import { AxiosProgressEvent } from 'axios';
import { RequestType } from './types';
import { sendAxiosFileRequest } from '@api/uploadFileWithProgression';
import { buildUrl, handleRequest } from './requestHelper';

const getData = async (
  endpoint: string,
  query = '',
  basePath = process.env.REACT_APP_API_PATH,
  shouldUseSubscriptionKey = true,
) => {
  const url = buildUrl(endpoint, basePath, query);

  return handleRequest({ url, requestType: RequestType.GET, shouldUseSubscriptionKey });
};

const addData = async (
  endpoint: string,
  data: any,
  query = '',
  basePath = process.env.REACT_APP_API_PATH,
  shouldUseSubscriptionKey = true,
) => {
  const url = buildUrl(endpoint, basePath, query);

  return handleRequest({ url, requestType: RequestType.POST, shouldUseSubscriptionKey, data });
};

const updateData = async (
  endpoint: string,
  data: any,
  query: string,
  basePath = process.env.REACT_APP_API_PATH,
  shouldUseSubscriptionKey = true,
) => {
  const url = buildUrl(endpoint, basePath, query);

  return handleRequest({ url, requestType: RequestType.PUT, shouldUseSubscriptionKey, data });
};

const deleteData = async (
  endpoint: string,
  query?: string,
  basePath = process.env.REACT_APP_API_PATH,
  shouldUseSubscriptionKey = true,
) => {
  const url = buildUrl(endpoint, basePath, query);

  return handleRequest({ url, requestType: RequestType.DELETE, shouldUseSubscriptionKey });
};

const uploadAxiosFiles = async (data: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) => {
  const basePath = process.env.REACT_APP_API_PATH;
  const url = buildUrl('/staged-file', basePath, '');

  return sendAxiosFileRequest(RequestType.POST, data, url, onUploadProgress);
};

const deleteFile = async (fileName: string, shouldUseSubscriptionKey = true) => {
  const basePath = process.env.REACT_APP_API_PATH;
  const url = buildUrl(`/staged-file/${fileName}`, basePath, '');

  return handleRequest({ url, requestType: RequestType.DELETE, shouldUseSubscriptionKey });
};

const replaceAltText = async (
  submissionId: string,
  documentId: string,
  data: unknown,
  shouldUseSubscriptionKey: boolean = true,
) => {
  const basePath = process.env.REACT_APP_API_PATH;
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/alternative-text`, basePath, '');

  return handleRequest({ url, requestType: RequestType.PUT, data, shouldUseSubscriptionKey });
};

export { getData, addData, updateData, deleteData, deleteFile, uploadAxiosFiles, replaceAltText };
