import { WidgetData, Widgets } from '@common/pages/Home/types';
import { newsItems, oldSlaItems, newSlaItems, tutorialItems, bCertifiedImage } from './constants';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { fetchDashboard } from '@common/services/dashboardService';
import { useQuery } from '@tanstack/react-query';
import { ArticleTypes } from '../widgets/ArticleWidget/types';
import { useCallback } from 'react';

export const fetchDashboardQueryKey = 'fetchDashboard';

export interface DashboardDto {
  date: Date;
  widgets: WidgetData[];
}

interface IDashboardsData {
  draft: DashboardDto | null;
  published: DashboardDto | null;
}

interface DashboardRaw {
  date: Date;
  content: string;
}

interface IFetchDashboardResponse {
  draft: DashboardRaw;
  published: DashboardRaw;
}

const useFetchDashboard = () => {
  const { showChristmasDeadlines } = useFeatureFlags();

  const { data, isFetching } = useQuery<IFetchDashboardResponse, unknown, IDashboardsData>(
    [fetchDashboardQueryKey],
    () => fetchDashboard(),
    {
      select: useCallback(
        (data: any) => ({
          published: parseDashboardData(showChristmasDeadlines, data.published, true),
          draft: parseDashboardData(showChristmasDeadlines, data!.draft),
        }),
        [showChristmasDeadlines],
      ),
    },
  );

  return {
    publishedDashboard: data?.published,
    draftDashboard: data?.draft,
    isFetching,
  };
};

const defaultWidgetsPositions = {
  [Widgets.NewsWidget]: { row: 1, col: 0 },
  [Widgets.WelcomeWidget]: { row: 0, col: 0 },
  [Widgets.ArticleWidget]: { row: 0, col: 1 },
  [Widgets.TutorialsWidget]: { row: 0, col: 2 },
  [Widgets.SlaWidget]: { row: 1, col: 2 },
  [Widgets.LinksWidget]: { row: 2, col: 2 },
};

const getDefaultWidgetsData = (showChristmasDeadlines: boolean) => {
  var slaItems = showChristmasDeadlines ? newSlaItems : oldSlaItems;
  var widgets = [
    { widgetType: Widgets.NewsWidget, data: { newsItems }, pos: defaultWidgetsPositions[Widgets.NewsWidget] } as WidgetData,
    {
      widgetType: Widgets.WelcomeWidget,
      data: {
        title: 'Welcome to your new emc publishing tool',
        subtitle:
          'The emc publishing platform is part of your emc regulatory solution, and is where you can submit your medicines information content for publication on emc.',
      },
      pos: defaultWidgetsPositions[Widgets.WelcomeWidget],
    } as WidgetData,
    {
      widgetType: Widgets.ArticleWidget,
      data: {
        title: 'Datapharm becomes a certified B Corporation',
        description:
          'Datapharm is delighted to have joined a growing list of companies which have achieved certified B Corp status. <a target="_blank" href="https://www.datapharm.com/resource-hub/datapharm-becomes-a-certified-b-corp/" class="styles__Link-sc-1fijo5x-0 jsxhCK">Read more &gt;</a>',
        imageSrc: bCertifiedImage,
        type: ArticleTypes.image,
        link: '',
      },
      pos: defaultWidgetsPositions[Widgets.ArticleWidget],
    } as WidgetData,

    {
      widgetType: Widgets.TutorialsWidget,
      data: { tutorialItems },
      pos: defaultWidgetsPositions[Widgets.TutorialsWidget],
    } as WidgetData,
    {
      widgetType: Widgets.SlaWidget,
      data: { slaItems },
      pos: defaultWidgetsPositions[Widgets.SlaWidget],
    } as WidgetData,
  ];

  return { date: new Date(), widgets } as DashboardDto;
};

const parseDashboardData = (
  showChristmasDeadlines: boolean,
  dashboardData?: DashboardRaw,
  returnDefault: boolean = false,
): DashboardDto | null => {
  if (!dashboardData?.content) {
    return returnDefault ? getDefaultWidgetsData(showChristmasDeadlines) : null;
  }

  let widgets = JSON.parse(dashboardData.content) as WidgetData[];
  if (widgets.some((widget) => !widget.pos)) {
    widgets = widgets.map((widget) => ({ ...widget, pos: defaultWidgetsPositions[widget.widgetType] }));
  }

  return {
    date: dashboardData.date,
    widgets,
  };
};

export default useFetchDashboard;
