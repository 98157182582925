import { DocumentStatus, DocumentType, SubmissionTaskType, ValidationError } from '@common/types';
import { IDocumentDto, ISubmissionTaskDto } from '../submission/types';
import { FILE_SIZE_LIMIT, documentTypeToCustomLabelMap } from '@common/constants';
import { FILE_EXTENSIONS, OLD_WORD_FILES_EXTENSIONS } from './constants';
import { FileRoles } from './types';
import { ICompanyConfigurationDto } from '../configuration/types';
import { enhanceWithHttps } from '@common/helpers';
import { DocumentProcessingDetails, DocumentProcessingFlow } from './components/DocumentContentDetails/types';
import { ISubmissionDto } from '@common/features/submission/types';

const { Pil, Smpc, Rmm, UserManual, Dhcp, Epil, AudioVideo, SafetyAlert, ProductInformation, Video, Audio } = DocumentType;

export const smpcTypes = [SubmissionTaskType.Smpc, SubmissionTaskType.SmpcNew, SubmissionTaskType.SmpcUpdate];
export const pilTypes = [
  SubmissionTaskType.Pil,
  SubmissionTaskType.PilNew,
  SubmissionTaskType.PilUpdate,
  SubmissionTaskType.QRDPilNew,
];
export const epilTypes = [SubmissionTaskType.ePILNew, SubmissionTaskType.ePILUpdate, SubmissionTaskType.QRDEpilNew];
export const RMMTypes = [SubmissionTaskType.RMM, SubmissionTaskType.RMMNew, SubmissionTaskType.RMMUpdate];
export const DHPCTypes = [SubmissionTaskType.DHPCNew, SubmissionTaskType.DHPCNew, SubmissionTaskType.DHPCUpdate];
export const ManualTypes = [SubmissionTaskType.UserManual, SubmissionTaskType.UserManualNew, SubmissionTaskType.UserManualUpdate];
export const SafetyInfoTypes = [
  SubmissionTaskType.SafetyAlert,
  SubmissionTaskType.SafetyAlertNew,
  SubmissionTaskType.SafetyAlertUpdate,
];
export const ProductInfoTypes = [
  SubmissionTaskType.ProductInformation,
  SubmissionTaskType.ProductInformationNew,
  SubmissionTaskType.ProductInformationUpdate,
];
export const AudioVideoTypes = [
  SubmissionTaskType.AudioVideo,
  SubmissionTaskType.AudioVideoNew,
  SubmissionTaskType.AudioVideoUpdate,
];
export const AudioTypes = [SubmissionTaskType.Audio, SubmissionTaskType.AudioNew, SubmissionTaskType.AudioUpdate];
export const VideoTypes = [SubmissionTaskType.Video, SubmissionTaskType.VideoNew, SubmissionTaskType.VideoUpdate];

export const getContentTypeFromTask = (submissionTaskType?: SubmissionTaskType) => {
  if (!submissionTaskType) return undefined;
  if (smpcTypes.includes(submissionTaskType!)) return DocumentType.Smpc;
  if (pilTypes.includes(submissionTaskType!)) return DocumentType.Pil;
  if (epilTypes.includes(submissionTaskType!)) return DocumentType.Pil;
  if (RMMTypes.includes(submissionTaskType!)) return DocumentType.Rmm;
  if (DHPCTypes.includes(submissionTaskType!)) return DocumentType.Dhcp;
  if (ManualTypes.includes(submissionTaskType!)) return DocumentType.UserManual;
  if (SafetyInfoTypes.includes(submissionTaskType!)) return DocumentType.SafetyAlert;
  if (ProductInfoTypes.includes(submissionTaskType!)) return DocumentType.ProductInformation;
  if (AudioVideoTypes.includes(submissionTaskType!)) return DocumentType.AudioVideo;
  if (AudioTypes.includes(submissionTaskType!)) return DocumentType.Audio;
  if (VideoTypes.includes(submissionTaskType!)) return DocumentType.Video;
};

type DocumentToDigitalVersion = {
  [key in DocumentType]?: DocumentType;
};

const DocumentToDigitalVersionMap: DocumentToDigitalVersion = {
  [DocumentType.Pil]: DocumentType.Epil,
};

export const checkDigitalVersion = (document: IDocumentDto, allDocuments: IDocumentDto[]) => {
  let hasDigitalVersion = null;

  const digitalType = DocumentToDigitalVersionMap[document.type];
  if (digitalType) {
    hasDigitalVersion = !!allDocuments.find(
      (d) => d.documentId === document.documentId && d.type === digitalType && d.version === document.version,
    );
  }

  return hasDigitalVersion;
};

export const getIsNoteFileValid = ({ size, name }: File): ValidationError[] => {
  const errors = [];

  const extension = name.split('.').pop() ?? '';
  const isWrongFileType = () => !FILE_EXTENSIONS[DocumentType.Smpc].includes(extension);

  if (isWrongFileType()) {
    errors.push({ message: 'Invalid file format. Please upload a .docx file.', subMessage: '' });
  }

  const isFileTooLarge = () => size > FILE_SIZE_LIMIT;
  if (isFileTooLarge()) errors.push({ message: 'File size exceeds 5mb.', subMessage: '' });

  return errors;
};

export const getIsFileValid = (type: DocumentType, { size, name }: File, fileRole?: FileRoles): ValidationError[] => {
  const extension = name.split('.').pop() ?? '';

  const isOldWordFile = () => [Smpc, Pil, Epil].includes(type) && OLD_WORD_FILES_EXTENSIONS.includes(extension);
  const isFileTooLarge = () => size > FILE_SIZE_LIMIT;

  const modifyFileExtensionsByFileRole = () => {
    const extensions = { ...FILE_EXTENSIONS };

    if (!fileRole) {
      return extensions;
    }

    if (fileRole === FileRoles.PrimaryDocFile) {
      extensions[DocumentType.Pil.toString()] = ['docx'];
    }

    if (fileRole === FileRoles.SecondaryPDFFile) {
      extensions[DocumentType.Pil.toString()] = ['pdf'];
    }

    if (fileRole === FileRoles.AlternativeText) {
      extensions[DocumentType.Pil.toString()] = ['docx'];
    }

    return extensions;
  };

  const extensionsByFileRole = modifyFileExtensionsByFileRole();
  const isWrongFileType = () => !extensionsByFileRole[type].includes(extension);
  const errors = [];
  const {
    INCORRECT_TYPE_VERSION,
    TOO_LARGE,
    INCORRECT_TYPE_SMPC,
    INCORRECT_TYPE_PIL,
    INCORRECT_TYPE_PIL_DOC,
    INCORRECT_TYPE_PIL_ALTERNATIVE_TEXT,
    INCORRECT_TYPE_RMM,
    INCORRECT_TYPE_DHCP,
    INCORRECT_TYPE_USER_MANUAL,
    INCORRECT_TYPE_PDF,
    INCORRECT_TYPE_EPIL,
  } = getFileValidationErrors(type);
  if (isOldWordFile()) errors.push(INCORRECT_TYPE_VERSION);
  else if (isWrongFileType())
    switch (type) {
      case DocumentType.Smpc:
        errors.push(INCORRECT_TYPE_SMPC);
        break;
      case DocumentType.Pil:
        switch (fileRole) {
          case FileRoles.AlternativeText:
            errors.push(INCORRECT_TYPE_PIL_ALTERNATIVE_TEXT);
            break;
          case FileRoles.PrimaryDocFile:
            errors.push(INCORRECT_TYPE_PIL_DOC);
            break;
          case FileRoles.SecondaryPDFFile:
            errors.push(INCORRECT_TYPE_PIL);
            break;
          default:
            errors.push(INCORRECT_TYPE_PIL);
            break;
        }
        break;
      case DocumentType.Epil:
        errors.push(INCORRECT_TYPE_EPIL);
        break;
      case DocumentType.Rmm:
        errors.push(INCORRECT_TYPE_RMM);
        break;
      case DocumentType.Dhcp:
        errors.push(INCORRECT_TYPE_DHCP);
        break;
      case DocumentType.UserManual:
        errors.push(INCORRECT_TYPE_USER_MANUAL);
        break;
      case DocumentType.SafetyAlert:
        errors.push(INCORRECT_TYPE_PDF);
        break;
      case DocumentType.ProductInformation:
        errors.push(INCORRECT_TYPE_PDF);
        break;
    }

  if (isFileTooLarge()) errors.push(TOO_LARGE);
  return errors;
};

export const getCanUpdateAltText = (file: File): ValidationError[] => {
  const submitValidationErrors = getSubmitValidationErrors();
  const errors = [];

  if (!file) {
    errors.push(submitValidationErrors.FILE_NOT_UPLOADED);
  }

  return errors;
};

export const getCanUpload = (
  contentType: DocumentType,
  title: string,
  documentAttributes: IDocumentAttributes,
  skipWeblinkValidation: boolean | undefined,
  file?: File,
  webLink?: string,
  hasDigitalVersion?: boolean,
  isCustomerApprovalRequired?: boolean,
  content?: string,
  secondaryFileIsChecked?: boolean,
  secondaryfile?: File,
  documentProcessingFlow?: DocumentProcessingFlow,
): ValidationError[] => {
  const webLinkPattern = new RegExp(
    '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?',
  );

  const submitValidationErrors = getSubmitValidationErrors(contentType);
  const errors = [];

  if (title === '') errors.push(submitValidationErrors.DOCUMENT_TITLE_MISSING);

  if (documentAttributes.showRmmWebLink || documentAttributes.showAudioVideoWebLink) {
    if (!skipWeblinkValidation && !webLink && !file?.name) errors.push(submitValidationErrors.NO_FILE_OR_WEB_LINK);
    if (webLink && !enhanceWithHttps(webLink)!.match(webLinkPattern)) errors.push(submitValidationErrors.WEB_LINK_WRONG_FORMAT);

    return errors;
  }

  if (!file?.name && documentAttributes.allowFileUpload) {
    if (contentType === DocumentType.Pil && documentProcessingFlow === DocumentProcessingFlow.qrdPil) {
      errors.push(submitValidationErrors.WORD_PIL_NOT_UPLOADED);
    } else {
      errors.push(submitValidationErrors.FILE_NOT_UPLOADED);
    }
  }

  if (
    contentType === DocumentType.Pil &&
    documentProcessingFlow === DocumentProcessingFlow.qrdPil &&
    secondaryFileIsChecked === undefined
  ) {
    errors.push(submitValidationErrors.EPIL_INCLUDE_SECONDRY_NOT_SELECTED);
  }

  if (!secondaryfile?.name && secondaryFileIsChecked && documentProcessingFlow === DocumentProcessingFlow.qrdPil)
    errors.push(submitValidationErrors.PDF_PIL_NOT_UPLOADED);

  if (
    contentType === DocumentType.Pil &&
    documentProcessingFlow === DocumentProcessingFlow.standard &&
    hasDigitalVersion === undefined
  ) {
    errors.push(submitValidationErrors.EPIL_CONVERSION_NOT_SELECTED);
  }

  if (
    contentType === DocumentType.Pil &&
    documentProcessingFlow === DocumentProcessingFlow.standard &&
    isCustomerApprovalRequired === undefined
  ) {
    errors.push(submitValidationErrors.CUSTOMER_APPROVAL_NOT_SELECTED);
  }

  if (documentAttributes.allowHtmlContentUpload && !content!.length) {
    errors.push(submitValidationErrors.NO_CONTENT_ADDED);
  }

  return errors;
};

export const getFileValidationErrors = (type: DocumentType) => ({
  INCORRECT_TYPE_SMPC: {
    message: 'Incorrect file type',
    subMessage: `Your file is in the incorrect file type. Please upload the file as a docx file.`,
  },
  INCORRECT_TYPE_PIL: {
    message: 'Incorrect file type',
    subMessage: `Your file is an incorrect filetype, please save the file as a pdf file and re-upload.`,
  },
  INCORRECT_TYPE_PIL_DOC: {
    message: 'Incorrect file type',
    subMessage: `Your file is an incorrect filetype, please save the file as a docx file and re-upload.`,
  },
  INCORRECT_TYPE_EPIL: {
    message: 'Incorrect file type',
    subMessage: `Your file is an incorrect filetype, please save the file as a docx file and re-upload.`,
  },
  INCORRECT_TYPE_PIL_ALTERNATIVE_TEXT: {
    message: 'Incorrect file type',
    subMessage: `Your file is an incorrect filetype, please save the file as a docx file and re-upload.`,
  },
  INCORRECT_TYPE_PDF: {
    message: 'Incorrect file type',
    subMessage: `Your file is an incorrect filetype, please save the file as a pdf file and re-upload.`,
  },
  INCORRECT_TYPE_RMM: {
    message: 'Incorrect file type',
    subMessage: `Your file is an incorrect filetype, please save the file as a pdf file and re-upload.`,
  },
  INCORRECT_TYPE_DHCP: {
    message: 'Incorrect file type',
    subMessage: `Your file is an incorrect filetype, please save the file as a pdf file and re-upload.`,
  },
  INCORRECT_TYPE_USER_MANUAL: {
    message: 'Incorrect file type',
    subMessage: `Your file is an incorrect filetype, please save the file as a pdf file and re-upload.`,
  },
  INCORRECT_TYPE_VERSION: {
    message: 'Incorrect file type',
    subMessage: 'Your file is saved as an old word document version, please save the file as docx file and re-upload.',
  },
  TOO_LARGE: {
    message: 'Content too large',
    subMessage: `Your ${documentTypeToCustomLabelMap[type]} exceeds the size limit. Please reduce file size and retry.`,
  },
});

export const getSubmitValidationErrors = (contentType?: DocumentType) => {
  const contentTypeWithFallback = contentType || 'document';

  return {
    DOCUMENT_TITLE_MISSING: {
      message: 'Document title missing',
      subMessage: 'Your content does not have a title, please add a title and retry.',
    },
    FILE_NOT_UPLOADED: {
      message: 'File not uploaded',
      subMessage: 'Please upload a file.',
    },
    WORD_PIL_NOT_UPLOADED: {
      message: 'File not uploaded',
      subMessage: 'Please upload your word QRD PIL.',
    },
    PDF_PIL_NOT_UPLOADED: {
      message: 'File not uploaded',
      subMessage: 'Please upload your print ready pdf PIL.',
    },
    NO_FILE_OR_WEB_LINK: {
      message: 'Please provide a web link or upload a file',
      subMessage: '',
    },
    WEB_LINK_WRONG_FORMAT: {
      message: `Please enter a valid URL`,
      subMessage: '',
    },
    WEB_LINK_NO_PDF: {
      message: `Please ensure the link is a direct link to the ${contentTypeWithFallback}`,
      subMessage: '',
    },
    EPIL_CONVERSION_NOT_SELECTED: {
      message: 'Please indicate if you would like an ePIL created',
      subMessage:
        'e-PILs are specially formatted PILs, that are accessible to the visually impaired and can be interpreted by screen readers. e-PILs do not include any graphics, tables or images.',
    },
    CUSTOMER_APPROVAL_NOT_SELECTED: {
      message: 'No choice for auto approve',
      subMessage: '',
    },
    NO_CONTENT_ADDED: {
      message: 'No content provided',
      subMessage: '',
    },
    EPIL_INCLUDE_SECONDRY_NOT_SELECTED: {
      message: 'Please indicate if you would like to include a PDF',
      subMessage:
        'This should be a high quality PDF copy of your PIL that users can print and read it easily, please note that if you do not provider a seperate pdf version we will create one using the word document above',
    },
    ALTERNATIVE_TEXT_FILE_MISSING: {
      message: 'Alternative text file not uploaded',
      subMessage: 'Please upload your Alternative text file.',
    },
  };
};

export const htmlFileIsAvailable = (document: IDocumentDto, allDocuments: IDocumentDto[]) => {
  const documentWasPublished = document?.status === DocumentStatus.Published || document?.status === DocumentStatus.Retired;

  const htmlForDocumentExists =
    !!document && (document.type === DocumentType.Smpc || checkDigitalVersion(document, allDocuments));

  return documentWasPublished && htmlForDocumentExists;
};

export const hasOriginalFilePublished = (document?: IDocumentDto) => {
  const documentWasPublished = document?.status === DocumentStatus.Published || document?.status === DocumentStatus.Retired;

  if (!documentWasPublished) {
    return false;
  }

  switch (document.type) {
    case DocumentType.Smpc:
      return false;
    default:
      return true;
  }
};

export interface IDocumentAttributes {
  showHasDigitalVersion: boolean;
  showRegulatorApprovalDate: boolean;
  showAuthorisedDate: boolean;
  showDaysSinceApproval: boolean;
  showHcpOnly: boolean;
  showContentDescription: boolean;
  showFreeTextReason: boolean;
  requiresRetirementReason: boolean;
  showRmmWebLink: boolean;
  showAudioVideoWebLink: boolean;
  showAssociationWarning: boolean;
  hasHtmlContent: boolean;
  showContentTypeColumn: boolean;
  allowMultipleSelect: boolean;
  showApproval: boolean;
  showAudioVideoDropdown: boolean;
  allowLinkInHtmlContent: boolean;
  allowFileUpload: boolean;
  allowEnterLinksInHtmlContent?: boolean;
  allowHtmlContentUpload: boolean;
  allowAdminWeblinkUpload: boolean;
  shouldHandleCustomerLink: boolean;
  shouldHandleTitleUpdateInReplacement: boolean;
  showReplaceContentAction: boolean;
  shouldHandleProductGroupUpdate: boolean;
  canHaveAlternativeText: boolean;
}

export const getDocumentAttributes = (type: DocumentType, isAdmin: boolean): IDocumentAttributes => {
  return {
    showHasDigitalVersion: [Pil].includes(type),
    showRegulatorApprovalDate: [Pil, Smpc].includes(type),
    showAuthorisedDate: [Pil, Smpc].includes(type),
    showDaysSinceApproval: [Pil, Smpc].includes(type),
    showHcpOnly: ![Pil, Smpc].includes(type),
    showContentDescription: ![Pil, Smpc, UserManual, SafetyAlert, ProductInformation].includes(type),
    showFreeTextReason: ![Pil, Smpc].includes(type),
    requiresRetirementReason: [Pil, Smpc].includes(type),
    showRmmWebLink: [Rmm].includes(type),
    showAudioVideoWebLink: [AudioVideo, Video, Audio].includes(type),
    showAssociationWarning: [Pil, Smpc].includes(type),
    hasHtmlContent: [SafetyAlert, ProductInformation].includes(type),
    showContentTypeColumn: [AudioVideo].includes(type),
    allowMultipleSelect: ![Smpc, Pil, UserManual].includes(type),
    showApproval: ![SafetyAlert, ProductInformation].includes(type),
    showAudioVideoDropdown: [AudioVideo].includes(type),
    allowLinkInHtmlContent: [Video, Audio].includes(type) && isAdmin,
    allowFileUpload: ![AudioVideo, Audio, Video, SafetyAlert, ProductInformation].includes(type),
    allowAdminWeblinkUpload: [Audio, Video].includes(type) && isAdmin,
    allowHtmlContentUpload: [SafetyAlert, ProductInformation].includes(type),
    shouldHandleCustomerLink: [Audio, Video].includes(type),
    shouldHandleTitleUpdateInReplacement: [Pil, Smpc].includes(type),
    showReplaceContentAction: type !== Epil,
    shouldHandleProductGroupUpdate: [Smpc, Pil].includes(type),
    canHaveAlternativeText: [Pil, Epil].includes(type),
  };
};

export const taskHasConversion = (type: SubmissionTaskType) => {
  return [
    SubmissionTaskType.Smpc,
    SubmissionTaskType.SmpcNew,
    SubmissionTaskType.SmpcUpdate,
    SubmissionTaskType.QRDEpilNew,
    SubmissionTaskType.QRDEpilUpdate,
    SubmissionTaskType.QRDPilNew,
    SubmissionTaskType.QRDPilUpdate,
  ].includes(type);
};

export const getDocumentAttributesForSummary = (type: DocumentType) => {
  return {
    showRegulatorApprovalDate: [Pil, Smpc, Epil].includes(type),
    showSubmissionNotes: [Pil, Smpc, Epil].includes(type),
    showDaysSinceApproval: [Pil, Smpc, Epil].includes(type),
    showContentDescription: ![Pil, Smpc, Epil, UserManual, SafetyAlert, ProductInformation].includes(type),
    showHcpOnly: ![Pil, Smpc, Epil].includes(type),
    showApproval: ![Audio, Video, SafetyAlert, ProductInformation].includes(type),
  };
};

//Do the list of documents on the submission task contain at least one modifed Primary Document or one reassigned Primary Document
export const canUpdateProductDetailsInTask = (type: SubmissionTaskType) => {
  return [
    SubmissionTaskType.SmpcNew,
    SubmissionTaskType.SmpcUpdate,
    SubmissionTaskType.PilNew,
    SubmissionTaskType.PilUpdate,
    SubmissionTaskType.ePILNew,
    SubmissionTaskType.ePILUpdate,
    SubmissionTaskType.ProductGroupUpdate,
  ].includes(type);
};

export const canAddProductDetailsInTask = (type: SubmissionTaskType) => {
  return [
    SubmissionTaskType.SmpcNew,
    SubmissionTaskType.SmpcUpdate,
    SubmissionTaskType.PilNew,
    SubmissionTaskType.PilUpdate,
  ].includes(type);
};

export const shouldAllowMultipleRowSelect = (type: DocumentType) =>
  [Rmm, UserManual, Dhcp, SafetyAlert, ProductInformation, AudioVideo].includes(type);

export const isMedia = (documentType: DocumentType) =>
  [DocumentType.Audio, DocumentType.Video, DocumentType.AudioVideo].includes(documentType);

export const getMediaContentType = (type: DocumentType) => {
  switch (type) {
    case DocumentType.Audio:
      return 'Audio';
    case DocumentType.Video:
      return 'Video';
    default:
      return '-';
  }
};

export const getDocumentProccessingDetails = (submissionTask: ISubmissionTaskDto | undefined): DocumentProcessingDetails => {
  return {
    isCustomerApprovalRequired: submissionTask?.isCustomerApprovalRequired,
    customerUrl: submissionTask?.customerLink,
    documentProcessingFlow: getDocumentProcessingFlowForTask(submissionTask?.type),
  };
};

export const getDocumentProcessingFlowForDocument = (
  documentType: DocumentType,
  companyConfiguration: ICompanyConfigurationDto,
) => {
  return (documentType === DocumentType.Pil || documentType === DocumentType.Epil) && companyConfiguration.hasQrdEpilsEnabled
    ? DocumentProcessingFlow.qrdPil
    : DocumentProcessingFlow.standard;
};

export const getDocumentProcessingFlowForTask = (taskType: SubmissionTaskType | undefined) => {
  return taskType === SubmissionTaskType.QRDPilNew || taskType === SubmissionTaskType.QRDEpilNew
    ? DocumentProcessingFlow.qrdPil
    : DocumentProcessingFlow.standard;
};

export const validateDocumentTitleUniqueness = (
  documents: IDocumentDto[],
  documentTitle: string,
  contentType: DocumentType,
  documentId: string | null,
) => {
  const filteredDocuments =
    documents.filter(
      (x) =>
        x.documentTitle.toLowerCase() === documentTitle.toLowerCase() &&
        x.type === contentType &&
        x.documentId !== parseInt(documentId!),
    ) || [];

  return filteredDocuments.length > 0;
};

export const resolvePrimaryAndSecondaryFile = (
  contentType: DocumentType,
  submission: ISubmissionDto,
  documentId: number,
  processingDetails: DocumentProcessingDetails,
) => {
  if (contentType === DocumentType.Pil && processingDetails.documentProcessingFlow === DocumentProcessingFlow.qrdPil) {
    const electronicDocument = submission?.documents.find((d) => d.documentId === documentId && d.type === DocumentType.Epil);
    const pdfDocument = submission?.documents.find((d) => d.documentId === documentId && d.type === DocumentType.Pil);

    if (electronicDocument?.originalFileName?.toLowerCase().endsWith('.docx')) {
      return {
        primaryFile: electronicDocument,
        secondaryFile: pdfDocument,
      };
    }
  }
  const document = submission?.documents.find((d) => d.documentId === documentId && d.type === contentType);

  return {
    primaryFile: document,
    secondaryFile: undefined,
  };
};
