import { getProductReportData } from '@common/services/submissionService';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { convertObjectToQueryString } from '@common/filterFormHelpers';
import { ISelectOption } from '@common/types';
import { usePleaseWaitOverlay } from '@common/components/PleaseWaitOverlay/usePleaseWaitOverlay';

interface IMutationData {
  statuses?: ISelectOption[];
  types?: ISelectOption[];
  requestedFields?: ISelectOption[];
  fromDate?: string | null;
  toDate?: string | null;
}

interface IMutationProps {
  successCallback: (response: unknown) => void;
  errorCallback: () => void;
}

export const useGetProductReportData = ({ successCallback, errorCallback }: IMutationProps) => {
  const { handleShowOverlay, handleHideOverlay } = usePleaseWaitOverlay();

  const handleSuccess = (response: unknown) => {
    handleHideOverlay();
    successCallback(response);
  };

  const handleError = () => {
    handleHideOverlay();
    errorCallback();
  };

  const { mutate: generateProductReport, isLoading: isReportGenerating } = useWaitingMutation(
    (data: IMutationData) => {
      const queryString = convertObjectToQueryString(data);
      return getProductReportData(queryString);
    },
    { onMutate: handleShowOverlay, onSuccess: handleSuccess, onError: handleError },
  );

  return {
    generateProductReport,
    isReportGenerating,
  };
};
