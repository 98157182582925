import { useQuery } from '@tanstack/react-query';
import { downloadTaskFileByRole } from '@common/services/submissionService';
import { Id } from '@common/types';

export const useDownloadTaskFileByRole = (taskId: Id, fileRole: string) => {
  const { data, refetch } = useQuery(['downloadTaskFileByRole', taskId], () => downloadTaskFileByRole(taskId, fileRole), {
    enabled: false,
  });

  return { taskFileByRoleResponse: data, refetchDownloadTaskFileByRole: refetch };
};
