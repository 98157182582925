import { createSlice } from '@reduxjs/toolkit';
import { IConfigurationState } from './types';
import { fetchConfiguration } from './thunk';

export const initialState: IConfigurationState = {
  isLoading: false,
  configuration: {
    definitionProductNamePattern: '',
    emcProductNamePattern: '',
    maintenanceMessage: '',
    featureFlags: {
      showChristmasDeadlines: false,
      showEditProfile: false,
      showViewHierarchy: false,
      showImagesOnAssociateContent: false,
      showLiveChat: false,
      showMaintenancePage: false,
      enableXmlReplacement: false,
      showDashboardEditing: false,
      showEmcLinkAndRevisionDateInAuditReports: false,
      showChangeAlertsLink: false,
      showLookupManagement: false,
    },
    cmsLink: '',
    emcLink: '',
  },
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setFeatureFlags(state, action) {
      state.configuration.featureFlags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConfiguration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchConfiguration.fulfilled, (state, action) => {
      state.isLoading = false;
      state.configuration = action.payload;
    });
    builder.addCase(fetchConfiguration.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { setFeatureFlags } = configurationSlice.actions;

export default configurationSlice.reducer;
