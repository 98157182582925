import { useQuery } from '@tanstack/react-query';
import { downloadTaskFile } from '@common/services/submissionService';
import { Id } from '@common/types';

export const useDownloadTaskFile = (taskId: Id) => {
  const { data, refetch } = useQuery(['downloadTaskFile', taskId], () => downloadTaskFile(taskId), {
    enabled: false,
  });

  return { taskFileResponse: data, refetchDownloadTaskFile: refetch };
};
