import React, { ComponentType } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@common/hooks/redux';
import { useLogoutUser } from '@common/auth/useOidcAuthentication';
import { setHasUserInsufficientPermissions } from '@common/features/user/userActions';

export const WithQueryClient = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const WithQueryClient = (props: P) => {
    const { logoutUser } = useLogoutUser();
    const dispatch = useAppDispatch();

    const [queryClient] = useState<QueryClient>(
      () =>
        new QueryClient({
          defaultOptions: {
            queries: {
              refetchOnWindowFocus: false,
              retry: 0,
            },
          },
          queryCache: new QueryCache({
            onError: (error: any) => {
              if (error.cause === 401) {
                logoutUser();
              }

              if (error.cause === 403) {
                dispatch(setHasUserInsufficientPermissions(true));
              }

              if (error.cause === 404) {
                toast.error('Not found - 404');
              }
            },
          }),
          mutationCache: new MutationCache({
            onError: (error: any) => {
              if (error.cause === 401) {
                logoutUser();
              }

              if (error.cause === 403) {
                dispatch(setHasUserInsufficientPermissions(true));
              }

              if (error.cause === 404) {
                toast.error('Not found - 404');
              }
            },
          }),
        }),
    );

    return (
      <QueryClientProvider client={queryClient!}>
        <WrappedComponent {...props} />
      </QueryClientProvider>
    );
  };

  return WithQueryClient;
};
