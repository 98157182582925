import { sendAxiosFileRequest } from '@common/api/uploadFileWithProgression';
import { buildUrl, handleRequest } from '@common/services/requestHelper';
import { ProductGroupSubstancesUpdateCommand, RequestType } from '@common/services/types';
import { DocumentType, Id } from '@common/types';
import { AxiosProgressEvent } from 'axios';

const basePath = process.env.REACT_APP_API_PATH;

const fetchSubmissionTaskNotes = async (taskId: string) => {
  const url = buildUrl(`/submission-task/${taskId}/notes`, basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

const fetchProducts = async (queryString: string) => {
  const url = buildUrl(`/product`, basePath, queryString);

  return handleRequest({ url, requestType: RequestType.GET });
};

const fetchDocumentVersions = async (documentId: string, documentType: DocumentType) => {
  const url = buildUrl(`/document/${documentId}/document-type/${documentType}`, basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

const fetchCompanies = async (queryString: string) => {
  const url = buildUrl(`/company`, basePath, queryString);

  return handleRequest({ url, requestType: RequestType.GET });
};

const fetchCompanyConfiguration = async (companyid: number) => {
  const url = buildUrl(`/configuration/company/${companyid}`, basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

const fetchXml = async (taskId: string) => {
  const url = buildUrl(`/submission-task/${taskId}/xml`, basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

const fetchHtml = async (taskId: string) => {
  const url = buildUrl(`/submission-task/${taskId}/html`, basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

const validateXml = async (taskId: string, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/html-preview`, basePath);

  return handleRequest({ url, requestType: RequestType.POST, data: dataSet });
};

const regenerateDocument = async (taskId: string, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/document-regeneration`, basePath);

  return handleRequest({ url, requestType: RequestType.PUT, data: dataSet });
};

const putXml = async (taskId: string, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/xml`, basePath);

  return handleRequest({ url, requestType: RequestType.PUT, data: dataSet });
};

const postDraftForPublicationXmlUpdate = async (productGroupId: Id, documentId: Id) => {
  const url = buildUrl(`/submission/live-xml-update-draft`, basePath);

  return handleRequest({ url, requestType: RequestType.POST, data: { productGroupId, documentId } });
};

const postLiveXmlUpdate = async (submissionId: Id, documentId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/xml`, basePath);

  return handleRequest({ url, requestType: RequestType.POST, data: dataSet });
};

const changeDocumentAssociation = async (submissionId: Id, documentId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/product-assignments`, basePath);

  return handleRequest({ url, requestType: RequestType.POST, data: dataSet });
};

const downloadImage = async (taskId: string, name: string) => {
  const url = buildUrl(`/submission-task/${taskId}/image/${name}`, basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

const uploadImage = async (taskId: string, data: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) => {
  const url = buildUrl(`/submission-task/${taskId}/image`, basePath);

  return sendAxiosFileRequest(RequestType.PUT, data, url, onUploadProgress);
};

const deleteImage = async (taskId: string, name: string) => {
  const url = buildUrl(`/submission-task/${taskId}/image/${name}`, basePath);

  return handleRequest({ url, requestType: RequestType.DELETE });
};

const fetchMatchingProductsForCompany = async (companyId: Id, substances?: string[]) => {
  let query = `companyId=${companyId}`;

  if (substances && substances.length > 0) {
    query += substances.map((s) => `&substances=${s}`).join('');
  }

  const url = buildUrl(`/product-group/matching-products`, basePath, query);

  return handleRequest({ url, requestType: RequestType.GET });
};
const updateProductGroupSubstances = async (productGroupId: Id, command: ProductGroupSubstancesUpdateCommand) => {
  const url = buildUrl(`/product-group/${productGroupId}/substances`, basePath);

  return handleRequest({ url, requestType: RequestType.PATCH, data: command });
};

const fetchCompanyTransfers = async () => {
  const url = buildUrl(`/company/transfer-list`, basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

const fetchLiveChats = async () => {
  const url = buildUrl(`/company/live-chat`, basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

const deleteLiveChat = async (id: string) => {
  const url = buildUrl(`/company/live-chat/${id}`, basePath);

  return handleRequest({ url, requestType: RequestType.DELETE });
};

const updateLiveChat = async (data: unknown) => {
  const url = buildUrl(`/company/live-chat`, basePath);

  return handleRequest({ url, requestType: RequestType.POST, data });
};

const fetchHolidays = async () => {
  const url = buildUrl(`/holiday`, basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

const deleteHoliday = async (date: string) => {
  const url = buildUrl(`/holiday/${date}`, basePath);

  return handleRequest({ url, requestType: RequestType.DELETE });
};

const addHoliday = async (date: string) => {
  const url = buildUrl(`/holiday/${date}`, basePath);

  return handleRequest({ url, requestType: RequestType.POST });
};

const scheduleCompanyTransfer = async (data: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) => {
  const url = buildUrl('/company/products-transfer', basePath);

  return sendAxiosFileRequest(RequestType.POST, data, url, onUploadProgress);
};

const fetchProductFamilies = async (search?: string) => {
  const url = buildUrl(`/product-family`, basePath, search && `nameStartsWith=${search}`);

  return handleRequest({ url, requestType: RequestType.GET });
};

const fetchProductFamily = async (id: Id) => {
  const url = buildUrl(`/product-family/${id}`, basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

const updateProductFamilyName = async (productFamilyId: Id, dataSet: unknown) => {
  const url = buildUrl(`/product-family/${productFamilyId}/name`, basePath);

  return handleRequest({ url, requestType: RequestType.PUT, data: dataSet });
};

const updateProductFamilyType = async (productFamilyId: Id, dataSet: unknown) => {
  const url = buildUrl(`/product-family/${productFamilyId}/type`, basePath);

  return handleRequest({ url, requestType: RequestType.PUT, data: dataSet });
};

const migrateProductGroup = async (sourceProductFamilyId: Id, destinationProductFamilyId: Id, dataSet: unknown) => {
  const url = buildUrl(
    `/product-family/${sourceProductFamilyId}/migrate-product-group-to/${destinationProductFamilyId}`,
    basePath,
  );

  return handleRequest({ url, requestType: RequestType.PUT, data: dataSet });
};

const fetchMatchingProductGroupsForFamily = async (productFamilyId: Id, companyId: Id) => {
  const url = buildUrl(`/product-family/${productFamilyId}/matching-product-groups`, basePath, `companyId=${companyId}`);

  return handleRequest({ url, requestType: RequestType.GET });
};

const fetchMatchingProductGroups = async (companyId: Id) => {
  const url = buildUrl(`/product-group`, basePath, `companyId=${companyId}`);

  return handleRequest({ url, requestType: RequestType.GET });
};

const migrateProductGroupToBrandNewProductFamily = async (dataSet: unknown) => {
  const url = buildUrl(`/product-family/migrate-product-group-to-new-product-family`, basePath);

  return handleRequest({ url, requestType: RequestType.POST, data: dataSet });
};

const publishDashboard = async (content: string) => {
  const url = buildUrl(`/dashboard`, basePath);

  return handleRequest({ url, requestType: RequestType.PUT, data: { content } });
};

const saveDashboardDraft = async (content: string) => {
  const url = buildUrl(`/dashboard`, basePath);

  return handleRequest({ url, requestType: RequestType.POST, data: { content } });
};

const deleteDashboardDraft = async () => {
  const url = buildUrl(`/dashboard`, basePath);

  return handleRequest({ url, requestType: RequestType.DELETE });
};

export {
  fetchProducts,
  fetchCompanies,
  fetchCompanyConfiguration,
  fetchSubmissionTaskNotes,
  fetchXml,
  fetchHtml,
  validateXml,
  regenerateDocument,
  putXml,
  changeDocumentAssociation,
  uploadImage,
  downloadImage,
  deleteImage,
  updateProductGroupSubstances,
  postDraftForPublicationXmlUpdate,
  postLiveXmlUpdate,
  fetchCompanyTransfers,
  scheduleCompanyTransfer,
  fetchLiveChats,
  updateLiveChat,
  deleteLiveChat,
  fetchHolidays,
  deleteHoliday,
  addHoliday,
  fetchDocumentVersions,
  fetchMatchingProductsForCompany,
  fetchProductFamilies,
  updateProductFamilyName,
  updateProductFamilyType,
  migrateProductGroup,
  fetchMatchingProductGroupsForFamily,
  fetchProductFamily,
  fetchMatchingProductGroups,
  migrateProductGroupToBrandNewProductFamily,
  publishDashboard,
  saveDashboardDraft,
  deleteDashboardDraft,
};
