import { createColumnHelper } from '@tanstack/react-table';
import { formatDateTime } from '@common/helpers';
import { IHistoryDetail, IProductHistoryDto } from './hooks/useFetchProductHistory';
import { Id } from '@common/types';
import { IDocumentContentInfo } from '@common/hooks/useFetchDocumentHtmlContent';
import { ViewContentLink } from '@common/components/ViewContentLink/ViewContentLink';

export interface IProductHistoryTableProps {
  productHistory: IProductHistoryDto[];
  onFileOpen: (taskId: Id) => void;
  onViewContent: (documentData: IDocumentContentInfo) => void;
}

export const prepareColumns = ({ onFileOpen, onViewContent }: IProductHistoryTableProps) => {
  const columnHelper = createColumnHelper<IProductHistoryDto>();

  return [
    columnHelper.accessor('description', {
      header: 'Description',
      cell: ({ getValue }) => getValue(),
    }),
    columnHelper.accessor('details', {
      header: 'Detail',
      cell: ({ getValue, row }) => {
        const value = getValue() as IHistoryDetail[];

        if (value) {
          return value.map((detail, index) => {
            return (
              <div key={index}>
                <ViewContentLink
                  id={detail.taskId == null ? undefined : parseInt(detail.taskId!)}
                  text={detail.text}
                  isHtml={detail.isHtml}
                  documentData={{
                    documentId: Number(detail.documentId!)!,
                    version: Number(detail.documentVersion),
                    type: detail.documentType,
                    submissionId: Number(detail.submissionId)!,
                  }}
                  disabled={!(detail.isFileLink || detail.isWebLink || detail.isHtml)}
                  link={detail.webLink}
                  onViewContent={onViewContent}
                  onFileOpen={onFileOpen}
                />
              </div>
            );
          });
        }

        return <></>;
      },
    }),
    columnHelper.accessor('performedBy', {
      header: 'Performed by',
      cell: ({ getValue }) => getValue() ?? '-',
    }),
    columnHelper.accessor('date', {
      header: 'Date',
      cell: ({ getValue }) => {
        const value = !!getValue() ? new Date(getValue()!) : null;

        return value ? formatDateTime(value) : '-';
      },
    }),
    columnHelper.accessor('smpcVersion', {
      header: 'SmPC version',
      cell: ({ getValue }) => getValue() ?? '',
    }),
    columnHelper.accessor('pilVersion', {
      header: 'PIL version',
      cell: ({ getValue }) => getValue() ?? '',
    }),
    columnHelper.accessor('maNumbers', {
      header: 'MA numbers',
      cell: ({ getValue }) => {
        const value = getValue() as string[];
        return value.join(', ') ?? '-';
      },
    }),
    columnHelper.accessor('legalCategories', {
      header: 'Legal Categories',
      cell: ({ getValue }) => {
        const value = getValue() as string[];
        return value.join(', ') ?? '-';
      },
    }),
    columnHelper.accessor('atcCodes', {
      header: 'ATC code',
      cell: ({ getValue }) => {
        const value = getValue() as string[];
        return value.join(', ') ?? '-';
      },
    }),
    columnHelper.accessor('hasBlackTriangle', {
      header: 'Black Triangle',
      cell: ({ getValue }) => {
        return getValue() ? 'Yes' : 'No';
      },
    }),
  ].filter((x) => x);
};
