import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { requestEpilStandard } from '@common/services/documentService';

interface IRequestEpilStandardData {
  documentId: string;
  submissionId: string;
  alternativeTextFileName?: string;
}

export const useRequestEpilStandard = () => {
  const { mutate } = useWaitingMutation(({ documentId, submissionId, alternativeTextFileName }: IRequestEpilStandardData) =>
    requestEpilStandard(submissionId, documentId, { alternativeTextFileName }),
  );

  return { requestEpil: mutate };
};
