import useWaitingMutation from '@hooks/useWaitingMutation';
import { Id } from '@common/types';
import { AddProductFormFields, IAddProductForm } from '../../../../components/Product/AddProductPopup/types';
import { createProduct } from '@common/services/submissionService';
import { AddProductRequest } from 'apps/publisher-v3.ui.customer/src/features/submission/types';
import { prepareActiveIngredients } from '@common/features/product/components/ProductContent/helpers';

interface ICreateProductPayload {
  data: IAddProductForm;
  generatedProductName: string;
}

export const useCreateProduct = (submissionId: Id) => {
  const prepareData = (form: IAddProductForm, generatedProductName: string): AddProductRequest => {
    const activeIngredients = prepareActiveIngredients(form.activeIngredientsStrength);
    const emcProductName = form[AddProductFormFields.emcProductName];
    const medicinalProductName = form[AddProductFormFields.medicinalProductName];

    return {
      extraInformation: form[AddProductFormFields.extraInformation] || null,
      productForm: form[AddProductFormFields.productForm]?.label || null,
      submissionId: submissionId,
      activeIngredients,
      name: emcProductName !== generatedProductName ? emcProductName : null,
      regulatorApprovedName: medicinalProductName !== generatedProductName ? medicinalProductName : null,
    };
  };

  const { mutate, isLoading } = useWaitingMutation(({ data, generatedProductName }: ICreateProductPayload) =>
    createProduct(submissionId, prepareData(data, generatedProductName) as AddProductRequest),
  );

  return { createProduct: mutate, isCreatingProduct: isLoading };
};
