import { useQuery } from '@tanstack/react-query';
import { selectActiveCompany } from '@common/features/user/selectors';
import { fetchProductsTree } from '@common/services/productService';
import { mapTreeToTableEntries } from '../../UpdateProduct/helpers';
import { searchInputPattern } from '@common/constants';
import { convertObjectToQueryString } from '@common/filterFormHelpers';
import { useAppSelector } from '@common/hooks/redux';

export const queryKey = 'fetchProductsTree';

export const useFetchProductsTree = (search: string) => {
  const activeCompany = useAppSelector(selectActiveCompany);

  const activeCompanyId = activeCompany?.id || '';
  const queryStringParams = {
    companyId: activeCompanyId,
    search,
  };
  const queryString = convertObjectToQueryString(queryStringParams);

  const { data, isFetching: isFetchingProductsTree } = useQuery([queryKey, search, activeCompanyId], () =>
    search && search.match(searchInputPattern) ? fetchProductsTree(queryString) : [],
  );

  return {
    data: data ? mapTreeToTableEntries(data) : [],
    isFetchingProductsTree,
  };
};
