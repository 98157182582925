import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISubmissionForm } from 'apps/publisher-v3.ui.customer/src/pages/CreateSubmission/types';
import { addData } from '@services/requestService';
import { RootState } from '@common/store';

export const generateProductGroupPlan = createAsyncThunk<any, ISubmissionForm>(
  'product-group/plan',
  async ({ genericName, productType, activeIngredients }, { getState }) => {
    const request = {
      genericName,
      productType: productType.value,
      substances: activeIngredients.map((ingredient) => ({
        name: ingredient.label,
        id: ingredient.value,
      })),
    };

    const response = await addData('/product-group/plan', request);

    return response;
  },
);

export const createSubmissionDraft = createAsyncThunk<any, ISubmissionForm>(
  'submission/draft',
  async ({ genericName, productType, activeIngredients }, { getState }) => {
    const state = getState() as RootState;
    const companyId = state.user.activeCompany?.id;

    const request = {
      genericName,
      companyId: companyId || null,
      productType: productType.value,
      substances: activeIngredients.map((ingredient) => ({
        name: ingredient.label,
        id: ingredient.value,
      })),
    };

    const response = await addData('/submission/draft', request);

    return response;
  },
);
