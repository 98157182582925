import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { completeRetireDocument } from '@common/services/submissionService';
import { ISelectOption } from '@common/types';

export const queryKey = 'softDeleteContentDetailsData';

export interface ISoftDeleteArgs {
  reason: ISelectOption;
}

export const useCompleteRetireDocument = (submissionId: number, documentId: number) => {
  const { mutate } = useWaitingMutation(({ reason }: ISoftDeleteArgs) => {
    return completeRetireDocument(submissionId, documentId, {
      reasonId: reason.id,
      reason: reason.value,
    });
  });

  return { completeRetireDocument: mutate };
};
