import { useQuery } from '@tanstack/react-query';
import { fetchDocumentVersionHistory } from '@common/services/documentService';
import { IDocumentHistoryVersion } from '../model/IDocumentHistoryVersion';
import { DocumentType } from '@common/types';

export const fetchVersionHistory = 'fetchDocumentVersionHistory';

interface IDocumentVersionHistoryResult {
  items: IDocumentHistoryVersion[];
  isLoading: boolean;
}

export const useFetchDocumentVersionHistory = (
  documentId: number,
  documentType?: DocumentType,
): IDocumentVersionHistoryResult => {
  const { data, isFetching } = useQuery<IDocumentHistoryVersion[]>(
    [fetchVersionHistory, documentType, documentId],
    () => fetchDocumentVersionHistory(documentType!, documentId),
    { enabled: !!documentType },
  );

  return {
    items: data ?? [],
    isLoading: isFetching,
  };
};
