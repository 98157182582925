import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { selectActiveCompany } from '@common/features/user/selectors';
import { ITask } from 'apps/publisher-v3.ui.customer/src/features/submission/types';
import { PaginationResult, TaskTypes } from '@common/types';
import { fetchTasks } from 'apps/publisher-v3.ui.customer/src/services/submissionTaskService';
import { SubmissionStatus, SubmissionTaskStatuses } from '@common/services/types';
import { useAppSelector } from '../redux';

export const queryKey = 'fetchDocuments';

interface IFetchTasks {
  submissionId?: string;
  type?: TaskTypes;
  taskStatus?: SubmissionTaskStatuses;
  submissionStatus?: SubmissionStatus;
  enabled?: boolean;
  staleTime?: number;
  skipCompany?: boolean;
}

export const useFetchTasks = ({
  submissionId,
  type,
  taskStatus,
  submissionStatus,
  enabled = true,
  staleTime,
  skipCompany,
}: IFetchTasks) => {
  const company = useAppSelector(selectActiveCompany);

  const { data, isFetching, refetch } = useQuery<PaginationResult<ITask[]>>(
    [queryKey, type, submissionId],
    () => fetchTasks(type, taskStatus, submissionStatus, submissionId as string, skipCompany ? undefined : company?.id),
    {
      enabled: enabled,
      staleTime,
    },
  );

  const tasks: ITask[] = useMemo(
    () =>
      data?.items.map((t) => ({
        ...t,
        submittedAt: t.submittedAt ? new Date(t.submittedAt) : undefined,
      })) ?? [],
    [data],
  );

  return {
    tasks,
    isFetchingTasks: isFetching,
    refetchSubmissionTasks: refetch,
  };
};
