import { useQuery } from '@tanstack/react-query';
import { ISubmissionStateDiffDto } from '@common/features/submission/types';
import { fetchSubmissionTaskProductGroupData } from '@common/services/submissionTaskService';

export const queryKey = 'fetchTaskProductGroupData';

export const useFetchTaskProductGroupData = (taskId: string) => {
  const { data, isFetching, refetch } = useQuery<ISubmissionStateDiffDto>(
    [queryKey],
    () => fetchSubmissionTaskProductGroupData(taskId),
    { enabled: !!taskId },
  );

  return {
    productGroupWithTaskApplied: data,
    isFetchingTaskProductGroup: isFetching,
    refetchTaskProductGroupData: refetch,
  };
};
