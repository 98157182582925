import { buildUrl, handleRequest } from '@common/services/requestHelper';
import { RequestType } from '@common/services/types';
import { Id } from '@common/types';

const basePath = process.env.REACT_APP_API_PATH;

const fetchPublicProductGroupState = async (productGroupId: Id, propagateError: boolean = true) => {
  const url = buildUrl(`/product-group/${productGroupId}`, basePath);

  return handleRequest({ url, requestType: RequestType.GET, propagateNotFound: propagateError });
};

const migrateProductsBetweenProductGroups = async (sourceProductGroupId: Id, destinationProductGroupId: Id, productIds: Id[]) => {
  const url = buildUrl(`/product-group/${sourceProductGroupId}/migrate-products-to/${destinationProductGroupId}`, basePath);

  return handleRequest({ url, requestType: RequestType.PUT, data: productIds });
};

const migrateProductsToBrandNewProductGroup = async (sourceProductGroupId: Id, dataSet: unknown) => {
  const url = buildUrl(`/product-group/${sourceProductGroupId}/migrate-products-to-new-product-group`, basePath);

  return handleRequest({ url, requestType: RequestType.POST, data: dataSet });
};

const renameProductGroup = async (productGroupId: Id, productGroupName: string) => {
  const url = buildUrl(`/product-group/${productGroupId}/rename-product-group`, basePath);
  return handleRequest({ url, requestType: RequestType.PUT, data: productGroupName });
};

const updateProductGroupProductNameDetails = async (productGroupId: Id, productId: Id, dataSet: unknown) => {
  const url = buildUrl(`/product-group/${productGroupId}/product/${productId}/name-definition`, basePath);

  return handleRequest({ url, requestType: RequestType.PUT, data: dataSet });
};

const updateProductPostPublication = async (productGroup: Id, productId: Id, dataSet: unknown) => {
  const url = buildUrl(`/product-group/${productGroup}/product/${productId}`, basePath);

  return handleRequest({ url, requestType: RequestType.PUT, data: dataSet });
};

const discontinueProductGroupProduct = async (productGroupId: Id, productId: Id, dataSet: unknown) => {
  const url = buildUrl(`/product-group/${productGroupId}/product/${productId}/discontinuation`, basePath);

  return handleRequest({ url, requestType: RequestType.POST, data: dataSet });
};

export {
  fetchPublicProductGroupState,
  migrateProductsBetweenProductGroups,
  updateProductGroupProductNameDetails,
  updateProductPostPublication,
  discontinueProductGroupProduct,
  renameProductGroup,
  migrateProductsToBrandNewProductGroup,
};
