import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { selectUserId } from '@common/features/user/selectors';
import { updateDocument } from '@common/services/submissionService';
import { enhanceWithHttps, getIsoStringWithTimezoneHandling } from '@common/helpers';
import { DocumentType } from '@common/types';
import { IDocumentAttributes } from '@common/features/document/helpers';
import { useAppSelector } from '@common/hooks/redux';

export const queryKey = 'contentDetailsData';

export const useUpdateDocument = (
  submissionId: number,
  isFormExtended: boolean,
  contentType: DocumentType,
  docAttributes: IDocumentAttributes,
  shouldHandleAlternativeText?: boolean,
) => {
  const userId = useAppSelector(selectUserId);

  const { mutate } = useWaitingMutation(
    ({
      approvedByRegulator,
      reasonsForSubmission,
      regulatorApprovalDate,
      authorisedDate,
      details,
      documentTitle,
      hasDigitalVersion,
      documentId,
      alternativeTextFileName,
      isCustomerApprovalRequired,
      isVisibleToHCP,
      contentDescription,
      webLink,
      htmlContent,
      customerUrl,
      replacementSecondaryFileName,
      replacementSecondaryFile,
    }: any) => {
      const defaultData = {
        submissionId,
        documentId,
        approvedByRegulator: docAttributes?.showApproval ? approvedByRegulator.value : null,
        regulatorApprovalDate: docAttributes?.showRegulatorApprovalDate
          ? getIsoStringWithTimezoneHandling(regulatorApprovalDate)
          : new Date(),
        authorisedDate: docAttributes?.showAuthorisedDate ? getIsoStringWithTimezoneHandling(authorisedDate) : null,
        details,
        reasonsForSubmission,
        userId,
        hasDigitalVersion: hasDigitalVersion ?? false,
        alternativeTextFileName: shouldHandleAlternativeText ? alternativeTextFileName : null,
        documentType: contentType,
        isCustomerApprovalRequired,
        isVisibleToHCP,
        contentDescription,
        link: docAttributes?.showRmmWebLink || docAttributes?.showAudioVideoWebLink ? enhanceWithHttps(webLink) : undefined,
        htmlContent: htmlContent.length > 0 ? htmlContent : undefined,
        replacementOriginalSecondaryFileName: replacementSecondaryFile?.name,
        replacementSecondaryFileName,
        customerLink: enhanceWithHttps(customerUrl),
      };

      const extendedData = { ...defaultData, documentTitle };

      const data = isFormExtended ? extendedData : defaultData;
      return updateDocument(submissionId, documentId, data);
    },
  );

  return { updateDocument: mutate };
};
