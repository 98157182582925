import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { requestChanges } from 'apps/publisher-v3.ui.customer/src/services/submissionTaskService';

interface IRequestChanges {
  taskId: number;
  additionalInformation: string;
}

export const useRequestChanges = () => {
  const { mutate, isLoading } = useWaitingMutation(({ taskId, additionalInformation }: IRequestChanges) =>
    requestChanges(taskId, { additionalInformation }),
  );

  return { requestChanges: mutate, isRequestingChanges: isLoading };
};
