import { useState } from 'react';
import { useDeleteFile } from '@hooks/useDeleteFile';
import { abortUpload } from '@api/uploadFileWithProgression';
import { getIsFileValid, getSubmitValidationErrors } from '@common/features/document/helpers';
import { FileRoles, RequestEpilQrdFormFields } from '@common/features/document/types';
import { useForm } from 'react-hook-form';
import { useUploadFile } from '@common/hooks/useUploadFile';
import { DocumentType } from '@common/types';
import { useRequestEpil } from './useRequestEpil';
import { useParams } from 'react-router';
import { useInlineFileUpload } from './useInlineFileUpload';

interface IRequestEpilForm {
  [RequestEpilQrdFormFields.file]: File | undefined;
  [RequestEpilQrdFormFields.isWithAlternativeText]: boolean;
  [RequestEpilQrdFormFields.alternativeTextFile]: File | undefined;
  [RequestEpilQrdFormFields.alternativeTextFileName]: string;
}

export const useRequestEpilModal = (onUploadSuccess: () => void) => {
  const { submissionId } = useParams();

  const { deleteFile } = useDeleteFile();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState<string | undefined>(undefined);

  const methods = useForm<IRequestEpilForm>({
    mode: 'onChange',
    defaultValues: {
      [RequestEpilQrdFormFields.file]: undefined,
      [RequestEpilQrdFormFields.isWithAlternativeText]: false,
      [RequestEpilQrdFormFields.alternativeTextFile]: undefined,
      [RequestEpilQrdFormFields.alternativeTextFileName]: '',
    },
  });

  const { setValue, reset, getValues, handleSubmit } = methods;

  const handleRemove = () => {
    setValue(RequestEpilQrdFormFields.file, undefined);
    clearFileName();
    abortUpload();
    if (!isLoading && fileName) {
      deleteFile(fileName);
    }
  };

  const {
    handleFileSubmit: handleAlternativeTextFileSubmit,
    handleRemove: handleAlternativeTextFileRemove,
    isLoading: isAlternativeTextFileBeingUploaded,
    fileErrors: alternativeTextFileErrors,
    setFileErrors: setInlineUploadFileErrors,
    clearErrors: clearAlternativeTextFileErrors,
    uploadProgress: alternativeTextFileUploadProgress,
  } = useInlineFileUpload({
    contentType: DocumentType.Epil,
    methods,
    fileFormField: RequestEpilQrdFormFields.alternativeTextFile,
    fileNameFormField: RequestEpilQrdFormFields.alternativeTextFileName,
    fileRole: FileRoles.AlternativeText,
  });

  const { uploadFile, isLoading, uploadProgress, fileName, fileErrors, setFileErrors, clearFileName } =
    useUploadFile(handleRemove);

  const handleFileSubmit = (files: FileList | null) => {
    const file = files?.item(0);
    const fileRole = FileRoles.PrimaryDocFile;
    if (file) {
      const errors = getIsFileValid(DocumentType.Epil, file, fileRole);
      if (errors.length > 0) {
        setFileErrors(errors);
      } else {
        setValue(RequestEpilQrdFormFields.file, file);
        uploadFile({ file, documentType: DocumentType.Epil, fileRole });
      }
    }
  };

  const { requestEpil } = useRequestEpil();

  const handleUploadClick = () => {
    const submitValidationErrors = getSubmitValidationErrors();

    if (!getValues(RequestEpilQrdFormFields.file)?.name) setFileErrors([submitValidationErrors.WORD_PIL_NOT_UPLOADED]);
    if (!getValues(RequestEpilQrdFormFields.alternativeTextFileName) && getValues(RequestEpilQrdFormFields.isWithAlternativeText))
      setInlineUploadFileErrors([submitValidationErrors.ALTERNATIVE_TEXT_FILE_MISSING]);
    else {
      handleSubmit(({ file, alternativeTextFileName }) =>
        requestEpil(
          { documentId, submissionId, fileName, originalFileName: file?.name, alternativeTextFileName },
          {
            onSuccess: () => {
              setIsModalOpen(false);
              setValue(RequestEpilQrdFormFields.file, undefined);
              setValue(RequestEpilQrdFormFields.isWithAlternativeText, false);
              setValue(RequestEpilQrdFormFields.alternativeTextFile, undefined);
              setValue(RequestEpilQrdFormFields.alternativeTextFileName, '');
              onUploadSuccess();
            },
          },
        ),
      )();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    clearErrors();
    clearAlternativeTextFileErrors();
    reset({});
    abortUpload();
    if (!isLoading && fileName) {
      deleteFile(fileName);
    }
  };

  const clearErrors = () => {
    setFileErrors([]);
    clearAlternativeTextFileErrors();
  };

  const handleOpenModal = (documentId?: string) => {
    setIsModalOpen(true);
    setDocumentId(documentId);
  };

  return {
    handleRequestEpilModalFileSubmit: handleFileSubmit,
    handleRequestEpilModalUploadClick: handleUploadClick,
    handleRequestEpilModalCancel: handleCancel,
    handleRequestEpilModalRemove: handleRemove,
    handleRequestEpilModalOpen: handleOpenModal,
    clearRequestEpilModalErrors: clearErrors,
    handleAlternativeTextFileSubmit,
    handleAlternativeTextFileRemove,
    isAlternativeTextFileBeingUploaded,
    alternativeTextFileUploadProgress,
    requestEpilModalFileErrors: [...fileErrors, ...alternativeTextFileErrors],
    isRequestEpilModalOpen: isModalOpen,
    isRequestEpilModalLoading: isLoading,
    requestEpilModalUploadProgress: uploadProgress,
    requestEpilModalMethods: methods,
  };
};
