import { Id } from '@common/types';
import { removeSubmissionDocument, removeProduct } from '@common/services/submissionService';
import useWaitingMutation from '@hooks/useWaitingMutation';

export type SubmissionDataType = 'document' | 'product';

export const useRemoveSubmissionData = (submissionId: Id, documentId: Id, type: SubmissionDataType) => {
  const { mutate } = useWaitingMutation((data: unknown) =>
    type === 'document' ? removeSubmissionDocument(submissionId, documentId) : removeProduct(submissionId, documentId),
  );

  return { mutate };
};
