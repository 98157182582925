import { getData } from '@common/services/requestService';
import { createAsyncThunkWithCustomErrorHandling } from '../helpers';

export const fetchUserData = createAsyncThunkWithCustomErrorHandling('user/user', async () => {
  const data = await getData('/user', '');

  return data;
});

export const fetchIdentityUserData = createAsyncThunkWithCustomErrorHandling('user/userInfo', async () => {
  const data = await getData('/userInfo', '', `${process.env.REACT_APP_AUTH_URL}/connect`, false);

  return data;
});
