import { useFormContext } from 'react-hook-form';
import { Section } from '@common/components/Section/Section';
import { SideDecorationContent } from '@common/components/SideDecorationContent/SideDecorationContent';
import { UploadedFile } from '@common/components/UploadedFile/UploadedFile';
import { isStringNullOrEmpty } from '@common/helpers';
import { DocumentTitleInput } from '../DocumentTitle/DocumentTitleInput';
import { DocumentRetireFormFields } from '../RetireModal/hooks/useRetireModalForm';
import { HookFormSelect } from '@common/components/Select/HookFormSelect';
import { RetireContentDetailsFormField } from './hooks/usePrepareRetireContentDetailsForm';
import { DocumentType } from '@common/types';
import { getRetireReasonOptions } from '@common/features/reasons/retirementReasons';
import * as Styled from './styles';
import * as SharedStyled from '@common/styles';
import { DocumentProcessingDetails } from './types';
import { getDocumentAttributes } from '../../helpers';
import { ReadonlyContentDetailsWeblink } from '../ContentDetailsWeblink/ReadonlyContentDetailsWeblink';

export interface IDocumentContent {
  documentTitle: string;
  fileName: string;
  url: string;
  isAdmin: boolean
  contentType: DocumentType;
  hasDigitalVersion?: boolean;
  documentProcessingDetails: DocumentProcessingDetails;
}

export const RetiredDocumentContent = ({ documentTitle, fileName, url, isAdmin, contentType, documentProcessingDetails }: IDocumentContent) => {
  const {
    formState: { errors },
  } = useFormContext();
  const dropdownError = errors[DocumentRetireFormFields.reason]?.message?.toString();

  const documentAttributes = getDocumentAttributes(contentType, isAdmin);
  const retireReasonOptions = getRetireReasonOptions(contentType);
  const hasUrl = !isStringNullOrEmpty(url) || !isStringNullOrEmpty(documentProcessingDetails.customerUrl);

  return (
    <Styled.SmpcDetailsWrap>
      <SharedStyled.SubmissionFlowWrap>
        <SharedStyled.SubmissionFlowInner>
          <Styled.SmpcDetailsHeadingWrap>
            <Styled.SmpcDetailsHeadingText>{contentType} details</Styled.SmpcDetailsHeadingText>
          </Styled.SmpcDetailsHeadingWrap>
          <SideDecorationContent>
            <Section text={`Your ${contentType}`}>
              <>
                {!hasUrl && documentAttributes.allowFileUpload ? (
                  <UploadedFile
                    active={[DocumentType.Pil, DocumentType.Smpc].includes(contentType)}
                    fileName={fileName!}
                  />
                ) : null}
              </>

              {hasUrl && <ReadonlyContentDetailsWeblink customerUrl={documentProcessingDetails.customerUrl} url={url} />}
            </Section>
            <Section text="Document title">
              <DocumentTitleInput title={documentTitle} isReadonly={true} />
            </Section>
            <Section text="Retirement reason">
              <HookFormSelect
                name={RetireContentDetailsFormField.reason}
                options={retireReasonOptions}
                rules={{ required: 'Please select a reason' }}
                error={dropdownError}
              />
            </Section>
          </SideDecorationContent>
        </SharedStyled.SubmissionFlowInner>
      </SharedStyled.SubmissionFlowWrap>
    </Styled.SmpcDetailsWrap>
  );
};
