import { Section } from '@common/components/Section/Section';
import { useCallback, useState } from 'react';
import { SideDecorationContent } from '@common/components/SideDecorationContent/SideDecorationContent';
import * as Styled from './styles';
import * as SharedStyled from '@common/styles';
import { SubmissionDetails } from '../SubmissionDetails/SubmissionDetails';
import { RegulatorApproval } from '../RegulatorApproval/RegulatorApproval';
import { regulatorApprovalOptions } from 'apps/publisher-v3.ui.customer/src/features/submission/constants';
import { DocumentType } from '@common/types';
import { DocumentTitleInput } from '../DocumentTitle/DocumentTitleInput';
import { UploadedFile } from '@common/components/UploadedFile/UploadedFile';
import { DocumentDetailsReadonlySections, DocumentProcessingFlow } from './types';
import { ContentDetailsFormField } from '../../pages/ContentDetails/types';
import { AdditionalTextFileSection } from '../AdditionalTextFileSection/AdditionalTextFileSection';
import { Controller, useFormContext } from 'react-hook-form';
import { useInlineFileUpload } from '../../hooks/useInlineFileUpload';
import { FileRoles } from '../../types';
import { NotifyModal } from '@common/components/NotifyModal';
import { FileError } from '@common/components/NotifyModal/component/FileError';
import { FormCheckboxesSection, HookFormRichTextEditor } from '@common/components';
import { epilConversionCheckboxTexts, pilAutoApproveCheckboxTexts } from '../../constants';
import { GroupedCheckboxSections } from '@common/styles';
import { HealthCareProfessional } from '../../components/HealthCareProfessional/HealthCareProfessional';
import { RichTextEditor } from '@common/components/RichTextEditor/RichTextEditor';
import { SubmissionReason } from '../SubmissionReason/SubmissionReason';
import { ReadonlySubmissionDetails } from '../SubmissionDetails/ReadonlySubmissionDetails';
import { ReadOnlySubmissionReason } from '../SubmissionReason/ReadonlySubmissionReason';
import { ISubmissionReasonDto } from '@common/features/submission/types';
import { documentTypeToCustomLabelMap, generalFormErrors } from '@common/constants';
import { IDocumentAttributes } from '../../helpers';
import { isStringNullOrEmpty, richTextEditorHttpsValidator } from '@common/helpers';
import { ContentDetailsWeblink } from '../ContentDetailsWeblink/ContentDetailsWeblink';
import { ReplaceSecondaryFile } from '../ReplaceSecondaryFile/ReplaceSecondaryFile';
import { IFileTitleAndSummary } from './hooks/useGenerateFileDescription';
import { ReadonlyAdditionalTextFileSection } from '../AdditionalTextFileSection/ReadonlyAdditionalTextFileSection';

export interface IDocumentContent {
  documentTitle: string;
  version: number;
  fileName: string;
  secondaryFileName: string | undefined;
  url: string | null;
  customerUrl: string | undefined;
  htmlContent: string;
  contentType: DocumentType;
  documentReadonlySections?: DocumentDetailsReadonlySections;
  documentAttributes: IDocumentAttributes;
  documentProcessingFlow: DocumentProcessingFlow;
  fileDescriptions: IFileTitleAndSummary;
  isAdmin?: boolean;
  onFileOpen?: () => void;
  onSecondaryFileOpen?: () => void;
  onAlternativeTextFileNameClick?: () => void;
}

export const DocumentContent = ({
  documentTitle,
  version,
  fileName,
  secondaryFileName,
  url,
  customerUrl,
  contentType,
  documentReadonlySections,
  documentAttributes,
  documentProcessingFlow,
  htmlContent,
  fileDescriptions,
  isAdmin,
  onFileOpen,
  onSecondaryFileOpen,
  onAlternativeTextFileNameClick,
}: IDocumentContent) => {
  const { ...methods } = useFormContext() ?? {};

  const errors = methods.formState.errors;

  let hasDigitalVersion = methods.watch(ContentDetailsFormField.hasDigitalVersion);

  let isCustomerApprovalRequired = methods.watch(ContentDetailsFormField.isCustomerApprovalRequired) ?? false;

  const uploadedFile = methods.watch(ContentDetailsFormField.alternativeTextFile);

  const alternativeTextFileName = methods.watch(ContentDetailsFormField.alternativeTextFileName);

  const secondaryUploadedFile = methods.watch(ContentDetailsFormField.replacementSecondaryFile);

  const errorTitle = `There was an issue uploading your alternative text file`;

  const { handleFileSubmit, handleRemove, isLoading, fileErrors, clearErrors, uploadProgress } = useInlineFileUpload({
    contentType,
    methods,
    fileFormField: ContentDetailsFormField.alternativeTextFile,
    fileNameFormField: ContentDetailsFormField.alternativeTextFileName,
    fileRole: FileRoles.AlternativeText,
  });

  const {
    handleFileSubmit: secondaryFileSubmit,
    handleRemove: secondaryRemove,
    isLoading: secondaryLoading,
    fileErrors: secondaryErrors,
    clearErrors: secondaryClearErrors,
    uploadProgress: secondaryProgress,
  } = useInlineFileUpload({
    contentType,
    methods,
    fileFormField: ContentDetailsFormField.replacementSecondaryFile,
    fileNameFormField: ContentDetailsFormField.replacementSecondaryFileName,
    fileRole: FileRoles.SecondaryPDFFile,
  });

  const maxLengthContentDescription = 'Length greater than 3000 chars';

  const validateHtml = useCallback((value: any) => {
    if (!value) return true;
    var text = value.replaceAll(/<(.|\n)*?>/g, '');
    if (text.length > 3000) return maxLengthContentDescription;
    else return true;
  }, []);

  const hasUrl = !isStringNullOrEmpty(url) || !isStringNullOrEmpty(customerUrl);

  const submissionReason = methods.watch(ContentDetailsFormField.reasonsForSubmission) as ISubmissionReasonDto[];
  const hasHtmlContent = htmlContent.length > 0;

  const htmlContentToolbarOptions = [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }],
    ['link'],
  ];

  const contentDescriptionToolbarOptions = documentAttributes?.allowLinkInHtmlContent
    ? [['bold', 'italic', 'underline'], ['link']]
    : undefined;

  const handleWebLinkClear = (inputName: 'customer' | 'datapharm') => {
    if (inputName === 'datapharm') methods.setValue(ContentDetailsFormField.webLink, '');
    if (inputName === 'customer') methods.setValue(ContentDetailsFormField.customerUrl, '');
  };

  const [replaceSecondaryDocumentOpen, setReplaceSecondaryDocumentOpen] = useState<boolean>(false);

  const uploadedSecondaryFile = methods.watch(ContentDetailsFormField.replacementSecondaryFile);

  const hasFile = fileName;

  const renderAlternativeTextSection = () =>
    documentReadonlySections?.hasAlternativeTextFile ? (
      <ReadonlyAdditionalTextFileSection
        withBorderAndSubtext
        alternativeTextFileName={alternativeTextFileName}
        onAlternativeTextFileDownload={onAlternativeTextFileNameClick!}
      />
    ) : (
      <AdditionalTextFileSection
        field={ContentDetailsFormField.hasAlternativeTextFile}
        withBorderAndSubtext
        disabled={documentReadonlySections?.hasAlternativeTextFile}
        isLoading={isLoading}
        uploadProgress={uploadProgress}
        uploadedFileName={uploadedFile?.name}
        uploadedFileSize={uploadedFile?.size}
        handleFileSubmit={handleFileSubmit}
        handleRemove={handleRemove}
      />
    );

  return (
    <Styled.SmpcDetailsWrap>
      <SharedStyled.SubmissionFlowWrap>
        <SharedStyled.SubmissionFlowInner>
          <Styled.SmpcDetailsHeadingWrap>
            <Styled.SmpcDetailsHeadingText>{documentTypeToCustomLabelMap[contentType]} details</Styled.SmpcDetailsHeadingText>
          </Styled.SmpcDetailsHeadingWrap>
          <SideDecorationContent>
            <Section data-testid="title" text={`Your ${fileDescriptions.primaryFileDescription}`}>
              <>
                {fileDescriptions.primaryFileHeader ? <p>{fileDescriptions.primaryFileHeader}</p> : null}

                {!hasUrl && documentAttributes.allowFileUpload ? (
                  <UploadedFile
                    active={[
                      DocumentType.Pil,
                      DocumentType.Smpc,
                      DocumentType.Dhcp,
                      DocumentType.Rmm,
                      DocumentType.UserManual,
                    ].includes(contentType)}
                    fileName={fileName}
                    onClick={onFileOpen}
                  />
                ) : null}

                {!hasFile ? (
                  <ContentDetailsWeblink
                    documentAttributes={documentAttributes}
                    customerUrl={customerUrl}
                    url={url}
                    datapharmLinkFieldName={ContentDetailsFormField.webLink}
                    customerLinkFieldName={ContentDetailsFormField.customerUrl}
                    documentReadonlySections={documentReadonlySections}
                    onClearInput={handleWebLinkClear}
                    errors={errors}
                  />
                ) : null}

                {hasHtmlContent && documentAttributes.allowHtmlContentUpload ? (
                  <HookFormRichTextEditor
                    className="addModalEditorWrapper"
                    toolbarOptions={htmlContentToolbarOptions}
                    name={`${contentType} htmlContent`}
                    formField={ContentDetailsFormField.htmlContent}
                    rules={{
                      validate: richTextEditorHttpsValidator,
                    }}
                    onRawContentChange={(rawContent) =>
                      rawContent !== undefined && rawContent.toString().length > 5001 ? 'Max. length: 5000 characters' : undefined
                    }
                  />
                ) : null}
              </>
            </Section>
            {documentProcessingFlow === DocumentProcessingFlow.qrdPil ? (
              <Section data-testid="title" text={`Your ${fileDescriptions.secondaryFileDescription}`}>
                <>
                  <p>{fileDescriptions.secondaryFileHeader}</p>
                  {!hasUrl ? (
                    <UploadedFile
                      onReplaceClick={
                        !isAdmin
                          ? () => {
                              setReplaceSecondaryDocumentOpen(true);
                            }
                          : undefined
                      }
                      active={[DocumentType.Pil].includes(contentType) && !secondaryUploadedFile}
                      fileName={uploadedSecondaryFile?.name ?? secondaryFileName}
                      onClick={onSecondaryFileOpen && !secondaryUploadedFile ? onSecondaryFileOpen : () => {}}
                    />
                  ) : null}
                  {replaceSecondaryDocumentOpen ? (
                    <ReplaceSecondaryFile
                      field={ContentDetailsFormField.replacementSecondaryFile}
                      withBorderAndSubtext
                      isLoading={secondaryLoading}
                      uploadProgress={secondaryProgress}
                      uploadedFileName={secondaryUploadedFile?.name}
                      uploadedFileSize={secondaryUploadedFile?.size}
                      handleFileSubmit={secondaryFileSubmit}
                      handleRemove={secondaryRemove}
                      onClose={() => setReplaceSecondaryDocumentOpen(false)}
                    ></ReplaceSecondaryFile>
                  ) : null}
                </>
              </Section>
            ) : null}
            <Section text="Document title">
              <DocumentTitleInput
                title={documentTitle}
                isReadonly={documentReadonlySections?.documentTitle}
                rules={{
                  maxLength: {
                    value: 200,
                    message: generalFormErrors.documentTitleLength,
                  },
                }}
                error={errors[ContentDetailsFormField.documentTitle]?.message!.toString()}
              />
            </Section>
            {documentProcessingFlow === DocumentProcessingFlow.standard && contentType === DocumentType.Pil ? (
              <GroupedCheckboxSections>
                <FormCheckboxesSection
                  data-testId="isCustomerApprovalRequired"
                  field={ContentDetailsFormField.isCustomerApprovalRequired}
                  value={isCustomerApprovalRequired}
                  text={pilAutoApproveCheckboxTexts.title}
                  withBorderAndSubtext
                />
                <FormCheckboxesSection
                  data-testId="hasDigitalVersion"
                  field={ContentDetailsFormField.hasDigitalVersion}
                  value={!!hasDigitalVersion}
                  text={epilConversionCheckboxTexts.title}
                  subtextSection={epilConversionCheckboxTexts.subtitle}
                  withBorderAndSubtext
                  disabled={documentReadonlySections?.hasDigitalVersion}
                />
                {!!hasDigitalVersion && renderAlternativeTextSection()}
              </GroupedCheckboxSections>
            ) : null}

            {documentAttributes && documentAttributes.showApproval ? (
              <Section text="Regulator approval">
                <RegulatorApproval
                  regulatorApprovalOptions={regulatorApprovalOptions}
                  contentType={contentType}
                  documentReadonlySections={documentReadonlySections}
                />
              </Section>
            ) : null}

            {documentAttributes && documentAttributes.showHcpOnly ? (
              <>
                <Section text="Audience Visibility">
                  <HealthCareProfessional />
                </Section>
              </>
            ) : null}

            {documentAttributes && documentAttributes.showContentDescription ? (
              <Section text="Content Description">
                <Controller
                  name={ContentDetailsFormField.contentDescription}
                  rules={{
                    validate: validateHtml,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <RichTextEditor
                      className="contentDescriptionEditor"
                      fieldName={ContentDetailsFormField.contentDescription}
                      value={value}
                      onChange={onChange}
                      toolbarOptions={contentDescriptionToolbarOptions}
                    />
                  )}
                />
              </Section>
            ) : null}

            {documentAttributes && documentAttributes.showFreeTextReason ? (
              <Section text="Reasons For Submission">
                {documentReadonlySections?.reasonsForSubmission ? (
                  <ReadOnlySubmissionReason reasonsForSubmission={submissionReason} />
                ) : (
                  <SubmissionReason />
                )}
              </Section>
            ) : null}

            {documentAttributes && !documentAttributes.showFreeTextReason ? (
              <Section text="Submission details">
                {documentReadonlySections?.reasonsForSubmission ? (
                  <ReadonlySubmissionDetails
                    contentType={contentType}
                    version={version}
                    details=""
                    reasonsForSubmission={submissionReason}
                  />
                ) : (
                  <SubmissionDetails contentType={contentType} version={version} />
                )}
              </Section>
            ) : null}
          </SideDecorationContent>
        </SharedStyled.SubmissionFlowInner>
      </SharedStyled.SubmissionFlowWrap>
      <NotifyModal title={errorTitle} isOpen={fileErrors.length > 0 || secondaryErrors.length > 0} width="32rem">
        <FileError
          onClose={() => {
            clearErrors();
            secondaryClearErrors();
          }}
          errors={fileErrors.concat(secondaryErrors)}
          type={contentType}
        />
      </NotifyModal>
    </Styled.SmpcDetailsWrap>
  );
};
