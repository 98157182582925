import {
  closestCorners,
  DndContext,
  DragOverEvent,
  DragOverlay,
  MouseSensor,
  SensorDescriptor,
  SensorOptions,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import React, { ReactNode, useState } from 'react';
import { DndColumn, DndVisualCue, DragHandle, SortableItemWrapper, WidgetsDndWrapper } from './styles';
import { WidgetData } from '@common/pages/Home/types';

export type Items = {
  [key: string]: React.ReactNode;
};

interface SortableItemProps {
  item: ReactNode;
  itemId: string;
  overId: any;
  showHandle?: boolean;
}

const SortableItem: React.FC<SortableItemProps> = ({ item, itemId, overId, showHandle }) => {
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({ id: itemId });
  return (
    <SortableItemWrapper>
      {overId === itemId && <DndVisualCue />}
      {isDragging ? null : (
        <div ref={setNodeRef}>
          {showHandle ? <DragHandle {...attributes} {...listeners} /> : null}
          {item}
        </div>
      )}
    </SortableItemWrapper>
  );
};

export const useWidgetsDragAndDrop = (
  dashboardData: WidgetData[] | null,
  setDashboardData: React.Dispatch<React.SetStateAction<WidgetData[] | null>>,
) => {
  const mouseSensor = useSensor(MouseSensor);
  const sensors = useSensors(mouseSensor);

  const [activeId, setActiveId] = useState<string>();
  const [overId, setOverId] = useState<string>();
  const [items, setItems] = useState<Items>();

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };

  const handleDragOver = (event: any) => {
    setOverId(event.over?.id);
  };

  const handleDragEnd = () => {
    if (!overId || !activeId || !dashboardData) return;

    if ([0, 1, 2].includes(Number(overId))) {
      handleDropAsLastItem(dashboardData, setDashboardData, overId, activeId);
    } else {
      handleDropInPlaceOfItem(dashboardData, setDashboardData, overId, activeId);
    }

    adjustRowsAfterGrabbedTakenAway(dashboardData, setDashboardData, activeId);

    setActiveId(undefined);
    setOverId(undefined);
  };

  const activeItem = items && activeId ? items[activeId] : null;

  return {
    items,
    activeItem,
    sensors,
    overId,
    setItems,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  };
};

const adjustRowsAfterGrabbedTakenAway = (
  dashboardData: WidgetData[],
  setDashboardData: (value: React.SetStateAction<WidgetData[] | null>) => void,
  activeId: string,
) => {
  const activePosition = dashboardData.find((p) => p.widgetType === activeId);

  setDashboardData((widgets) =>
    widgets!.map((widget) =>
      widget.pos.col === activePosition!.pos.col && widget.pos.row > activePosition!.pos.row
        ? { ...widget, pos: { ...widget.pos, row: widget.pos.row - 1 } }
        : widget,
    ),
  );
};

const handleDropInPlaceOfItem = (
  dashboardData: WidgetData[],
  setDashboardData: (value: React.SetStateAction<WidgetData[] | null>) => void,
  overId: string,
  activeId: string,
) => {
  const overPosition = dashboardData.find((d) => d.widgetType === overId);

  setDashboardData((widgets) =>
    widgets!.map((widget) => {
      if (widget.widgetType === activeId) {
        return { ...widget, pos: { col: overPosition!.pos.col, row: overPosition!.pos.row } };
      }

      if (widget.pos.col === overPosition!.pos.col && widget.pos.row >= overPosition!.pos.row) {
        return { ...widget, pos: { ...widget.pos, row: widget.pos.row + 1 } };
      }

      return widget;
    }),
  );
};

const handleDropAsLastItem = (
  dashboardData: WidgetData[],
  setDashboardData: (value: React.SetStateAction<WidgetData[] | null>) => void,
  overId: string,
  activeId: string,
) => {
  const rowsInColumn = dashboardData.filter((widget) => widget.pos.col === Number(overId) && widget.widgetType !== activeId);
  if (rowsInColumn.length === 0) {
    setDashboardData((widgets) =>
      widgets!.map((widget) => (widget.widgetType === activeId ? { ...widget, pos: { col: Number(overId), row: 0 } } : widget)),
    );
  } else {
    const highestIndexInColumn = Math.max(...rowsInColumn.map((widget) => widget.pos.row));
    setDashboardData((widgets) =>
      widgets!.map((widget) =>
        widget.widgetType === activeId ? { ...widget, pos: { col: Number(overId), row: highestIndexInColumn + 1 } } : widget,
      ),
    );
  }
};

interface WidgetsDragAndDropProps {
  activeItem: ReactNode;
  sensors: SensorDescriptor<SensorOptions>[];
  dashboardData: WidgetData[];
  overId: string | undefined;
  items: Items;
  disabled?: boolean;
  handleDragStart: (event: any) => void | undefined;
  handleDragOver: (event: any) => void | undefined;
  handleDragEnd: (event: DragOverEvent) => void | undefined;
}

const WidgetsDragAndDrop = ({
  sensors,
  dashboardData,
  items,
  overId,
  activeItem,
  disabled,
  handleDragEnd,
  handleDragOver,
  handleDragStart,
}: WidgetsDragAndDropProps) => (
  <DndContext
    sensors={sensors}
    collisionDetection={closestCorners}
    onDragEnd={handleDragEnd}
    onDragStart={handleDragStart}
    onDragOver={handleDragOver}
  >
    <SortableContext items={Object.keys(items)} disabled={disabled}>
      <WidgetsDisplay dashboardData={dashboardData} items={items} overId={overId} activeItem={activeItem} disabled={disabled} />
    </SortableContext>
  </DndContext>
);

interface WidgetDisplayProps {
  dashboardData: WidgetData[];
  items: Items;
  overId?: string;
  activeItem?: ReactNode;
  disabled?: boolean;
}

const WidgetsDisplay = ({ dashboardData, items, overId, activeItem, disabled }: WidgetDisplayProps) => (
  <WidgetsDndWrapper>
    {[0, 1, 2].map((i) => {
      const itemsInColumn = dashboardData.filter((widget) => {
        return widget.pos?.col === i;
      });
      const sortedItemsInColumn = itemsInColumn.sort((a, b) => a.pos.row - b.pos.row);

      return (
        <DndColumn key={i}>
          {sortedItemsInColumn.map((data) => {
            const widget = items[data.widgetType];

            return widget ? (
              <SortableItem key={data.widgetType} itemId={data.widgetType} item={widget} overId={overId} showHandle={!disabled} />
            ) : null;
          })}
          {overId && <SortableItem itemId={i.toString()} item={<div />} overId={overId} />}
        </DndColumn>
      );
    })}
    {activeItem && <DragOverlay style={{ opacity: 0.7 }}>{activeItem}</DragOverlay>}
  </WidgetsDndWrapper>
);

export { SortableItem, WidgetsDragAndDrop, WidgetsDisplay };
