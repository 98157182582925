import { updateSubmissionActiveIngredients } from '@common/services/submissionService';
import { selectActiveCompany, selectUserId } from '@common/features/user/selectors';
import useWaitingMutation from '@hooks/useWaitingMutation';
import { UpdateSubmissionActiveIngredientsCommand } from '../../types';
import { useAppSelector } from '@common/hooks/redux';

interface IUpdateSubmissionActiveIngredients {
  submissionId: string;
  payload: UpdateSubmissionActiveIngredientsCommand;
}

export const useUpdateSubmissionActiveIngredients = () => {
  const activeCompany = useAppSelector(selectActiveCompany);
  const companyId = activeCompany?.id!;
  const userId = useAppSelector(selectUserId);

  const { mutate } = useWaitingMutation(({ submissionId, payload }: IUpdateSubmissionActiveIngredients) =>
    updateSubmissionActiveIngredients(submissionId, { ...payload, companyId, userId }),
  );

  return { updateSubmissionActiveIngredients: mutate };
};
