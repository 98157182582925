import { useQuery } from '@tanstack/react-query';
import { getPublishedDocument } from '@services/submissionTaskService';
import { DocumentExtensionType, DocumentType } from '@common/types';

export const useFetchPublishedDocument = (
  handleAsFileStream: boolean,
  documentId?: string,
  version?: string,
  documentType?: DocumentType,
  extensionType?: DocumentExtensionType,
  enabled?: boolean,
) => {
  const getQueryString = () => {
    const queryParts = [];

    if (extensionType) {
      queryParts.push(`extensionType=${extensionType}`);
    }

    return queryParts.join('&');
  };

  const queryString = getQueryString();

  const { data, isFetching, refetch } = useQuery(
    ['getPublishedDocument', documentId, version, documentType, queryString],
    () => getPublishedDocument(documentId!, version!, documentType!, queryString, handleAsFileStream, false),
    {
      enabled: !!documentId && !!version && !!documentType && (enabled ?? true),
    },
  );

  return { data, documentFetching: isFetching, refetch };
};
