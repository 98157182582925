import axios, { AxiosError, AxiosProgressEvent } from 'axios';
import { RequestType } from '../services/types';
import { getUser } from '@common/auth/useOidcAuthentication';

let controller = new AbortController();

export interface IMappedAxiosError {
  message: string;
  cause: number;
  data: any;
}

export const abortUpload = () => {
  controller.abort();
  controller = new AbortController();
};

export const sendAxiosFileRequest = async (
  requestType: RequestType,
  formData: FormData,
  url: string,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
) => {
  const token = getUser()?.access_token;

  const headers = {
    Authorization: `Bearer ${token}`,
    'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`,
  };

  try {
    const response = await axios.request({
      method: requestType,
      url: url,
      data: formData,
      headers: headers,
      onUploadProgress,
      signal: controller.signal,
    });

    return response.data;
  } catch (e: any) {
    if (e.code === AxiosError.ERR_CANCELED) return AxiosError.ERR_CANCELED;

    const messageWithStatus: IMappedAxiosError = {
      message: e.response.statusText,
      cause: e.response.status,
      data: e.response.data,
    };

    return Promise.reject(messageWithStatus);
  }
};
