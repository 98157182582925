import { useQuery } from '@tanstack/react-query';
import { fetchMaNumbersConfig } from '@common/services/productService';

export const queryKey = 'fetchMaNumbersConfig';

export const useFetchMaNumbersConfig = () => {
  const { data, isFetching: isFetchingMaNumbersConfig } = useQuery([queryKey], () => fetchMaNumbersConfig(), {});

  return {
    maNumbersConfig: data,
    isFetchingMaNumbersConfig,
  };
};
