import axios from 'axios';

export const setupAxiosInterceptors = ({ onUnauthorised }: { onUnauthorised: () => void }) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        onUnauthorised();
      }
      return Promise.reject(error);
    },
  );
};
