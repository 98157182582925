import { createSlice } from '@reduxjs/toolkit';
import { ICompanyPayload, ICompanyPermission, IUserState } from './types';
import { fetchIdentityUserData, fetchUserData } from './thunks';
import { storeDataLayerValue } from '@common/services/dataLayerService';
import { setHasUserInsufficientPermissions } from './userActions';

export const initialState: IUserState = {
  error: '',
  userData: {
    user: {
      userId: null,
      name: '',
      email: '',
    },
    isAdmin: false,
  },
  companyPermissions: [],
  activeCompany: undefined,
  hasUserInsufficientPermissions: false,
  documentConversionResults: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setActiveCompany(state, action) {
      const userId = action.payload.userId;
      localStorage.setItem(userId, JSON.stringify(action.payload));
      state.activeCompany = action.payload;
    },
    updateDocumentConversionResults(state, action) {
      state.documentConversionResults = action.payload;
    },
    addDocumentConversionResult(state, action) {
      state.documentConversionResults = [...state.documentConversionResults, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.companyPermissions = action.payload.companyPermissions;
      state.activeCompany = handleActiveCompany(action.payload);
      storeDataLayerValue({ userId: action.payload.user.userId });
    });
    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(fetchIdentityUserData.fulfilled, (state, action) => {
      state.userData.user.name = action.payload.user_name;
      state.userData.user.userId = action.payload.user_id;
      state.userData.user.email = action.payload.email;
    });
    builder.addCase(fetchIdentityUserData.rejected, (state, action) => {
      state.error = action.error.message;
    });
    // Added to avoid cyclic dependency to userSlice
    builder.addCase(setHasUserInsufficientPermissions, (state, action) => {
      state.hasUserInsufficientPermissions = action.payload;
    });
  },
});

const handleActiveCompany = (companyPayload: ICompanyPayload) => {
  const userId = companyPayload.user.userId?.toString() as string;

  if (!localStorage.getItem(userId)) {
    storeAndReturnDefaultPermission(companyPayload);
  }

  const storedUserData = JSON.parse(localStorage.getItem(userId) as string);
  const hasPermissionForStoredCompany = hasUserCompanyPermission(companyPayload.companyPermissions, storedUserData.id);

  if (!hasPermissionForStoredCompany) {
    storeAndReturnDefaultPermission(companyPayload);
  }

  return storedUserData;
};

const hasUserCompanyPermission = (permissions: ICompanyPermission[], companyId: number) => {
  const matchedCompanies = permissions.filter((item) => item.id === companyId);
  return matchedCompanies.length > 0;
};

const storeAndReturnDefaultPermission = (companyPayload: ICompanyPayload) => {
  const userId = companyPayload.user.userId?.toString() as string;
  localStorage.setItem(userId, JSON.stringify(companyPayload.companyPermissions[0]));

  return companyPayload.companyPermissions[0];
};

export const { setActiveCompany, updateDocumentConversionResults, addDocumentConversionResult } = userSlice.actions;

export default userSlice.reducer;
