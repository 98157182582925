import { buildUrl, handleRequest } from '@common/services/requestHelper';
import { RequestType } from '@common/services/types';

const basePath = process.env.REACT_APP_API_PATH;

const fetchDashboard = async () => {
  const url = buildUrl(`/dashboard`, basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

export { fetchDashboard };
