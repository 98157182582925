import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from '@common/store';
import GlobalStyle from '@common/globalStyles';
import theme from '@common/theme';
import { oidcConfig } from '@common/auth/useOidcAuthentication';
import { AuthProvider } from 'react-oidc-context';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <AuthProvider {...oidcConfig}>
          <App />
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
