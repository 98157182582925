import { ISelectOption, Id } from '@common/types';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { discontinueProduct } from '@common/services/submissionService';
import { useParams } from 'react-router';
import { useAppSelector } from './redux';
import { selectUserId } from '@common/features/user/selectors';

interface Props {
  productId: Id;
  reason: ISelectOption;
}

export const useDiscontinueProduct = () => {
  const { submissionId = '' } = useParams();

  const userId = useAppSelector(selectUserId);

  const { mutate, isLoading } = useWaitingMutation(({ productId, reason }: Props) =>
    discontinueProduct(submissionId, productId, { userId, reasonId: reason.id, reason: reason.value }),
  );

  return { discontinueProduct: mutate, isDiscontinuingProduct: isLoading };
};
