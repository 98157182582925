import React, { useEffect, useMemo, useState } from 'react';
import { selectActiveCompany } from '@common/features/user/selectors';
import { useFetchCompanySubmissions } from '@common/features/submission/hooks/useFetchCompanySubmissions';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { prepareDetailsColumns, prepareSubmissionsColumns, useSubmissionsTableSorting } from './submissionsTableSetup';
import { Id } from '@common/types';
import { SubmissionStatus, SubmissionTaskStatuses } from '@common/services/types';
import { useConfirmSubmissionCancellation } from '@common/components/ConfirmSubmissionCancellation/useConfirmSubmissionCancellation';
import { ConfirmSubmissionCancellation } from '@common/components/ConfirmSubmissionCancellation';
import { RequestChangesModal } from '@common/components/RequestChangesModal';
import { useRequestChangesModal } from '@common/components/RequestChangesModal/useRequestChangesModal';
import { CancelSubmissionTaskModal } from '@common/components/CancelSubmissionTaskModal';
import { useCancelSubmissionTaskModal } from '@common/components/CancelSubmissionTaskModal/useCancelSubmissionTaskModal';
import { RequestSubmittedModal } from '@common/components/RequestSubmittedModal';
import { useDownloadAndOpenFile } from '@hooks/useDownloadAndOpenFile';
import { useTaskStatusChange } from '@common/hooks/useTaskStatusChange';
import useAppNavigation from '../../routing/useAppNavigation';
import { Button, ButtonTypes, HtmlContentModal, SearchInput, useHtmlContentModal } from '@common/components';
import { useSearch } from '@common/components/SearchInput/useSearch';
import { FilteringSection } from '@common/components/FiltersDropdown/styles';
import { CheckboxFilter, DateFilter, FiltersDropdown, YesNoFilter } from '@common/components/FiltersDropdown';
import { useSubmissionsListFilters } from './hooks/useSubmissionsListFilters';
import { mapSubmissionsTasks } from './helpers';
import { useFetchTasksForMultipleSubmissions } from '@common/features/submission/hooks/useFetchTasksForMultipleSubmissions';
import { ICompanySubmission } from '@common/features/submission/types';
import { Row } from '@tanstack/react-table/build/lib';
import { useInfiniteLoader } from '@hooks/useInfiniteLoader';
import theme from '@common/theme';
import { useConfirmSubmissionRemoval } from '@components/ConfirmSubmissionRemoval/useConfirmSubmissionRemoval';
import { ConfirmSubmissionRemoval } from '@components/ConfirmSubmissionRemoval/ConfirmSubmissionRemoval';
import { useGetPublicProductGroupState } from '@features/productGroup/hooks/useGetPublicProductGroupState';
import * as SharedStyled from '@common/styles';
import * as Styled from './styles';
import { IDocumentContentInfo, useFetchDocumentHtmlContent } from '@common/hooks/useFetchDocumentHtmlContent';
import { htmlHeader } from '@common/constants';
import { useAppSelector } from '@common/hooks/redux';

const Submissions = () => {
  const [submissionsIdsToFetchTasks, setSubmissionsIdsToFetchTasks] = useState<number[]>([]);
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const { goToProductGroup, goToSubmissionTaskDetails, goToPublishedProductGroup } = useAppNavigation();
  const { htmlContentModalParams, handleViewContent, closeHtmlContentModal } = useHtmlContentModal();
  const { downloadAndOpen } = useDownloadAndOpenFile();
  const { goToHtml } = useAppNavigation();
  const { searchPhrase, displayPhrase, searchError, handleChangeSearch, handleClearSearch } = useSearch();
  const {
    filtersQueryString,
    filtersDropdownProps,
    submissionStatusFilterProps,
    fastTrackFilterProps,
    dateFilterProps,
    contentTypeFilterProps,
    taskStatusFilterProps,
  } = useSubmissionsListFilters(() => setExpanded({}));

  const { sortingQuery, customSorting } = useSubmissionsTableSorting();
  const { recordLimit, increaseOffset, resetOffset, offset, initialRecordOffset } = useInfiniteLoader();
  const { getPublicProductGroupState } = useGetPublicProductGroupState();

  const { id } = useAppSelector(selectActiveCompany) ?? {};
  const { companySubmissions, isLoadingSubmissions, isFetchingSubmissions, refetchCompanySubmissions, totalCount } =
    useFetchCompanySubmissions(
      id!,
      filtersQueryString,
      searchPhrase,
      sortingQuery,
      recordLimit,
      offset,
      offset === initialRecordOffset,
    );
  const { changeTaskStatus, isChangingTaskStatus } = useTaskStatusChange();

  const { fetchDocumentHtmlContent } = useFetchDocumentHtmlContent();

  const tasksQueries = useFetchTasksForMultipleSubmissions(submissionsIdsToFetchTasks);
  const fetchedTasks = useMemo(
    () => mapSubmissionsTasks(tasksQueries, submissionsIdsToFetchTasks),
    [submissionsIdsToFetchTasks, tasksQueries],
  );

  const refetchSubmissionTasks = (submissionId: number) => {
    const index = submissionsIdsToFetchTasks?.findIndex((id) => id === submissionId);
    const taskQuery = tasksQueries[index];
    if (!!taskQuery) {
      taskQuery.refetch();
    }
  };

  useEffect(() => {
    resetOffset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingQuery, searchPhrase, filtersQueryString]);

  const refreshData = (submissionId: number) => {
    refetchSubmissionTasks(submissionId);
    refetchCompanySubmissions();
  };

  const {
    cancelSubmissionTaskForm,
    idForCancelSubmission,
    isCancellingTask,
    handleCancelSubmissionTask,
    handleCancelSubmissionTaskCancel,
    handleCancelSubmissionTaskSubmit,
  } = useCancelSubmissionTaskModal((submissionId) => {
    refreshData(submissionId);
  });

  const {
    requestChangesForm,
    idForRequestChange,
    requestSubmittedOpen,
    isRequestingChanges,
    handleRequestChanges,
    handleRequestSubmittedConfirm,
    handleRequestChangesCancel,
    handleRequestChangesSubmit,
  } = useRequestChangesModal((submissionId) => refetchSubmissionTasks(submissionId));

  const {
    submissionIdForCancel,
    isCancellingSubmission,
    handleSubmissionCancel,
    handleConfirmCancellationCancel,
    handleConfirmCancellation,
  } = useConfirmSubmissionCancellation((submissionId) => refreshData(submissionId));

  const {
    isRemovingSubmission,
    submissionIdForRemoval,
    handleSubmissionRemoval,
    handleConfirmRemoval,
    handleRemovalCancellationCancel,
  } = useConfirmSubmissionRemoval((submissionId) => refreshData(submissionId));

  const handleRowExpand = async (row: Row<ICompanySubmission>, isExpanded: boolean) => {
    setExpanded((prevExpanded) =>
      !!prevExpanded[row?.index] ? { ...prevExpanded, [row?.index]: false } : { ...prevExpanded, [row?.index]: true },
    );

    if (!isExpanded) {
      setSubmissionsIdsToFetchTasks((prev) => [...prev, row?.original?.submissionId]);
    }
  };

  const handleApprove = (taskId: number, submissionId: number) =>
    changeTaskStatus(
      { taskId: taskId.toString(), status: SubmissionTaskStatuses.Approved },
      {
        onSuccess: () => {
          if (!isChangingTaskStatus) {
            refreshData(submissionId);
          }
        },
      },
    );

  const handleSubmissionDetailsClick = (submissionId: number, status: SubmissionStatus, existingProductGroupId?: number) => {
    if (status === SubmissionStatus.Published) {
      getPublicProductGroupState(
        { productGroupId: existingProductGroupId, propagateError: false },
        {
          onSuccess: (result) => {
            if (result == null) {
              goToProductGroup(submissionId.toString());
            } else {
              goToPublishedProductGroup(existingProductGroupId!.toString(), submissionId);
            }
          },
        },
      );
    } else {
      goToProductGroup(submissionId.toString());
    }
  };

  const handleSubmissionTaskDiscontinuationDetailsClick = (submissionId: number) => {
    goToProductGroup(submissionId.toString());
  };

  const submissionsColumns = prepareSubmissionsColumns({
    onCancel: handleSubmissionCancel,
    onRemove: handleSubmissionRemoval,
    onSubmissionDetailsClick: handleSubmissionDetailsClick,
  });

  const handleSubmissionTaskDetailsClick = (submissionTaskId: number, submissionId: number) => {
    goToSubmissionTaskDetails(submissionId.toString(), submissionTaskId.toString());
  };

  const handleFileOpen = (taskId: Id) => downloadAndOpen({ taskId });

  const handleViewConvertedContent = (submissionTaskId: string, submissionId: string) => {
    goToHtml(submissionId, submissionTaskId);
  };

  const handleViewHtmlClick = async (documentData: IDocumentContentInfo) => {
    const data = await fetchDocumentHtmlContent(documentData);

    handleViewContent(data, htmlHeader);
  };

  const detailsColumns = prepareDetailsColumns({
    onApprove: handleApprove,
    onCancel: handleCancelSubmissionTask,
    onRequestChanges: handleRequestChanges,
    onDocumentTaskDetailsClick: handleSubmissionTaskDetailsClick,
    onProductTaskDetailsClick: handleSubmissionTaskDiscontinuationDetailsClick,
    onFileOpen: handleFileOpen,
    onViewConvertedContent: handleViewConvertedContent,
    onViewHtmlContent: handleViewHtmlClick,
  });

  const companySubmissionsWithDetails = companySubmissions.map((s) => {
    const index = submissionsIdsToFetchTasks?.findIndex((id) => id === s.submissionId);
    const isLoading = tasksQueries[index]?.isLoading;

    return {
      ...s,
      expandableContent: (
        <Styled.DetailsTableWrapper>
          <DatapharmTable
            columns={detailsColumns}
            documents={fetchedTasks[s.submissionId] ?? []}
            showLoader={isChangingTaskStatus || isLoading}
          />
        </Styled.DetailsTableWrapper>
      ),
    };
  });

  const shouldShowResultsCount = !(isFetchingSubmissions || isLoadingSubmissions);
  const shouldShowLoadMoreButton =
    companySubmissionsWithDetails.length !== totalCount &&
    !(offset === initialRecordOffset && (isFetchingSubmissions || isLoadingSubmissions));

  return (
    <>
      <Styled.SubmissionsWrap>
        <SharedStyled.PageTitle>Submissions</SharedStyled.PageTitle>
        <FilteringSection>
          <SearchInput
            id="allSubmissionsSearch"
            name="Search"
            value={displayPhrase}
            onChange={handleChangeSearch}
            onClearInput={handleClearSearch}
            error={searchError}
          />
          <FiltersDropdown {...filtersDropdownProps}>
            <CheckboxFilter {...submissionStatusFilterProps} />
            <YesNoFilter {...fastTrackFilterProps} />
            <DateFilter {...dateFilterProps} />
            <CheckboxFilter {...taskStatusFilterProps} />
            <CheckboxFilter {...contentTypeFilterProps} />
          </FiltersDropdown>
        </FilteringSection>
        <Styled.TableWrapper>
          {shouldShowResultsCount && <Styled.ResultsCountText>{totalCount} search results</Styled.ResultsCountText>}
          <SharedStyled.TableOverflowContainer>
            <DatapharmTable
              columns={submissionsColumns}
              documents={companySubmissionsWithDetails}
              expanded={expanded}
              onRowExpand={handleRowExpand}
              enableSorting={true}
              customSorting={customSorting}
              showLoader={(isFetchingSubmissions || isLoadingSubmissions) && offset === initialRecordOffset}
              data-testid="submissions-table"
            />
          </SharedStyled.TableOverflowContainer>
          {shouldShowLoadMoreButton && (
            <Styled.LoadMoreButtonWrapper>
              <Button
                color={theme.colors.mediumBlue}
                type={ButtonTypes.TEXT}
                onClick={increaseOffset}
                isLoading={isFetchingSubmissions || isLoadingSubmissions}
              >
                Load more
              </Button>
            </Styled.LoadMoreButtonWrapper>
          )}
        </Styled.TableWrapper>
      </Styled.SubmissionsWrap>
      <ConfirmSubmissionCancellation
        isOpen={!!submissionIdForCancel}
        isLoading={isCancellingSubmission}
        onCancel={handleConfirmCancellationCancel}
        onConfirm={handleConfirmCancellation}
      />
      <ConfirmSubmissionRemoval
        isOpen={!!submissionIdForRemoval}
        onCancel={handleRemovalCancellationCancel}
        isLoading={isRemovingSubmission}
        onConfirm={handleConfirmRemoval}
      />
      <RequestChangesModal
        isOpen={!!idForRequestChange}
        isLoading={isRequestingChanges}
        onCancel={handleRequestChangesCancel}
        onSubmit={handleRequestChangesSubmit}
        methods={requestChangesForm}
      />
      <CancelSubmissionTaskModal
        isOpen={!!idForCancelSubmission}
        isLoading={isCancellingTask}
        onCancel={handleCancelSubmissionTaskCancel}
        onSubmit={handleCancelSubmissionTaskSubmit}
        methods={cancelSubmissionTaskForm}
      />
      <RequestSubmittedModal isOpen={requestSubmittedOpen} onConfirm={handleRequestSubmittedConfirm} />
      <HtmlContentModal modalParams={htmlContentModalParams} onClose={() => closeHtmlContentModal()} />
    </>
  );
};

export default Submissions;
