import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import Tabs from '@common/components/Tabs/Tabs';
import { documentTypeToCustomLabelMap } from '@common/constants';
import { IDocumentDto } from '@common/features/submission/types';
import { useGetAlternativeTextFile } from '@common/hooks/useGetAlternativeTextFile';
import { DocumentType, DocumentStatus } from '@common/types';
import { HtmlDocument } from '@components/HtmlDocument/HtmlDocument';
import { ReadonlyDocumentContent } from '../../components/DocumentContentDetails/ReadonlyDocumentContent';
import { DocumentProcessingDetails } from '../../components/DocumentContentDetails/types';
import { DocumentVersionHistory } from '../../components/DocumentVersionHistory/DocumentVersionHistory';
import { getDocumentAttributes } from '../../helpers';
import * as Styled from './styles';
import { useFetchDocumentHtmlContent } from '@common/hooks/useFetchDocumentHtmlContent';

export interface IReadonlyDocumentDetailsContent {
  showHistoryFiltering: boolean;
  contentType: DocumentType;
  title: string;
  document: IDocumentDto;
  onCancel: () => void;
  onFileDownload?: () => void;
  onSecondaryFileDownload?: () => void;
  displayHtmlViewTab?: boolean;
  submissionId: string;
  isAdmin: boolean;
  secondaryOriginalFileName: string | undefined;
  documentProcessingDetails: DocumentProcessingDetails;
}

const ReadonlyDocumentDetailsContent = ({
  showHistoryFiltering,
  contentType,
  title,
  document,
  onCancel,
  onFileDownload,
  onSecondaryFileDownload,
  displayHtmlViewTab,
  submissionId,
  isAdmin,
  secondaryOriginalFileName,
  documentProcessingDetails,
}: IReadonlyDocumentDetailsContent) => {
  const documentTitle = document?.documentTitle;
  const fileName = document?.originalFileName ?? document?.link;
  const version = document?.version ?? 1;
  const approvalDate = document?.regulatorApprovalDate;
  const approved = !!document?.regulatorApprovalDate;
  const firstAuthorisationDate = document?.authorisedDate;
  const regulator = document?.approvedByRegulator;
  const reasonsForSubmission = document?.reasonsForSubmission;
  const documentState = document?.state;
  const hasDigitalVersion = document?.hasDigitalVersion ?? null;
  const url = document?.link;
  const details = document?.details ?? '';

  const attributes = getDocumentAttributes(contentType, isAdmin);

  const { data: htmlContent, documentFetching } = useFetchDocumentHtmlContent(
    document && attributes.hasHtmlContent
      ? {
          documentId: document?.documentId,
          version: document?.version,
          type: contentType,
          submissionId: document.status == DocumentStatus.Published ? null :  Number(submissionId),
        }
      : undefined,
  );

  const { alternativeTextFileName, openAlternativeTextFile } = useGetAlternativeTextFile(
    submissionId,
    document.documentId.toString(),
    attributes,
  );

  const reasonForRetirement =
    document?.reasonForRetirement?.length! > 0
      ? { label: document?.reasonForRetirement as string, value: document?.reasonForRetirement as string }
      : undefined;

  const getTabs = () => {
    const items = [
      {
        label: `${documentTypeToCustomLabelMap[contentType]} details`,
        content: (
          <ReadonlyDocumentContent
            documentTitle={documentTitle ?? ''}
            version={version ?? 1}
            fileName={fileName ?? ''}
            contentType={contentType}
            approvalDate={approvalDate ?? null}
            approved={approved}
            firstAuthorisationDate={firstAuthorisationDate ?? null}
            regulator={regulator ?? ''}
            reasonsForSubmission={reasonsForSubmission ?? []}
            reasonForRetirement={reasonForRetirement}
            url={url}
            documentState={documentState!}
            hasDigitalVersion={hasDigitalVersion}
            onFileClick={onFileDownload}
            contentDescription={document.contentDescription}
            isVisibleToHCP={document.isVisibleToHCP}
            htmlContent={htmlContent}
            alternativeTextFileName={alternativeTextFileName}
            onAlternativeTextFileNameClick={openAlternativeTextFile}
            isAdmin={isAdmin}
            secondaryFileName={secondaryOriginalFileName}
            onSecondaryFileClick={onSecondaryFileDownload}
            documentProcessingDetails={documentProcessingDetails}
            details={details}
          />
        ),
      },
      {
        label: `Version history`,
        content: (
          <DocumentVersionHistory
            documentId={document?.documentId ?? 0}
            documentType={document?.type}
            showHistoryFiltering={showHistoryFiltering}
          />
        ),
      },
    ];

    if (displayHtmlViewTab)
      items.push({
        label: `View html`,
        content: (
          <HtmlDocument documentId={document?.documentId.toString()} documentType={contentType} version={version.toString()} />
        ),
      });

    return items;
  };

  const renderContent = () => {
    switch (contentType) {
      case DocumentType.Smpc:
      case DocumentType.Pil:
      case DocumentType.Rmm:
      case DocumentType.Dhcp:
      case DocumentType.UserManual:
      case DocumentType.SafetyAlert:
      case DocumentType.ProductInformation:
      case DocumentType.AudioVideo:
      case DocumentType.Audio:
      case DocumentType.Video:
        return <Tabs items={getTabs()} />;
      default:
        return <>Work in progress</>;
    }
  };

  return (
    <>
      <Styled.ContentDetailsWrap>
        <SubmissionFlowHeader title={title} onCloseClick={onCancel} onBackClick={onCancel} />
        {!documentFetching && renderContent()}
      </Styled.ContentDetailsWrap>
      <SubmissionFlowFooter onCancel={onCancel} cancelText="Back" />
    </>
  );
};

export default ReadonlyDocumentDetailsContent;
