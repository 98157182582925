import { selectActiveCompany } from '@common/features/user/selectors';
import useWaitingMutation from '@hooks/useWaitingMutation';
import { postDraftFromProductGroup } from '@common/services/submissionService';
import { useAppSelector } from '@common/hooks/redux';

export interface IDraftFromProductGroup {
  type: string;
  familyName: string;
  familyId: string;
  id: string;
  name: string;
}

export const useCreateDraftFromProductGroup = (onSuccess: (submissionId: number) => void) => {
  const activeCompany = useAppSelector(selectActiveCompany);
  const companyId = activeCompany?.id || '';

  const prepareData = (data: IDraftFromProductGroup) => {
    return {
      productType: data.type,
      productFamilyName: data.familyName,
      productFamilyId: data.familyId,
      productGroupName: data.name,
      productGroupId: data.id,
      companyId: companyId,
    };
  };
  const { mutate } = useWaitingMutation(
    (data: IDraftFromProductGroup) => {
      return postDraftFromProductGroup(prepareData(data));
    },
    { onSuccess: (data) => onSuccess(data.submissionId) },
  );

  return { mutate };
};
