import { useQuery } from '@tanstack/react-query';
import { downloadDocumentFile, downloadDocumentFileWithDocumentType } from '@common/services/submissionService';
import { Id } from '@common/types';
import { DocumentType } from '@common/types';

export const useDownloadDocumentFile = (submissionId: Id, documentId: Id) => {
  const { data, refetch } = useQuery(
    ['downloadDocumentFile', submissionId, documentId],
    () => downloadDocumentFile(submissionId, documentId),
    { enabled: false },
  );

  return { documentFileResponse: data, refetchDownloadDocumentFile: refetch };
};

export const useDownloadSecondaryFile = (submissionId: Id, documentId: Id, contentType: DocumentType, lastUpdated: string) => {
  const { data, refetch } = useQuery(
    ['downloadDocumentFileWithDocumentType', submissionId, documentId, contentType, lastUpdated],
    () => {
      return downloadDocumentFileWithDocumentType(submissionId, documentId, contentType);
    },
    { enabled: false },
  );

  return { secondaryDocumentFileResponse: data, secondaryRefetchDownloadDocumentFile: refetch };
};
