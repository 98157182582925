import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { requestEpil } from '@common/services/documentService';

export const useRequestEpil = () => {
  const { mutate } = useWaitingMutation(({ documentId, submissionId, fileName, originalFileName, alternativeTextFileName }) =>
    requestEpil(submissionId, documentId, { fileName, originalFileName, alternativeTextFileName }),
  );

  return { requestEpil: mutate };
};
