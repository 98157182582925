import { SubmissionStatus, IProductSubmissionStatusDto } from '@services/types';
import { fetchProductSubmissionStatuses } from '@common/services/productService';
import { SubmissionStatus as SubmissionStatusEnum } from '@common/types';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { useState } from 'react';

interface IProductUpdateInterceptionCallbacks {
  draftSubmissionCallback: (submissionId: number) => void;
  noActiveSubmissionCallback: () => void;
}

interface IProductUpdateInterceptionMutationData {
  callbacks?: IProductUpdateInterceptionCallbacks;
  productGroupId: number;
}

export const useProductUpdateInterception = () => {
  const [showSubmissionLimitModal, setShowSubmissionLimitModal] = useState(false);

  const { mutate } = useWaitingMutation<IProductSubmissionStatusDto[]>((productGroupId: number) =>
    fetchProductSubmissionStatuses(productGroupId, SubmissionStatusEnum.Active),
  );

  const closeSubmissionLimitModal = () => setShowSubmissionLimitModal(false);

  const handleUpdateWithActiveSubmissions = (mutationData: IProductUpdateInterceptionMutationData) => {
    mutate(mutationData.productGroupId, {
      onSuccess: (data) => {
        const draftSubmission = getDraftSubmission(data);
        const inProgressSubmission = getInProgressSubmission(data);

        if (inProgressSubmission) {
          setShowSubmissionLimitModal(true);
          return;
        }

        if (draftSubmission) {
          mutationData.callbacks!.draftSubmissionCallback(draftSubmission.submissionId);
          return;
        }

        mutationData.callbacks!.noActiveSubmissionCallback();
      },
    });
  };

  const getInProgressSubmission = (data: IProductSubmissionStatusDto[]) => {
    return data?.find((x) => x.status === SubmissionStatus.InProgress);
  };

  const getDraftSubmission = (data: IProductSubmissionStatusDto[]) => {
    return data?.find((x) => x.status === SubmissionStatus.Draft);
  };

  return { showSubmissionLimitModal, closeSubmissionLimitModal, handleUpdateWithActiveSubmissions };
};
