import { IDocumentDto, ISubmissionDto } from '@common/features/submission/types';
import { DocumentType, ICheckboxEntry } from '@common/types';
import { DocumentDetailsReadonlySections, DocumentProcessingDetails } from '../../components/DocumentContentDetails/types';
import { IRegulatorApprovalOptions } from '../../components/RegulatorApproval/types';

export interface IRetiredContentDetails {
  showHistoryFiltering: boolean;
  document: IDocumentDto;
  contentType: DocumentType;
  contentTitle: string;
  submissionId: String;
  documentId: string;
  url: string;
  isAdmin: boolean
  originalFileName: string;
  documentProcessingDetails: DocumentProcessingDetails;
  onCancel: () => void;
  onSuccess: () => void;
  displayHtmlViewTab?: boolean;
}

export interface IContentDetailsParent {
  submission: ISubmissionDto;
  documentId: string;
  submissionId: string;
  contentType: DocumentType;
  documentDetailsReadonlySections?: DocumentDetailsReadonlySections;
  isEditable?: boolean;
  isFormExtended: boolean;
  isAdmin: boolean;
  documentProcessingDetails: DocumentProcessingDetails;
  onCancel: () => void;
  onSuccess: () => void;
}

export interface IReadonlyContentDetails {
  showHistoryFiltering: boolean;
  contentType: DocumentType;
  productGroupId: string;
  documentId: string;
  document?: IDocumentDto;
  isAdmin?: boolean;
  onCancel: () => void;
  onFileDownload?: () => void;
}

export interface IContentDetailsForm {
  approvedByRegulator: IRegulatorApprovalOptions;
  regulatorApprovalDate: Date | null;
  authorisedDate: Date | null;
  reasonsForSubmission: ICheckboxEntry[];
  details: string | null;
  isRegulatorApproved: boolean;
  documentTitle?: string;
  hasDigitalVersion?: boolean | null;
  documentName: string;
  hasAlternativeTextFile?: boolean;
  alternativeTextFile?: File;
  alternativeTextFileName?: string;
  isCustomerApprovalRequired: boolean | null;
  isVisibleToHCP?: boolean | null;
  contentDescription: string;
  submissionReason: string;
  webLink?: string | null;
  customerUrl?: string | null;
  htmlContent?: string;
  replacementSecondaryFileName? :  string | null;
  replacementSecondaryFile?: File | null;
}

export enum ContentDetailsFormField {
  approvedByRegulator = 'approvedByRegulator',
  regulatorApprovalDate = 'regulatorApprovalDate',
  authorisedDate = 'authorisedDate',
  reasonsForSubmission = 'reasonsForSubmission',
  details = 'details',
  isRegulatorApproved = 'isRegulatorApproved',
  documentTitle = 'documentTitle',
  hasDigitalVersion = 'hasDigitalVersion',
  hasAlternativeTextFile = 'hasAlternativeTextFile',
  alternativeTextFile = 'alternativeTextFile',
  alternativeTextFileName = 'alternativeTextFileName',
  isCustomerApprovalRequired = 'isCustomerApprovalRequired',
  isVisibleToHCP = 'isVisibleToHCP',
  contentDescription = 'contentDescription',
  webLink = 'webLink',
  customerUrl = 'customerUrl',
  htmlContent = 'htmlContent',
  replacementSecondaryFile = 'replacementSecondaryFile',
  replacementSecondaryFileName = 'replacementSecondaryFileName',
}

export enum ContentDetailsFormFieldErrors {
  Details = 'Max. length: 6000 characters',
  HtmlContent = 'Max. length: 5000 characters',
}
