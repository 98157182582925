import { NotifyModal } from '../../../components/NotifyModal';
import { selectThirdPartyLink } from '@common/features/global/selectors';
import { useDispatch } from 'react-redux';
import { setThirdPartyLink } from '../globalSlice';
import { useAppSelector } from '@common/hooks/redux';

export const ThirdPartyLinkWarning = () => {
  const thirdPartyLink = useAppSelector(selectThirdPartyLink);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setThirdPartyLink(undefined));
  };

  const handleConfirm = () => {
    dispatch(setThirdPartyLink(undefined));
    window.open(thirdPartyLink, '_blank');
  };

  return (
    <NotifyModal isOpen={!!thirdPartyLink} title="Warning" onConfirm={handleConfirm} onClose={handleClose} width="30rem">
      You are about to leave for a third party website.
      <br />
      Are you sure you want to do that?
    </NotifyModal>
  );
};
