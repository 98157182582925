import { useQuery } from '@tanstack/react-query';
import { Id } from '@common/types';
import { downloadNoteFile } from '@common/services/submissionService';

export const useDownloadNoteFile = (taskId: Id, noteId: number) => {
  const { data, refetch } = useQuery(['downloadNoteFile', taskId, noteId], () => downloadNoteFile(taskId, noteId), {
    enabled: false,
  });

  return { noteFile: data, refetchDownloadNoteFile: refetch };
};
