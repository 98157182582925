import { buildUrl, handleRequest } from '@common/services/requestHelper';
import { RequestType, SubmissionStatus, SubmissionTaskStatuses } from '@common/services/types';
import { TaskTypes } from '@common/types';

const basePath = process.env.REACT_APP_API_PATH;

const requestChanges = async (taskId: number, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/request-changes`, basePath);
  return handleRequest({ url, requestType: RequestType.POST, data: dataSet });
};

const fetchTasks = async (
  type?: TaskTypes,
  taskStatus?: SubmissionTaskStatuses,
  submissionStatus?: SubmissionStatus,
  submissionId?: string,
  companyId?: number,
) => {
  let queryString = [];

  companyId && queryString.push(`companyId=${companyId}`);
  submissionId && queryString.push(`submissionId=${submissionId}`);
  type && queryString.push(`types=${type}`);
  taskStatus && queryString.push(`statuses=${taskStatus}`);
  submissionStatus && queryString.push(`submissionStatus=${submissionStatus}`);

  const url = buildUrl(`/submission-task` + (queryString.length > 0 ? `?${queryString.join('&')}` : ''), basePath);

  return handleRequest({ url, requestType: RequestType.GET });
};

const updateSubmissionTaskFastTrack = async (taskId: number, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/fast-track`, basePath);

  return handleRequest({ url, requestType: RequestType.PUT, data: dataSet });
};

const updateSubmissionTaskAssignment = async (taskId: number, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/user-assignment`, basePath);

  return handleRequest({ url, requestType: RequestType.PUT, data: dataSet });
};

export { fetchTasks, requestChanges, updateSubmissionTaskFastTrack, updateSubmissionTaskAssignment };
