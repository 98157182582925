import { selectDocumentConversionResults } from '@features/user/selectors';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchDocumentConversionResults } from '@common/services/documentService';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addDocumentConversionResult, updateDocumentConversionResults } from '@common/features/user/userSlice';
import { IDocumentConversionResult } from '@common/features/user/types';
import { DocumentType } from '@common/types';
import { queryKey as fetchDocumentConversionResultsQueryKey } from '@common/hooks/useFetchDocumentConversionResults';
import { DocumentProcessingFlow } from '@common/features/document/components/DocumentContentDetails/types';
import { useAppSelector } from './redux';

export const queryKey = 'fetchDocumentConversionResults';

const refetchInterval = 3 * 1000;
const timeInterval = 30;
export const useFetchDocumentConversionResults = (enabled: boolean = true) => {
  const dispatch = useDispatch();

  const documentConversionResults = useAppSelector(selectDocumentConversionResults);

  // Get current UTC date and add 30 minutes so that the delay caused by the request is taken into account
  const toUtcDate = new Date(new Date().toISOString());
  toUtcDate.setMinutes(toUtcDate.getMinutes() + timeInterval);

  // Get current UTC date and substract 30 minutes to create a time window for getting ongoing background processes
  const fromUtcDate = new Date(new Date().toISOString());
  fromUtcDate.setMinutes(fromUtcDate.getMinutes() - timeInterval);

  const { data, isFetching, isSuccess } = useQuery<IDocumentConversionResult[]>(
    [queryKey],
    () => fetchDocumentConversionResults(fromUtcDate.toISOString(), toUtcDate.toISOString()),
    {
      enabled: enabled,
      refetchInterval: (data) => (data?.some((dto) => !dto.isFinished) ? refetchInterval : false),
    },
  );

  useEffect(() => {
    if (!isFetching && isSuccess) {
      const diff = getIsFinishedDiff(documentConversionResults, data);
      if (diff.length > 0) {
        diff.forEach((d) => {
          if (d.isFinished && d.isFileValid) toast.success(`Conversion succeeded for document ${d.documentId}`);
          if (d.isFinished && !d.isFileValid) toast.error(`Conversion failed for document ${d.documentId}`);
        });
      }
      dispatch(updateDocumentConversionResults(data));
    }
  }, [data, dispatch, documentConversionResults, isFetching, isSuccess]);

  return { documentConversionResults: data, isFetching };
};

const getIsFinishedDiff = (initial: IDocumentConversionResult[], updated: IDocumentConversionResult[]) => {
  return updated.filter((initialObj) => {
    const updatedObj = initial.find((updatedObj) => updatedObj.taskId === initialObj.taskId);
    return updatedObj && initialObj.isFinished !== updatedObj.isFinished;
  });
};

export const useHandleInitialConversionResultForDocument = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const addInitialConversionResultForDocument = (
    documentId: number,
    contentType: DocumentType,
    documentProcessingFlow: DocumentProcessingFlow,
  ) => {
    // If document with conversion, add initial state for document conversion results and invalidate request for conversion results
    if (
      contentType === DocumentType.Smpc ||
      (contentType === DocumentType.Pil && documentProcessingFlow === DocumentProcessingFlow.qrdPil)
    ) {
      dispatch(
        addDocumentConversionResult({
          documentId,
          taskId: -1,
          isFinished: false,
          isFileValid: false,
        } as IDocumentConversionResult),
      );
      queryClient.invalidateQueries([fetchDocumentConversionResultsQueryKey]);
    }
  };

  return { addInitialConversionResultForDocument };
};
