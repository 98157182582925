import { fetchCompanyConfiguration } from '@common/services/adminService';
import { useQuery } from '@tanstack/react-query';
import { ICompanyConfigurationDto } from './types';

export const useFetchCompanyConfiguration = (companyId: number) => {
  const { data, isFetching } = useQuery<ICompanyConfigurationDto>(
    ['fetchCompanyConfiguration', companyId],
    () => fetchCompanyConfiguration(companyId),
    {
      enabled: !!companyId,
      staleTime: 10 * 60 * 1000,
    },
  );

  return { companyConfiguration: data, isFetchingCompanyConfiguration: isFetching };
};
