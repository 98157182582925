import React, { useEffect } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { SubmissionTypePicker } from '../../containers/SubmissionTypePicker/SubmissionTypePicker';
import { useAppDispatch, useAppSelector } from '@common/hooks/redux';
import {
  selectProductGroupPlan,
  selectReviewPopupState,
  selectWarningPopupState,
} from 'apps/publisher-v3.ui.customer/src/features/submission/selectors';
import { CreateProduct } from './CreateProduct';
import ProductGroupReviewPopup from '../../components/Submissions/ProductGroupReviewPopup';
import ProductGroupAlreadyExistsPopup from '../../components/Submissions/ProductGroupAlreadyExistsPopup';
import * as AsyncActions from 'apps/publisher-v3.ui.customer/src/features/submission/thunks';
import * as Actions from 'apps/publisher-v3.ui.customer/src/features/submission/submissionSlice';
import { usePrepareForm } from './hooks/usePrepareForm';
import { FormErrors, FormFields } from './types';
import { SubmissionTypes } from '@common/features/submission/types';
import * as Styled from './styles';
import * as SharedStyled from '@common/styles';
import UpdateProduct from './UpdateProduct/UpdateProduct';
import { useCreateDraftFromProductGroup } from './hooks/useCreateDraftFromProductGroup';
import useAppNavigation from '../../routing/useAppNavigation';
import UnknownProductGroupPopup from '../../components/Submissions/UnknownProductGroupPopup';
import { useUnknownProductGroup } from './hooks/useUnknownProductGroup/useUnknownProductGroup';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { useProductUpdateInterception } from '../../features/productGroup/hooks/useProductUpdateInterception';
import { NotifyModal } from '@common/components/NotifyModal';

export const CreateSubmission = () => {
  const dispatch = useAppDispatch();
  const { goToProductGroup, goToPublishedProductGroup } = useAppNavigation();
  const isReviewPopupOpen = useAppSelector(selectReviewPopupState);
  const isDuplicateWarningOpen = useAppSelector(selectWarningPopupState);
  const productGroupPlan = useAppSelector(selectProductGroupPlan);

  const { mutate } = useCreateDraftFromProductGroup((submissionId: number) => goToProductGroup(submissionId.toString()));

  const { showSubmissionLimitModal, closeSubmissionLimitModal, handleUpdateWithActiveSubmissions } =
    useProductUpdateInterception();

  const {
    methods,
    watch,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    clearErrors,
  } = usePrepareForm();

  useEffect(() => {
    return () => {
      dispatch(Actions.clearDraftSubmissionState());
    };
  }, [dispatch]);

  var { isUnknownGroupStatusOpen, handleShowUnknownGroup, handleCloseUnknownGroup, isProductGroupUnknown } =
    useUnknownProductGroup();

  const handleReviewClose = () => {
    dispatch(Actions.closeReviewDialog());
  };

  const handleReviewContinue = () => {
    dispatch(AsyncActions.createSubmissionDraft({ ...getValues() })).then((action: any) => {
      goToProductGroup(action.payload.submissionId);
    });
  };

  const handleDuplicateWarningClose = () => {
    dispatch(Actions.closeDuplicateWarning());
  };

  const handleViewGroup = () => {
    if (productGroupPlan?.existingProductGroupId) {
      goToPublishedProductGroup(productGroupPlan.existingProductGroupId.toString());
    }

    if (productGroupPlan?.existingSubmissionId) {
      goToProductGroup(productGroupPlan.existingSubmissionId.toString());
    }

    dispatch(Actions.closeDuplicateWarning());
  };
  const handleContinue = () => {
    switch (watch(FormFields.submissionType)) {
      case SubmissionTypes.CREATE_PRODUCT:
        clearErrors([FormFields.activeIngredients]);
        handleSubmit((data) => dispatch(AsyncActions.generateProductGroupPlan({ ...data })))();
        break;
      case SubmissionTypes.UPDATE_PRODUCT:
        const selectedProductGroup = getValues(FormFields.selectedProductGroup);
        const productGroupId = parseInt(selectedProductGroup!.id);
        const callbacks = {
          draftSubmissionCallback: goToProductGroup,
          noActiveSubmissionCallback: () => {
            if (isProductGroupUnknown(selectedProductGroup.familyId)) handleShowUnknownGroup();
            else mutate(selectedProductGroup);
          },
        };

        handleUpdateWithActiveSubmissions({ productGroupId, callbacks });
        break;
    }
  };

  const renderContent = () => {
    switch (watch(FormFields.submissionType)) {
      case SubmissionTypes.CREATE_PRODUCT:
        return <CreateProduct />;
      case SubmissionTypes.UPDATE_PRODUCT:
        return <UpdateProduct showUnknownProductGroup={handleShowUnknownGroup} isProductGroupUnknown={isProductGroupUnknown} />;
    }
  };

  return (
    <FormProvider {...methods}>
      <SharedStyled.SubmissionFlowWrap>
        <SharedStyled.SubmissionFlowInner>
          <Styled.CreateSubmissionHeadingWrap>
            <Styled.CreateSubmissionTextHeadingText>Create a submission</Styled.CreateSubmissionTextHeadingText>
            <Styled.CreateSubmissionTextHeadingSubtext>
              Use this step by step process to manage your portfolio of products
            </Styled.CreateSubmissionTextHeadingSubtext>
          </Styled.CreateSubmissionHeadingWrap>
          <Styled.CreateSubmissionCompactWrap>
            <Controller
              control={control}
              name={FormFields.submissionType}
              rules={{ required: FormErrors.submissionType }}
              render={({ field: { value, onChange } }) => (
                <SubmissionTypePicker value={value} onChange={onChange} error={errors[FormFields.submissionType]?.message} />
              )}
            />
          </Styled.CreateSubmissionCompactWrap>
          {renderContent()}
          <UnknownProductGroupPopup isOpen={isUnknownGroupStatusOpen} handleClose={handleCloseUnknownGroup} />
          {productGroupPlan && (
            <ProductGroupReviewPopup
              isOpen={isReviewPopupOpen}
              draftState={productGroupPlan}
              handleClose={handleReviewClose}
              handleContinue={handleReviewContinue}
            />
          )}
          <ProductGroupAlreadyExistsPopup
            isOpen={isDuplicateWarningOpen}
            handleClose={handleDuplicateWarningClose}
            handleViewGroup={handleViewGroup}
          />
        </SharedStyled.SubmissionFlowInner>
      </SharedStyled.SubmissionFlowWrap>
      <SubmissionFlowFooter onContinue={handleContinue} />
      <NotifyModal cancelText="Close" isOpen={showSubmissionLimitModal} onClose={closeSubmissionLimitModal} width="32rem">
        <p>
          <b>
            An in progress submission already exists for this product group - You can request changes to this submission via the
            submissions screen.
          </b>
        </p>
      </NotifyModal>
    </FormProvider>
  );
};

export default CreateSubmission;
