import React from 'react';
import { DashboardWrapper, Content } from './styles';
import NewsWidget from '@common/pages/Home/widgets/NewsWidget/NewsWidget';
import WelcomeWidget from '@common/pages/Home/widgets/WelcomeWidget/WelcomeWidget';
import ArticleWidget from '@common/pages/Home/widgets/ArticleWidget/ArticleWidget';
import TutorialsWidget from '@common/pages/Home/widgets/TutorialsWidget/TutorialsWidget';
import SlaWidget from '@common/pages/Home/widgets/SlaWidget/SlaWidget';
import { Widgets } from '@common/pages/Home/types';
import useFetchDashboard, { DashboardDto } from '@common/pages/Home/hooks/useFetchDashboard';
import { PleaseWaitLoader } from '@common/components/PleaseWaitLoader';
import { WidgetsDisplay } from '@common/pages/Home/WidgetsDragAndDrop/WidgetsDragAndDrop';

const Dashboard = () => {
  const { publishedDashboard, isFetching } = useFetchDashboard();

  const dashboardData = publishedDashboard?.widgets;

  return (
    <DashboardWrapper>
      {isFetching || !dashboardData ? (
        <PleaseWaitLoader backgroundColor="white" />
      ) : (
        <Content>
          <WidgetsDisplay dashboardData={dashboardData} items={prepareWidgets(publishedDashboard)} disabled />
        </Content>
      )}
    </DashboardWrapper>
  );
};

const getDataForWidget = (widgetType: string, publishedDashboard: DashboardDto) => {
  return publishedDashboard?.widgets?.find((widget: any) => widget.widgetType === widgetType)!;
};

const prepareWidgets = (publishedDashboard: DashboardDto) => ({
  [Widgets.WelcomeWidget]: <WelcomeWidget {...getDataForWidget(Widgets.WelcomeWidget, publishedDashboard)} />,
  [Widgets.NewsWidget]: <NewsWidget {...getDataForWidget(Widgets.NewsWidget, publishedDashboard)} />,
  [Widgets.ArticleWidget]: <ArticleWidget {...getDataForWidget(Widgets.ArticleWidget, publishedDashboard)} />,
  [Widgets.TutorialsWidget]: <TutorialsWidget {...getDataForWidget(Widgets.TutorialsWidget, publishedDashboard)} />,
  [Widgets.SlaWidget]: <SlaWidget {...getDataForWidget(Widgets.SlaWidget, publishedDashboard)} />,
});

export default Dashboard;
