import styled from 'styled-components';

export const SectionWrap = styled.div`
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  border: 1px solid ${({ theme }) => theme.elements.primaryLight};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 20px;
  height: 100%;
`;

export const WidgetForm = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  overflow-x: auto;
  padding: 10px;
`;

export const TopControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
