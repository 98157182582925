import { useQuery } from '@tanstack/react-query';
import { fetchProductHistory } from '@common/services/productService';
import { DocumentType } from '@common/types';

export const fetchProductHistoryQueryKey = 'fetchProductHistory';

export interface IProductHistoryDto {
  description: string;
  details: IHistoryDetail[] | null;
  performedBy: string;
  date: Date;
  smpcVersion: number | null;
  pilVersion: number | null;
  maNumbers: string[];
  legalCategories: string[];
  atcCodes: string[] | null;
  hasBlackTriangle: boolean;
}

export interface IHistoryDetail {
  text: string;
  webLink: string | null;
  isFileLink: boolean;
  isWebLink: boolean;
  isHtml: boolean;
  taskId: string | null;
  submissionId?: string | null;
  documentId: string | null;
  documentVersion?: string | null;
  documentType?: DocumentType | null;
}

interface IProductHistoryResult {
  items: IProductHistoryDto[];
  isLoading: boolean;
}

export const useFetchProductHistory = (id: string): IProductHistoryResult => {
  const { data, isFetching } = useQuery<IProductHistoryDto[]>([fetchProductHistoryQueryKey], () => fetchProductHistory(id));

  return {
    items: data ?? [],
    isLoading: isFetching ?? true,
  };
};
