import React, { useCallback, useMemo } from 'react';
import {
  BadgeElementWrapper,
  ContentHeaderSummary,
  ContentHeaderWrapper,
  Description,
  Subtitle,
  TableWrapper,
} from '@common/features/productGroup/styles';
import { useParams } from 'react-router';
import NoContentPanel from '@components/NoContentPanel/NoContentPanel';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { DocumentStatus, DocumentType } from '@common/types';
import Badge from '@common/components/Badge/Badge';
import { Button } from '@common/components/Button/Button';
import { ButtonTypes } from '@common/components/Button/types';
import { selectProductGroup } from '@common/features/productGroup/slices/productGroup/selectors';
import { prepareColumns, prepareDropdownButtons } from './helpers';
import { getDocumentTypeLabel } from '@common/helpers';
import useAppNavigation, { BackToContentTypeState } from 'apps/publisher-v3.ui.customer/src/routing/useAppNavigation';
import { IDocumentWithTask } from '@common/features/submission/types';
import { DropDownButton } from './DropDownButton';
import { getDocumentAttributes } from '@common/features/document/helpers';
import { AddModalOpenModes } from '@common/features/document/types';
import { useAppSelector } from '@common/hooks/redux';

interface ISubTabContent {
  contentType: DocumentType;
  documents: IDocumentWithTask[];
  onContentAdd: (productGroupName: string, documentId?: number, mode?: AddModalOpenModes) => void;
  onContentRemove: (documentId: string, title: string) => void;
  onContentRetire: (documentId: string) => void;
  onContentDetailsClick: (documentId: string) => void;
  onRequestEpil: (documentId: string) => void;
  onContentTypeSelect: (doc: DocumentType) => void;
  showAudioVideoUpdateWarning?: () => void;
  onViewErrorsClick?: (taskId: number) => void;
}

const DocumentsTabContent = ({
  contentType,
  documents,
  onContentAdd,
  onContentRetire,
  onContentDetailsClick,
  onContentRemove,
  onRequestEpil,
  onContentTypeSelect,
  showAudioVideoUpdateWarning,
  onViewErrorsClick,
}: ISubTabContent) => {
  const productGroup = useAppSelector(selectProductGroup);
  const { goToUpdateDocumentContentDetails } = useAppNavigation();
  const { submissionId = '' } = useParams();

  const handleUpdateDocument = useCallback(
    (document: IDocumentWithTask) => {
      onContentTypeSelect(document.type);
      if (document.type === DocumentType.AudioVideo) {
        return showAudioVideoUpdateWarning && showAudioVideoUpdateWarning();
      }

      if (document.status === DocumentStatus.Published || document.status === DocumentStatus.Retired) {
        onContentTypeSelect(document.type);
        onContentAdd(productGroup?.name, document.documentId, 'Update');
      } else {
        const backToContentState: BackToContentTypeState = { contentType: document.type };

        goToUpdateDocumentContentDetails(submissionId, document.type, document.documentId.toString(), backToContentState);
      }
    },
    [
      goToUpdateDocumentContentDetails,
      onContentAdd,
      onContentTypeSelect,
      productGroup?.name,
      submissionId,
      showAudioVideoUpdateWarning,
    ],
  );

  const detailsCallback = useCallback((documentId: string) => onContentDetailsClick(documentId), [onContentDetailsClick]);
  const removeCallback = useCallback(
    (documentId: string, title: string) => onContentRemove(documentId, title),
    [onContentRemove],
  );

  const retireCallback = useCallback(
    (documentId: string) => {
      onContentRetire(documentId);
    },
    [onContentRetire],
  );

  const requestCallback = useCallback(
    (documentId: string) => {
      onRequestEpil(documentId);
    },
    [onRequestEpil],
  );

  const tableCallbacks = useMemo(
    () => ({
      onUpdateClick: handleUpdateDocument,
      onDetailsClick: detailsCallback,
      onRemoveClick: removeCallback,
      onRetireClick: retireCallback,
      onRequestEpilClick: requestCallback,
    }),
    [detailsCallback, handleUpdateDocument, removeCallback, requestCallback, retireCallback],
  );

  const openContentDetailsPopup = () => onContentAdd(productGroup?.name);

  const documentAttributes = getDocumentAttributes(contentType, false);
  const columns = useMemo(
    () => prepareColumns(true, tableCallbacks, documentAttributes, false, onViewErrorsClick),
    [documentAttributes, onViewErrorsClick, tableCallbacks],
  );

  const contentTypeLabel = getDocumentTypeLabel(contentType);

  const handleItemClick = (dt: DocumentType) => {
    onContentTypeSelect(dt);
    openContentDetailsPopup();
  };

  return (
    <>
      <ContentHeaderWrapper>
        <ContentHeaderSummary>
          <BadgeElementWrapper>
            <Subtitle data-testid="content-tab-title">{contentTypeLabel}(s)</Subtitle>
            <Badge value={documents?.length} data-testid={`${contentType}-counter`} />
          </BadgeElementWrapper>
          <Description>
            These are all the {contentTypeLabel}s that are part of this product group, a {contentTypeLabel} can be associated to
            more than one product/presentation in the product group.
          </Description>
        </ContentHeaderSummary>
        {documentAttributes.showAudioVideoDropdown ? (
          <DropDownButton buttonText={'Add Media'} dropDownButtons={prepareDropdownButtons(handleItemClick)} />
        ) : (
          <Button
            onClick={openContentDetailsPopup}
            text={`Add ${contentTypeLabel}`}
            type={ButtonTypes.PRIMARY_MEDIUM_BORDER}
            icon="/icons/plus-sign.svg"
            minWidth={150}
            data-testid={`add-${contentType}-button`}
          />
        )}
      </ContentHeaderWrapper>
      <TableWrapper data-testid={`${contentType}-table`}>
        {!documents || documents.length === 0 ? (
          <NoContentPanel
            title="No content found"
            helpMessage="How do I add content?"
            helpLink={process.env.REACT_APP_HELP_CONTENT_URL}
          />
        ) : (
          <DatapharmTable documents={documents ?? []} columns={columns} data-testid="documents-table" />
        )}
      </TableWrapper>
    </>
  );
};

export default DocumentsTabContent;
