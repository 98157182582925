import { StyledLink } from '@common/components/Link/styles';
import { useThirdPartyLinkWarning } from '@common/features/global/ThirdPartyLinkWarning/useThirdPartyLinkWarning';
import { IDocumentContentInfo } from '@common/hooks/useFetchDocumentHtmlContent';

interface Props {
  id?: number;
  text: string;
  isHtml: boolean | null;
  documentData?: IDocumentContentInfo;
  link: string | null;
  disabled: boolean;
  onViewContent?: (documentData: IDocumentContentInfo) => void;
  onFileOpen?: (id: string) => void;
}

export const ViewContentLink = ({
  id,
  isHtml,
  documentData,
  link,
  text,
  disabled,
  onViewContent,
  onFileOpen,
}: Props): JSX.Element => {
  const { handleUrlClick } = useThirdPartyLinkWarning();

  const handleClick = (e: any) => {
    e.preventDefault();
    !!id && onFileOpen!(id!.toString());
  };

  const url = link ?? '';

  if (!text || text === '') {
    text = '-';
  }

  if (disabled) {
    return <b>{text}</b>;
  }

  if (isHtml) {
    return (
      <StyledLink onClick={onViewContent ? () => onViewContent(documentData!) : () => {}} to="#">
        {text}
      </StyledLink>
    );
  }

  if (!!link && link !== '') {
    return (
      <StyledLink target="_blank" to={url} onClick={(e) => handleUrlClick(e, url)}>
        {text}
      </StyledLink>
    );
  }

  return (
    <StyledLink onClick={handleClick} to="#">
      {text}
    </StyledLink>
  );
};
