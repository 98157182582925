import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { updateProduct } from '@common/services/submissionService';
import { IProductMetadata } from '../types';
import { Id } from '@common/types';

export const queryKey = 'patchProductDetails';

export const useUpdateProductDetails = (submissionId: Id, productId: Id) => {
  const { mutate, isLoading } = useWaitingMutation((productMetadata: IProductMetadata) =>
    updateProduct(submissionId, productId, { ...productMetadata }),
  );

  return { updateProductDetails: mutate, isUpdatingProductDetails: isLoading };
};
