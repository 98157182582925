import { useEffect, useState } from 'react';
import { useAppDispatch } from '../hooks/redux';
import { fetchConfiguration } from '../features/configuration/thunk';
import { fetchIdentityUserData, fetchUserData } from '@common/features/user/thunks';
import { AuthContextProps, AuthProviderProps, hasAuthParams, useAuth } from 'react-oidc-context';
import { WebStorageStateStore, User } from 'oidc-client-ts';
import { sendDataLayerCustomEvent } from '@common/services/dataLayerService';

const oidcConfig: AuthProviderProps = {
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID!,
  authority: process.env.REACT_APP_AUTH_URL!,
  redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/`,
  post_logout_redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/`,
  scope: process.env.REACT_APP_AUTH_SCOPES!,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: () => {
    sendDataLayerCustomEvent('successful_login');
    redirectToIntendedUrl();
  },
  automaticSilentRenew: true,
};

const useOidcAuthentication = () => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  const dispatch = useAppDispatch();
  useLogoutOnAllTabs(auth);

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      saveIntendedUrlToStorage();
      auth.signinRedirect();
      setHasTriedSignin(true);
    }

    if (auth.isAuthenticated && !hasTriedSignin) {
      dispatch(fetchUserData({}));
      dispatch(fetchIdentityUserData({}));
      dispatch(fetchConfiguration({}));
    }

    auth.events.addSilentRenewError(() => {
      logoutUser(auth);
    });
  }, [auth, dispatch, hasTriedSignin]);

  return {
    isAuthenticated: auth.isAuthenticated,
    logoutUser: () => {
      logoutUser(auth);
    },
  };
};

const saveIntendedUrlToStorage = () => {
  const currentUrl = window.location.pathname + window.location.search;
  sessionStorage.setItem('redirectUrl', currentUrl);
};

const redirectToIntendedUrl = () => {
  const redirectUrl = sessionStorage.getItem('redirectUrl') || '/';
  sessionStorage.removeItem('redirectUrl');
  window.location.href = redirectUrl;
};

const getUser = () => {
  const oidcStorage = localStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_OIDC_CLIENT_ID}`);

  if (oidcStorage === null) return null;

  return User.fromStorageString(oidcStorage);
};

const useLogoutUser = () => {
  const auth = useAuth();

  return { logoutUser: () => logoutUser(auth) };
};

const logoutUser = (auth: AuthContextProps) => {
  localStorage.setItem('logout', Date.now().toString());
  auth.signoutRedirect();
};

const useLogoutOnAllTabs = (auth: AuthContextProps) => {
  useEffect(() => {
    const syncLogout = (event: any) => {
      if (event.key === 'logout') {
        auth.signoutRedirect();
      }
    };

    window.addEventListener('storage', syncLogout);

    return () => {
      window.removeEventListener('storage', syncLogout);
    };
  }, [auth]);
};

export { useOidcAuthentication, oidcConfig, getUser, useLogoutUser };
