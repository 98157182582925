import { ISelectOption, DocumentType } from '@common/types';
import { IMaNumber, IProductDenominator, IProductNumerator, IActiveIngredientsDto } from '@common/features/submission/types';

export interface IProduct {
  title: string | undefined;
  genericName: string | undefined;
  productType: string | undefined;
  productForm: string;
  extraInformation: string;
  submissionId?: string;
  productMetadata: {
    maNumbers: IMaNumber[];
    activeIngredientsMetadata: IActiveIngredientsMetadata;
    legalCategories: string[];
    hasBlackTriangle: ISelectOption | null;
    isGlutenFree: ISelectOption | null;
    isLactoseFree: ISelectOption | null;
    isSuitableForVegans: ISelectOption | null;
    isPregnancyRisk: ISelectOption | null;
  };
}

export interface IAssociatedContent {
  id: number;
  rowId: string;
}

export interface IProductMetadata {
  maNumbers: IMaNumber[];
  legalCategories: string[];
  hasBlackTriangle: boolean;
  isGlutenFree: boolean | null;
  isLactoseFree: boolean | null;
  isSuitableForVegans: boolean | null;
  isPregnancyRisk: boolean | null;
  atcCodes: string[];
  ampIds: string[] | null;
  name?: string | null;
  regulatorApprovedName?: string | null;
  extraInformation?: string;
  productForm?: string;
  activeIngredients: IActiveIngredientsDto;
  documentIds: number[];
  documentRenames?: IDocumentRenameDto[];
  contactGroup: string;
  discontinuationReasonId?: number;
  discontinuationReason?: string;
}

export interface IProductNameInformationUpdateCommand {
  name?: string | null;
  regulatorApprovedName?: string | null;
  extraInformation?: string;
  productForm?: string;
  activeIngredients: IActiveIngredientsDto;
}

export interface IActiveIngredientsMetadata {
  denominator?: IProductDenominator;
  numerators?: IProductNumerator[];
}

export interface IProductInfoForm {
  discontinuationReason: ISelectOption;
}

export enum ProductTabs {
  ProductInfo = 'Product info',
  AssociatedContent = 'Associated content',
  ProductDetails = 'Product details',
  ProductHistory = 'Product history',
}

export enum ProductReadonlySection {
  productDetails = 'productDetails',
}

export enum AssociatedContentReadonlySection {
  pilSmpcSelection = 'pilSmpcSelection',
  secondaryDocumentsSelection = 'secondaryDocumentsSelection',
  editDocumentName = 'editDocumentName',
}

export enum ProductInfoReadonlySection {
  productType = 'productType',
  genericName = 'genericName',
  activeIngredients = 'activeIngredients',
  productForm = 'productForm',
  extraInformation = 'extraInformation',
  displayName = 'displayName',
  regulatorApprovedName = 'regulatorApprovedName',
}

export type ProductReadonlySections = {
  [key in ProductReadonlySection]: boolean;
};

export type AssociatedContentReadonlySections = {
  [key in AssociatedContentReadonlySection]?: boolean;
};

export type ProductInfoReadonlySections = {
  [key in ProductInfoReadonlySection]?: boolean;
};

export interface IDocumentRenameDto {
  documentId: number;
  documentName: string;
  documentType: DocumentType;
}

export enum SubstanceErrors {
  notInProductGroup = 'Please contact the admin team to create a new product group for the missing substance.',
}
