import { useQuery } from '@tanstack/react-query';
import { fetchSubmissionTaskNotes } from '@common/services/adminService';
import { INotesQueryDependencies } from '@common/features/submission/types';

export const queryKey = 'fetchSubmissionTaskNotes';

export interface ISubmissionNote {
  description: string;
  from: string;
  to: string;
  id: number;
  performedBy: string;
  text: string;
  submittedAt: string;
}

export const useFetchSubmissionTaskNotes = (taskId: string, queryDependencies?: INotesQueryDependencies) => {
  const { data, isFetching, isLoading } = useQuery<ISubmissionNote[]>(
    [queryKey, taskId, queryDependencies],
    () => fetchSubmissionTaskNotes(taskId),
    {
      enabled:
        !!taskId &&
        (!queryDependencies || (!!queryDependencies.status && !!queryDependencies.assignedUser && !!queryDependencies.fastTrack)),
    },
  );

  return {
    data,
    isFetchingSubmissionTaskNotes: isFetching || isLoading,
  };
};
