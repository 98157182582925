import { useFormContext } from 'react-hook-form';
import { DocumentType, ICheckboxEntry, ValidationError } from '@common/types';
import { SideDecorationContent, Button, ButtonTypes, ModalWithFooterWrapper } from '@common/components';
import { getModalContent } from '@common/features/document/constants';
import { Title } from '@common/styles';
import { DocumentProcessingFlow } from '../DocumentContentDetails/types';
import { AdditionalTextFileSection } from '../AdditionalTextFileSection/AdditionalTextFileSection';
import * as Styled from '@common/features/document/styles';

export interface IRequestEpilStandardModal {
  isOpen: boolean;
  isUploading: boolean;
  uploadProgress: number;
  productsMatchingDocument?: ICheckboxEntry[];
  documentProcessingFlow: DocumentProcessingFlow;
  alternativeTextFileFormField: string;
  alternativeTextCheckboxFormField: string;
  onFileSubmit: (files: FileList | null) => void;
  onUploadClick: () => void;
  onCancelClick: () => void;
  onRemove: () => void;
  uploadErrors?: () => ValidationError[];
}

const RequestEpilStandardModal = ({
  isOpen,
  isUploading,
  uploadProgress,
  documentProcessingFlow,
  alternativeTextFileFormField,
  alternativeTextCheckboxFormField,
  onFileSubmit,
  onCancelClick,
  onUploadClick,
  onRemove,
}: IRequestEpilStandardModal) => {
  const { watch } = useFormContext();

  const uploadedAlternativeTextFile = watch(alternativeTextFileFormField);

  const alternativeTextFileSize = Math.round((uploadedAlternativeTextFile?.size ?? 0));

  const modalContent = getModalContent(null, documentProcessingFlow)[DocumentType.Epil];

  const renderFooter = () => {
    return (
      <Styled.FooterWrapper>
        <Styled.Bottom>
          <Styled.Left />
          <Styled.Right>
            <Styled.CancelButtonWrap>
              <Button onClick={onCancelClick} text="Cancel" type={ButtonTypes.TEXT} />
            </Styled.CancelButtonWrap>
            <Button
              disabled={isUploading}
              onClick={onUploadClick}
              width={163}
              text={'Upload'}
              type={ButtonTypes.PRIMARY}
              data-testid="document-upload"
            />
          </Styled.Right>
        </Styled.Bottom>
      </Styled.FooterWrapper>
    );
  };

  return (
    <ModalWithFooterWrapper isOpen={!!isOpen} footer={renderFooter()}>
      <Styled.Wrap>
        <Styled.Top>
          <Title>{modalContent.title}</Title>
        </Styled.Top>
        <SideDecorationContent>
          <AdditionalTextFileSection
            field={alternativeTextCheckboxFormField}
            withBorderAndSubtext
            disabled={false}
            isLoading={isUploading}
            uploadProgress={uploadProgress}
            uploadedFileName={uploadedAlternativeTextFile?.name}
            uploadedFileSize={alternativeTextFileSize}
            handleFileSubmit={onFileSubmit}
            handleRemove={onRemove}
          />
        </SideDecorationContent>
      </Styled.Wrap>
    </ModalWithFooterWrapper>
  );
};

export default RequestEpilStandardModal;
