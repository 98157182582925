import { useEffect } from 'react';
import { useAppDispatch } from '@common/hooks/redux';
import { setProductGroupName } from '../../slices/productGroup/productGroupSlice';
import { fetchPublicProductGroupState } from '../../services/productGroupService';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { Id } from '@common/types';
import { IPublicProductGroupState } from '../../types';

export interface Props {
  propagateError?: boolean;
  productGroupId: Id;
}

export const useGetPublicProductGroupState = () => {
  const dispatch = useAppDispatch();

  const { mutate, data, isLoading } = useWaitingMutation<IPublicProductGroupState>(({ productGroupId, propagateError }: Props) =>
    fetchPublicProductGroupState(productGroupId, propagateError),
  );

  useEffect(() => {
    dispatch(setProductGroupName(data?.productGroup.productGroupName));
  }, [data?.productGroup.productGroupName, dispatch]);

  return {
    getPublicProductGroupState: mutate,
    isGettingPublicProductGroupState: isLoading,
  };
};
