import { changeDocumentAssociation } from '@common/services/adminService';
import { Id } from '@common/types';
import useWaitingMutation from '@hooks/useWaitingMutation';

interface IChangeDocumentAssociationData {
  productIds: number[];
  documentId: Id;
}

export const useChangeDocumentAssociation = (submissionId: Id) => {
  const { mutate, isLoading } = useWaitingMutation(({ productIds, documentId }: IChangeDocumentAssociationData) =>
    changeDocumentAssociation(submissionId, documentId, { productIds }),
  );

  return {
    changeDocumentAssociation: mutate,
    isChangingDocumentAssociation: isLoading,
  };
};
