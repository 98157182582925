import { useQuery } from '@tanstack/react-query';
import { fetchActiveIngredientsSearch } from '@common/services/productService';
import { ISubstanceDto } from '@common/features/submission/types';

export const queryKey = 'fetchActiveIngredients';

export const useFetchActiveIngredients = (search: string) => {
  const { data, isFetching: isFetchingActiveIngredients } = useQuery<ISubstanceDto[]>(
    [queryKey],
    () => fetchActiveIngredientsSearch(search),
    { enabled: search.trim().length >= 3 },
  );

  return {
    activeIngredients: data ?? [],
    isFetchingActiveIngredients,
  };
};
