import { useQuery } from '@tanstack/react-query';
import { fetchDocumentConversionErrors } from '@common/services/documentService';
import { useState } from 'react';
import { DocumentType } from '@common/types';
import { IDocumentWithTask } from '@common/features/submission/types';

export interface IDocumentConversionError {
  errorType: string;
  message: string;
}

export const queryKey = 'fetchDocumentConversionErrors';

export const useFetchDocumentConversionErrors = () => {
  const [taskId, setTaskId] = useState<number | null>(null);

  const { data, isFetching } = useQuery<IDocumentConversionError[]>(
    [queryKey, taskId],
    () => fetchDocumentConversionErrors(taskId!),
    {
      enabled: !!taskId,
    },
  );

  const setTaskIdForConversionErrors = (
    taskId: number,
    contentType: DocumentType,
    allDocumentsWithTasks: IDocumentWithTask[],
  ) => {
    let id = taskId;

    // We display PIL but errors are in ePIL task folder
    if (contentType === DocumentType.Pil) {
      const pilDocumentId = allDocumentsWithTasks.find((t) => t.submissionTaskId === taskId)?.documentId;
      const epilDocument = allDocumentsWithTasks.find((t) => t.documentId === pilDocumentId && t.type === DocumentType.Epil);
      id = epilDocument?.submissionTaskId ?? -1;
    }

    setTaskId(id);
  };

  return {
    documentConversionErrors: data,
    isFetchingDocumentConversionErrors: isFetching,
    setTaskIdForConversionErrors,
  };
};

export const mapConversionErrorsToErrorLogs = (conversionErrors?: IDocumentConversionError[]) =>
  conversionErrors?.map((e) => ({ mainError: e.errorType, subError: e.message }));
