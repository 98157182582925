import { useFormContext } from 'react-hook-form';
import { DocumentType, ICheckboxEntry, ValidationError } from '@common/types';
import { SideDecorationContent, Button, ButtonTypes, ModalWithFooterWrapper } from '@common/components';
import { getModalContent } from '@common/features/document/constants';
import * as Styled from '@common/features/document/styles';
import FileUpload from '@components/FileUpload/FileUpload';
import { UNITS_DIVIDER } from '@common/constants';
import { Title } from '@common/styles';
import { DocumentProcessingFlow } from '../DocumentContentDetails/types';
import { AdditionalTextFileSection } from '../AdditionalTextFileSection/AdditionalTextFileSection';

export interface IAddModal {
  isOpen: boolean;
  isUploading: boolean;
  uploadProgress: number;
  productsMatchingDocument?: ICheckboxEntry[];
  documentProcessingFlow: DocumentProcessingFlow;
  alternativeTextFileFormField: string;
  alternativeTextCheckboxFormField: string;
  alternativeTextFileUploadProgress: number;
  onFileSubmit: (files: FileList | null) => void;
  onAlternativeTextFileSubmit: (files: FileList | null) => void;
  onAlternativeTextFileRemove: () => void;
  onUploadClick: () => void;
  onCancelClick: () => void;
  onRemove: () => void;
  uploadErrors?: () => ValidationError[];
}

const RequestEpilModal = ({
  isOpen,
  isUploading,
  uploadProgress,
  documentProcessingFlow,
  alternativeTextFileFormField,
  alternativeTextCheckboxFormField,
  alternativeTextFileUploadProgress,
  onFileSubmit,
  onAlternativeTextFileSubmit,
  onAlternativeTextFileRemove,
  onCancelClick,
  onUploadClick,
  onRemove,
}: IAddModal) => {
  const { watch } = useFormContext();

  const uploadedSecondaryFile = watch('file');

  const secondaryfileSize = Math.round((uploadedSecondaryFile?.size ?? 0) / UNITS_DIVIDER);

  const uploadedAlternativeTextFile = watch(alternativeTextFileFormField!);

  const alternativeTextFileSize = Math.round(uploadedAlternativeTextFile?.size ?? 0);

  const modalContent = getModalContent(null, documentProcessingFlow)[DocumentType.Epil];

  const renderFooter = () => {
    return (
      <Styled.FooterWrapper>
        <Styled.Bottom>
          <Styled.Left />
          <Styled.Right>
            <Styled.CancelButtonWrap>
              <Button onClick={onCancelClick} text="Cancel" type={ButtonTypes.TEXT} />
            </Styled.CancelButtonWrap>
            <Button
              disabled={isUploading}
              onClick={onUploadClick}
              width={163}
              text={'Upload'}
              type={ButtonTypes.PRIMARY}
              data-testid="document-upload"
            />
          </Styled.Right>
        </Styled.Bottom>
      </Styled.FooterWrapper>
    );
  };

  return (
    <ModalWithFooterWrapper isOpen={!!isOpen} footer={renderFooter()}>
      <Styled.Wrap>
        <Styled.Top>
          <Title>{modalContent.title}</Title>
          {modalContent.description}
        </Styled.Top>
        <SideDecorationContent>
          <FileUpload
            title={modalContent.fileUploadTitle}
            onFileSubmit={onFileSubmit}
            loadingPresents={uploadProgress}
            isLoading={isUploading}
            fileSize={secondaryfileSize + 'KB'}
            fileName={uploadedSecondaryFile?.name}
            fileInputSubText={modalContent.fileSubText}
            onRemove={onRemove}
          />
        </SideDecorationContent>
        <SideDecorationContent>
          <AdditionalTextFileSection
            field={alternativeTextCheckboxFormField}
            withBorderAndSubtext
            disabled={false}
            isLoading={isUploading}
            uploadProgress={alternativeTextFileUploadProgress}
            uploadedFileName={uploadedAlternativeTextFile?.name}
            uploadedFileSize={alternativeTextFileSize}
            handleFileSubmit={onAlternativeTextFileSubmit}
            handleRemove={onAlternativeTextFileRemove}
          />
        </SideDecorationContent>
      </Styled.Wrap>
    </ModalWithFooterWrapper>
  );
};

export default RequestEpilModal;
