export enum Widgets {
  NewsWidget = 'News widget',
  WelcomeWidget = 'Welcome widget',
  ArticleWidget = 'Article widget',
  TutorialsWidget = 'Tutorials widget',
  SlaWidget = 'Sla widget',
  LinksWidget = 'Links widget',
}

export interface Position {
  row: number;
  col: number;
}

export type WidgetData = {
  widgetType: Widgets;
  data: any;
  ref: any;
  pos: Position;
};
