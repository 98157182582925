import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '@common/hooks/redux';
import { setProductGroupName } from '@common/features/productGroup/slices/productGroup/productGroupSlice';
import { fetchSubmission } from '@common/services/submissionService';
import { usePleaseWaitOverlay } from '@common/components/PleaseWaitOverlay/usePleaseWaitOverlay';
import { ISubmissionDto } from '@common/features/submission/types';
import { checkDigitalVersion } from '@common/features/document/helpers';
import { convertToDateWithTimezoneCompensation } from '@common/helpers';

export const queryKey = 'fetchSubmission';

export const useFetchSubmission = (id: string, staleTime?: number) => {
  const dispatch = useAppDispatch();
  const { handleShowOverlay, handleHideOverlay } = usePleaseWaitOverlay();

  const {
    data,
    isFetching: isFetchingSubmission,
    isFetched: isSubmissionFetched,
    refetch,
  } = useQuery<ISubmissionDto>(
    [queryKey, id],
    () => {
      handleShowOverlay();
      return fetchSubmission(id);
    },
    {
      enabled: !!id,
      staleTime,
      select: (data) => ({
        ...data,
        products: data.products?.map((p) => ({
          ...p,
          lastChangedDate: new Date(p.lastChangedDate),
        })),
        documents: data.documents?.map((d) => ({
          ...d,
          authorisedDate: d.authorisedDate ? convertToDateWithTimezoneCompensation(d.authorisedDate) : null,
          regulatorApprovalDate: d.regulatorApprovalDate
            ? convertToDateWithTimezoneCompensation(d.regulatorApprovalDate)
            : undefined,
          hasDigitalVersion: checkDigitalVersion(d, data.documents),
        })),
      }),
    },
  );

  useEffect(() => {
    !isFetchingSubmission && handleHideOverlay();
  }, [isFetchingSubmission, handleHideOverlay]);

  useEffect(() => {
    dispatch(setProductGroupName(data?.name || data?.productGroup.productGroupName || ''));
  }, [data?.name, data?.productGroup?.productGroupName, dispatch]);

  return {
    submission: data,
    isFetchingSubmission,
    isSubmissionFetched,
    refetchFetchSubmission: refetch,
  };
};
