import { useQuery } from '@tanstack/react-query';
import { fetchSubmissionTaskData } from '@common/services/submissionTaskService';
import { ISubmissionTaskDto } from '@common/features/submission/types';

export const queryKey = 'fetchSubmissionTaskData';

export const useFetchSubmissionTaskData = (taskId: string, staleTime?: number, enabled: boolean = true) => {
  const {
    data: submissionTask,
    isFetching,
    isLoading,
    isFetched,
    refetch,
  } = useQuery<ISubmissionTaskDto>([queryKey, taskId], () => fetchSubmissionTaskData(taskId), {
    enabled: !!taskId && enabled,
    staleTime,
  });

  return {
    submissionTask,
    isFetchingSubmissionTaskData: isFetching || isLoading,
    isSubmissionTaskDataFetched: isFetched,
    refetchSubmissionTaskData: refetch,
  };
};
