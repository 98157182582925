import { formatDate, formatPeriodInDays, isStringNullOrEmpty } from '@common/helpers';
import { Button, ButtonTypes } from '@common/components';
import { IDocumentDto } from '@common/features/submission/types';
import { DocumentType } from '@common/types';
import { StyledLink as StyledToLink } from '@common/components/Link/styles';
import { ITaskDocumentTableCallbacks } from '@common/components/DatapharmTable/types';
import { DocumentActions } from '../DocumentActions/DocumentActions';
import { createColumnHelper } from '@tanstack/react-table';
import { boolToYesNo } from '@common/helpers';
import DOMPurify from 'dompurify';
import { IDocumentAttributes } from '@common/features/document/helpers';
import { TitleWeblinkCell } from '@common/features/document/components/TitleWeblinkCell';
import { ICompanyConfigurationDto } from '@common/features/configuration/types';
import { ViewContentLink } from '@common/components/ViewContentLink/ViewContentLink';
import { IDocumentConversionResult } from '@common/features/user/types';
import ConversionStatus from '@common/components/ConversionStatus/ConversionStatus';
import { IDocumentContentInfo } from '@common/hooks/useFetchDocumentHtmlContent';

export const prepareTaskDocumentColumns = (
  isAdmin: boolean,
  taskDocumentCallbacks: (type?: DocumentType) => ITaskDocumentTableCallbacks,
  onContentTitleClick: (documentId: string) => void,
  onDocumentDetailsClick: (documentId: string) => void,
  onContentDescriptionClick: (text: string) => void,
  onViewContent: (documentData: IDocumentContentInfo) => void,
  documentAttributes: IDocumentAttributes,
  isReadonly: boolean,
  taskHasConversion: boolean,
  taskFilesValid: boolean,
  taskId: number,
  documentData: IDocumentContentInfo,
  openDocumentConversionErrorModal: (taskId: number) => void,
  conversionResult?: IDocumentConversionResult,
  companyConfiguration?: ICompanyConfigurationDto,
) => {
  const columnHelper = createColumnHelper<IDocumentDto>();

  return [
    columnHelper.accessor('documentTitle', {
      header: 'Document title',
      cell: ({ row, getValue }) => {
        var isAudioVideoWithoutUrl = documentAttributes.showAudioVideoWebLink && isStringNullOrEmpty(row.original.link);

        if (companyConfiguration?.hasQrdEpilsEnabled) {
          return (
            <ViewContentLink
              id={row.original.documentId}
              text={getValue()}
              isHtml={documentAttributes.hasHtmlContent}
              documentData={documentData}
              link={row.original.link}
              disabled={!row.original.type || isAudioVideoWithoutUrl}
              onViewContent={onViewContent}
              onFileOpen={onContentTitleClick}
            />
          );
        }

        return (documentAttributes.showRmmWebLink || documentAttributes.showAudioVideoWebLink) && row.original.link ? (
          <ViewContentLink
            id={row.original.documentId}
            text={getValue()}
            isHtml={documentAttributes.hasHtmlContent}
            documentData={documentData}
            link={row.original.link}
            disabled={!row.original.type || isAudioVideoWithoutUrl}
            onViewContent={onViewContent}
            onFileOpen={onContentTitleClick}
          />
        ) : (
          getValue()
        );
      },
    }),
    columnHelper.accessor('version', {
      header: 'Version',
    }),
    documentAttributes?.showHasDigitalVersion
      ? columnHelper.accessor('hasDigitalVersion', {
          header: 'ePIL',
          cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
        })
      : undefined,
    columnHelper.accessor('reasonsForSubmission', {
      header: 'Reasons for submission',
      cell: (info) =>
        info.getValue().length > 0 ? (
          <>
            {info.getValue()?.map((reason) => (
              <p key={reason.text}>{reason.text}</p>
            ))}
          </>
        ) : (
          '-'
        ),
    }),
    documentAttributes?.showRegulatorApprovalDate
      ? columnHelper.accessor('regulatorApprovalDate', {
          header: 'Regulator approval date',
          cell: (info) => formatDate(info.getValue()),
        })
      : null,
    documentAttributes?.showRegulatorApprovalDate
      ? columnHelper.accessor('daysSinceApproval', {
          header: 'Days since approval',
          id: 'daysSinceApproval',
          cell: ({ getValue }) => formatPeriodInDays(getValue()),
        })
      : null,
    documentAttributes.showRmmWebLink || documentAttributes.showAudioVideoWebLink
      ? columnHelper.accessor('link', {
          header: 'Weblink',
          cell: ({ getValue }) => (
            <TitleWeblinkCell text={getValue() ?? ''} link={getValue()} htmlContent={null} disabled={!getValue()} />
          ),
        })
      : undefined,
    documentAttributes?.showHcpOnly
      ? columnHelper.accessor('isVisibleToHCP', {
          header: 'HCP',
          cell: ({ getValue }) => boolToYesNo(getValue() ?? false),
        })
      : null,
    columnHelper.accessor('approvedByRegulator', {
      header: 'Regulator',
      cell: ({ getValue }) => {
        return getValue() ?? '-';
      },
    }),
    columnHelper.accessor('authorisedDate', {
      header: 'Date of first Authorisation',
      cell: (info) => formatDate(info.getValue()),
    }),
    columnHelper.accessor('linkedProductIds', {
      header: 'Associated products',
      cell: ({ getValue }) => getValue()?.length ?? '-',
    }),
    columnHelper.accessor('documentId', {
      header: 'Content ID',
    }),
    documentAttributes?.showContentDescription
      ? columnHelper.accessor('contentDescription', {
          header: 'Content Description',
          cell: ({ getValue }) => {
            const contentDescription = getValue() ?? '';
            return contentDescription.length > 0 ? (
              <StyledToLink onClick={(e) => onContentDescriptionClick(DOMPurify.sanitize(contentDescription))} to="#">
                {'View'}
              </StyledToLink>
            ) : (
              '-'
            );
          },
        })
      : null,
    !isAdmin && taskHasConversion
      ? columnHelper.accessor('status', {
          id: 'status',
          header: 'Status',
          cell: () => {
            let conversionState = conversionResult
              ? { isFailed: !conversionResult.isFileValid, isInProgress: !conversionResult.isFinished }
              : { isFailed: !taskFilesValid, isInProgress: false };

            return (
              <ConversionStatus conversionState={conversionState} onViewClick={() => openDocumentConversionErrorModal(taskId)} />
            );
          },
        })
      : null,
    columnHelper.accessor('details', {
      header: 'Details',
      cell: (info) => {
        const documentId = info.row.original.documentId.toString();
        const handleDocumentDetailsClick = () => onDocumentDetailsClick(documentId);
        return <Button type={ButtonTypes.TEXT} onClick={handleDocumentDetailsClick} text="" icon="/icons/eye.svg" height={25} />;
      },
    }),
    !isReadonly
      ? columnHelper.display({
          id: 'actions',
          header: 'Actions',
          cell: (info) => {
            const documentId = info.row.original.documentId.toString();
            const documentType = info.row.original.type;
            const actionCallbacks = taskDocumentCallbacks(documentType);
            return isReadonly ? (
              false
            ) : (
              <DocumentActions
                documentId={documentId}
                documentCallbacks={actionCallbacks}
                documentAttributes={documentAttributes}
              />
            );
          },
        })
      : undefined,
  ].filter((x) => x);
};

export const mapHasDigitalVersion = (productGroupDocuments: IDocumentDto[] = [], submissionDocuments: IDocumentDto[] = []) =>
  productGroupDocuments?.map((d) => ({
    ...d,
    hasDigitalVersion: submissionDocuments.find((sd) => sd.documentId === d.documentId)?.hasDigitalVersion ?? null,
  }));
