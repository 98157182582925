import styled from 'styled-components';

export const TutorialsWrap = styled.div`
  display: grid;
  row-gap: 10px;
  width: 100%;
`;

export const TutorialsDisclaimer = styled.span`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const TopControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TutorialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 10px;
`;

export const FormControlWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
`;

export const TutorialLink = styled.button`
  display: flex;
  text-align: left;
  padding: 15px 10px;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 10px;
  column-gap: 10px;

  .arrowPostfix {
    margin-left: auto;
  }

  .tick {
    min-height: 30px;
    min-width: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TutorialDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: normal;
  text-overflow: ellipsis;
  text-wrap: auto;
`;

export const TutorialName = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 600;
`;
