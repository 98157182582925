import { useQuery } from '@tanstack/react-query';
import { fetchSubmissionTaskDetailsByDocumentAndType } from '@common/services/submissionService';
import { DocumentType, ISubmissionTaskDto } from '../../types';
import { Id } from '@common/types';

export const queryKey = 'fetchSubmissionTaskByDocument';

export const useFetchSubmissionTaskByDocument = (submissionId: string, documentId: Id, contentType: DocumentType) => {
  const {
    data: submissionTaskData,
    isFetching: isFetchingSubmissionTaskByDocument,
    isFetched: isSubmissionTaskByDocumentFetched,
  } = useQuery<ISubmissionTaskDto>(
    [queryKey],
    () => fetchSubmissionTaskDetailsByDocumentAndType(submissionId, documentId, contentType),
    { enabled: !!submissionId && !!documentId && !!contentType },
  );

  return {
    submissionTaskData,
    isFetchingSubmissionTaskByDocument,
    isSubmissionTaskByDocumentFetched,
  };
};
