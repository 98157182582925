import { SidebarLogo, SidebarWrap } from './styles';
import SidebarEntry from '@common/components/SidebarEntry/SidebarEntry';

export interface IEntry {
  display: string;
  to: string;
  getIcon: any;
  shouldOpenInNewTab: boolean;
}

interface IProps {
  entries: IEntry[];
}

const GlobalSidebar = ({ entries }: IProps) => (
  <SidebarWrap>
    <SidebarLogo src="/publisherLogo.png" />
    {entries.map((entry, index) => (
      <SidebarEntry key={index} to={entry.to} display={entry.display} getIcon={entry.getIcon} shouldOpenInNewTab={entry.shouldOpenInNewTab} />
    ))}
  </SidebarWrap>
);

export default GlobalSidebar;
