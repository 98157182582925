import styled from 'styled-components';

export const DndColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const DndVisualCue = styled.hr`
  border-top: 1px solid gray;
`;

export const WidgetsDndWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
`;

export const SortableItemWrapper = styled.div`
  position: relative;
  gridcolumn: 'span 2';
`;

export const DragHandle = styled.span`
  width: 10px;
  height: 20px;
  display: inline-block;
  line-height: 5px;
  padding: 3px 4px;
  cursor: move;
  vertical-align: middle;
  top: 0.5rem;
  right: 1.5rem;
  font-size: 12px;
  letter-spacing: 2px;
  color: black;
  text-shadow: 1px 0 1px black;
  z-index: 5;
  position: absolute;

  ::before {
    content: '.. .. .. ..';
  }
`;
