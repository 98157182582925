import { toast } from 'react-toastify';
import { RequestType } from './types';
import axios, { AxiosError } from 'axios';
import { getUser } from '@common/auth/useOidcAuthentication';

interface IHandleRequestParams {
  url: string;
  requestType: RequestType;
  shouldUseSubscriptionKey?: boolean;
  data?: any;
  propagateNotFound?: boolean;
  companyId?: string;
}

export const buildUrl = (endpoint: string, basePath?: string, query?: string) => {
  const urlWithQuery = query ? `${endpoint}?${query}` : endpoint;

  return `${basePath}${urlWithQuery}`;
};

export const handleFileRequest = async ({
  url,
  requestType,
  shouldUseSubscriptionKey = true,
  data,
  propagateNotFound: shouldNotifyOnNotFound = true,
}: IHandleRequestParams) => {
  const token = getUser()?.access_token;

  const headers = {
    Authorization: `Bearer ${token}`,
    'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`,
  };

  if (!shouldUseSubscriptionKey) {
    delete (headers as any)['Ocp-Apim-Subscription-Key'];
  }

  try {
    const response = await axios.request({
      method: requestType,
      url: url,
      data,
      headers: headers,
      responseType: 'blob',
    });

    return response;
  } catch (e: any) {
    if (e?.response?.status !== 404) {
      toast.error('Error occurred');
    } else if (shouldNotifyOnNotFound) {
      toast.error('File not found');
    }

    return null;
  }
};

export const handleRequest = async ({
  url,
  requestType,
  shouldUseSubscriptionKey = true,
  data,
  propagateNotFound = true,
  companyId,
}: IHandleRequestParams) => {
  const token = getUser()?.access_token;

  const headers = {
    Authorization: `Bearer ${token}`,
    'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`,
    'Content-Type': 'application/json',
  };

  if (!shouldUseSubscriptionKey) {
    delete (headers as any)['Ocp-Apim-Subscription-Key'];
  }

  if (data && data.constructor.name === 'FormData') {
    delete (headers as any)['Content-Type'];
  }

  if (companyId) {
    (headers as any)['Company-Id'] = companyId;
  }

  try {
    const response = await axios.request({
      method: requestType,
      url: url,
      data,
      headers,
    });

    return response.data;
  } catch (e: any) {
    if (e.code === AxiosError.ERR_CANCELED) return;

    if (!propagateNotFound && e.response?.status === 404) {
      return null;
    }

    const messageWithStatus = {
      message: e.response?.statusText,
      cause: e.response?.status,
      data: e.response?.data,
    };

    return Promise.reject(messageWithStatus);
  }
};
