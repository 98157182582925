import React from 'react';
import { useOidcAuthentication } from '@common/auth/useOidcAuthentication';
import DefaultRoutes from 'apps/publisher-v3.ui.customer/src/routing/DefaultRoutes';
import MainContainer from './containers/MainContainer/MainContainer';
import { Toast } from '@common/components/Toast/Toast';
import { UnsavedChangesContextProvider } from '@common/context/UnsavedChangesContext';
import { selectShowPleaseWaitOverlay } from '@common/features/global/selectors';
import { PleaseWaitOverlay } from '@common/components/PleaseWaitOverlay';
import { useDelayedLoader } from '@common/components/PleaseWaitOverlay/useDelayedLoader';
import { selectActiveCompany, selectHasUserInsufficientPermissions, selectUserId } from '@common/features/user/selectors';
import { Watermark } from '@common/components/Watermark/watermark';
import axios from 'axios';
import { invokeDataLayer } from '@common/services/dataLayerService';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MaintenanceInfo } from '@common/components/Maintenance/MaintenanceInfo';
import { selectConfiguration } from '@common/features/configuration/selectors';
import { InsufficientPermissionsInfo } from '@common/components/InsufficientPermissions/InsufficientPermissionsInfo';
import { WithQueryClient } from '@common/auth/WithQueryClient';
import { useFetchDocumentConversionResults } from '@common/hooks/useFetchDocumentConversionResults';
import { useAppSelector } from '@common/hooks/redux';
import { setupAxiosInterceptors } from '@common/auth/setupAxios';

function App() {
  const { isAuthenticated, logoutUser } = useOidcAuthentication();
  setupAxiosInterceptors({
    onUnauthorised: () => {
      logoutUser();
    },
  });

  invokeDataLayer();

  const configuration = useAppSelector(selectConfiguration);
  const userId = useAppSelector(selectUserId);

  const showMaintenancePage = configuration.featureFlags?.showMaintenancePage;
  const maintenanceMessage = configuration.maintenanceMessage;
  const showPleaseWaitOverlay = useAppSelector(selectShowPleaseWaitOverlay);
  const companyId = useAppSelector(selectActiveCompany)?.id;

  useFetchDocumentConversionResults(!!companyId);

  axios.defaults.headers.common['Company-Id'] = companyId;
  const { showDelayedLoader } = useDelayedLoader(showPleaseWaitOverlay);
  const showReactQueryDevtools = process.env.REACT_APP_SHOW_REACT_QUERY_DEVTOOLS === 'TRUE';

  const hasUserInsufficientPermissions = useAppSelector(selectHasUserInsufficientPermissions);

  if (hasUserInsufficientPermissions) {
    return <InsufficientPermissionsInfo />;
  }

  return (
    <>
      {showReactQueryDevtools && <ReactQueryDevtools />}
      {!companyId || !configuration || !userId ? (
        <PleaseWaitOverlay />
      ) : (
        <>
          <Watermark>{process.env.REACT_APP_PLATFORMNAME}</Watermark>
          {companyId && isAuthenticated && showMaintenancePage && <MaintenanceInfo maintenanceMessage={maintenanceMessage} />}
          {companyId && isAuthenticated && !showMaintenancePage && (
            <UnsavedChangesContextProvider>
              <MainContainer>
                <DefaultRoutes />
              </MainContainer>
            </UnsavedChangesContextProvider>
          )}
        </>
      )}
      <Toast />
      {showDelayedLoader && <PleaseWaitOverlay />}
    </>
  );
}

export default WithQueryClient(App);
