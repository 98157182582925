import { fetchHtml } from '@common/services/adminService';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

export const useFetchHtml = (enabled: boolean = true) => {
  const { taskId = '' } = useParams();

  const { data, isFetching, isFetched } = useQuery(['fetchHtml', taskId], () => fetchHtml(taskId), {
    enabled: !!taskId && enabled,
  });

  return { htmlData: data, isFetchingHtml: isFetching, isHtmlFetched: isFetched };
};
