import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { replaceAltText } from '@common/services/requestService';

export interface IPayloadAltText {
  submissionId: string;
  documentId: string;
  fileName: string;
}

export const useReplaceAltText = () => {
  const { mutate } = useWaitingMutation((payload: IPayloadAltText) => {
    return replaceAltText(payload.submissionId!, payload.documentId!, { AlternativeTextFileName: payload.fileName });
  });

  return { replaceAltText: mutate };
};
