export const routes: Routes = {
  submissions: '/submissions',
  drafts: '/drafts',
  products: '/allProducts',
  audits: '/audits',
  productGroups: '/product-groups',
  product: '/product',
  createSubmission: '/createSubmission',
};

interface Routes {
  submissions: string;
  products: string;
  drafts: string;
  audits: string;
  product: string;
  productGroups: string;
  createSubmission: string;
}

export const paths: Paths = {
  submissionSummary: 'submissionSummary',
  submissionTasks: 'submission-tasks',
  html: 'html',
};

interface Paths {
  submissionSummary: string;
  submissionTasks: string;
  html: string;
}
