import React from 'react';

import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

import { ICompanyPermission } from '@common/features/user/types';

import { CompanyItem } from './CompanyItem';

import {
  UserInfoPopoverWrap,
  PopoverItem,
  PopoverHeader,
  PopoverHeaderLeft,
  PopoverHeaderRight,
  PopoverArrowWrap,
  PopoverArrow,
  PopoverContent,
  EditProfileLink,
  StyledAccountIcon,
  MenuSpacer,
  PopoverItemText,
  SignOutWrapper,
} from './styles';
import { useLogoutUser } from '@common/auth/useOidcAuthentication';

interface IUserInfoPopoverContent {
  companies: ICompanyPermission[];
  activeCompanyId: number | undefined;
  email: string;
  userId: number;
}

export const UserInfoPopoverContent = ({ companies, activeCompanyId, email, userId }: IUserInfoPopoverContent) => {
  const { logoutUser } = useLogoutUser();
  const { showEditProfile } = useFeatureFlags();

  const renderCompanies = () => {
    return companies.map((item) => {
      const isActiveCompany = item.id === activeCompanyId;
      return <CompanyItem key={item.id} userId={userId} company={item} isActiveCompany={isActiveCompany} />;
    });
  };

  const handleLogout = () => {
    logoutUser();
  };

  return (
    <UserInfoPopoverWrap>
      <PopoverArrowWrap>
        <PopoverArrow />
      </PopoverArrowWrap>
      <PopoverContent>
        <PopoverHeader>
          <PopoverHeaderLeft>
            <StyledAccountIcon src={`/icons/account.svg`} />
          </PopoverHeaderLeft>
          <PopoverHeaderRight>
            {email && <div>{email}</div>}
            {showEditProfile && <EditProfileLink>Edit Profile</EditProfileLink>}
          </PopoverHeaderRight>
        </PopoverHeader>
        <PopoverItem>{renderCompanies()}</PopoverItem>
        <PopoverItem onClick={handleLogout}>
          <SignOutWrapper>
            <MenuSpacer />
            <PopoverItemText>Sign Out</PopoverItemText>
          </SignOutWrapper>
        </PopoverItem>
      </PopoverContent>
    </UserInfoPopoverWrap>
  );
};
