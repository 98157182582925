import { useState } from 'react';
import { useDeleteFile } from '@hooks/useDeleteFile';
import { abortUpload } from '@api/uploadFileWithProgression';
import { FileRoles, RequestEpilStandardFormFields } from '@common/features/document/types';
import { useForm } from 'react-hook-form';
import { DocumentType } from '@common/types';
import { useParams } from 'react-router';
import { useRequestEpilStandard } from './useRequestEpilStandard';
import { useInlineFileUpload } from './useInlineFileUpload';
import { getSubmitValidationErrors } from '../helpers';

interface IRequestEpilStandardForm {
  [RequestEpilStandardFormFields.isWithAlternativeText]: boolean;
  [RequestEpilStandardFormFields.alternativeTextFile]: File | undefined;
  [RequestEpilStandardFormFields.alternativeTextFileName]: string;
}

export const useRequestEpilStandardModal = (onUploadSuccess: (documentId: string) => void) => {
  const { submissionId } = useParams();

  const { deleteFile } = useDeleteFile();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState<string | undefined>(undefined);

  const methods = useForm<IRequestEpilStandardForm>({
    mode: 'onChange',
    defaultValues: {
      [RequestEpilStandardFormFields.isWithAlternativeText]: false,
      [RequestEpilStandardFormFields.alternativeTextFile]: undefined,
      [RequestEpilStandardFormFields.alternativeTextFileName]: '',
    },
  });

  const { setValue, reset, getValues, handleSubmit } = methods;

  const { handleFileSubmit, handleRemove, isLoading, fileErrors, setFileErrors, clearErrors, uploadProgress } =
    useInlineFileUpload({
      contentType: DocumentType.Epil,
      methods,
      fileFormField: RequestEpilStandardFormFields.alternativeTextFile,
      fileNameFormField: RequestEpilStandardFormFields.alternativeTextFileName,
      fileRole: FileRoles.AlternativeText,
    });

  const { requestEpil } = useRequestEpilStandard();

  const handleUploadClick = () => {
    const submitValidationErrors = getSubmitValidationErrors();
    if (
      !getValues(RequestEpilStandardFormFields.alternativeTextFileName) &&
      getValues(RequestEpilStandardFormFields.isWithAlternativeText)
    )
      setFileErrors([submitValidationErrors.ALTERNATIVE_TEXT_FILE_MISSING]);
    handleSubmit((data) =>
      requestEpil(
        { documentId: documentId!, submissionId: submissionId!, alternativeTextFileName: data.alternativeTextFileName },
        {
          onSuccess: () => {
            setIsModalOpen(false);
            setValue(RequestEpilStandardFormFields.isWithAlternativeText, false);
            setValue(RequestEpilStandardFormFields.alternativeTextFile, undefined);
            setValue(RequestEpilStandardFormFields.alternativeTextFileName, '');
            onUploadSuccess(documentId!);
          },
        },
      ),
    )();
  };

  const handleCancel = () => {
    const fileName = getValues('alternativeTextFileName');
    setIsModalOpen(false);
    clearErrors();
    reset({});
    abortUpload();
    if (!isLoading && fileName) {
      deleteFile(fileName);
    }
  };

  const handleOpenModal = (documentId?: string) => {
    setIsModalOpen(true);
    setDocumentId(documentId);
  };

  return {
    handleRequestEpilStandardModalFileSubmit: handleFileSubmit,
    handleRequestEpilStandardModalUploadClick: handleUploadClick,
    handleRequestEpilStandardModalCancel: handleCancel,
    handleRequestEpilStandardModalRemove: handleRemove,
    handleRequestEpilStandardModalOpen: handleOpenModal,
    clearRequestEpilStandardModalErrors: clearErrors,
    requestEpilStandardModalFileErrors: fileErrors,
    isRequestEpilStandardModalOpen: isModalOpen,
    isRequestEpilStandardModalLoading: isLoading,
    requestEpilStandardModalUploadProgress: uploadProgress,
    requestEpilStandardModalMethods: methods,
  };
};
