import { SidebarEntryWrapper, StyledLink, IconWrapper } from './styles';
import { useLocation } from 'react-router-dom';

interface ISidebarEntry {
  to: string;
  display: string;
  getIcon: any;
  shouldOpenInNewTab: boolean;
}

const SidebarEntry = ({ to, display, getIcon, shouldOpenInNewTab }: ISidebarEntry) => {
  const location = useLocation();
  const isSelected = location.pathname === to;

  return (
    <StyledLink to={to} {...(shouldOpenInNewTab ? {target: "_blank"} : {}) }>
      <SidebarEntryWrapper selected={isSelected}>
        <IconWrapper selected={isSelected}>{getIcon()}</IconWrapper>
        {display}
      </SidebarEntryWrapper>
    </StyledLink>
  );
};

export default SidebarEntry;
