import { useQuery } from '@tanstack/react-query';
import { getSubmissionDocumentHtmlContent } from '@services/submissionService';
import { DocumentExtensionType } from '@common/types';
import { getPublishedDocument } from '@common/services/submissionTaskService';
import { DocumentType } from '@common/types';

export const fetchHtmlContentQueryKey = 'fetchHtmlContent';

export interface IDocumentContentInfo {
  documentId: number;
  version?: number | null;
  type?: DocumentType | null;
  submissionId?: number | null;
}

export const useFetchDocumentHtmlContent = (documentData?: IDocumentContentInfo, successCallback?: (data: string) => void) => {
  const fetchDocumentHtmlContent = async (documentData: IDocumentContentInfo) => {
    if (documentData?.submissionId) {
      return await getSubmissionDocumentHtmlContent(documentData.submissionId.toString(), documentData.documentId.toString());
    } else if (documentData) {
      const response = await getPublishedDocument(
        documentData.documentId.toString(),
        (documentData.version ?? 1).toString(),
        documentData.type as DocumentType,
        `extensionType=${DocumentExtensionType.Html}`,
        true,
        false,
      );
      return response.data.text();
    }
    return null;
  };

  const { data, isFetching } = useQuery(
    ['documentContent', documentData],
    () => {
      if (documentData) {
        return fetchDocumentHtmlContent(documentData);
      }
      return null;
    },
    {
      enabled: !!documentData,
      onSuccess: (data) => successCallback && successCallback(data),
    },
  );

  return { data, documentFetching: isFetching, fetchDocumentHtmlContent };
};
