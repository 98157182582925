import { IReadonlyContentDetails } from './types';
import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { selectProductGroup } from '@common/features/productGroup/slices/productGroup/selectors';
import { DocumentType } from '@common/types';
import * as Styled from './styles';
import { useFetchPublicProductGroupState } from '@common/features/productGroup/hooks/useFetchPublicProductGroupState';
import Tabs from '@common/components/Tabs/Tabs';
import { ReadonlyDocumentContent } from '../../components/DocumentContentDetails/ReadonlyDocumentContent';
import DocumentDetailsSkeletonLoader from './DocumentDetailsSkeletonLoader';
import { HtmlDocument } from '@components/HtmlDocument/HtmlDocument';
import { htmlFileIsAvailable } from '@features/document/helpers';
import { DocumentVersionHistory } from '../../components/DocumentVersionHistory/DocumentVersionHistory';
import { documentTypeToCustomLabelMap } from '@common/constants';
import { DocumentProcessingFlow } from '../../components/DocumentContentDetails/types';
import { getDocumentAttributes } from '../../helpers';
import { useFetchDocumentHtmlContent } from '@common/hooks/useFetchDocumentHtmlContent';
import { useAppSelector } from '@common/hooks/redux';

const PublishedDocumentDetailsContent = ({
  showHistoryFiltering,
  contentType,
  productGroupId,
  documentId,
  isAdmin = false,
  onCancel,
  onFileDownload,
}: IReadonlyContentDetails) => {
  const productGroup = useAppSelector(selectProductGroup);
  const { publicProductGroupState, isFetchingPublicProductGroupState, isPublicProductGroupStateFetched } =
    useFetchPublicProductGroupState(productGroupId!);

  const document = publicProductGroupState?.documents.find((d) => d.documentId === Number.parseInt(documentId!));
  const documentTitle = document?.documentTitle;
  const fileName = document?.originalFileName;
  const version = document?.version ?? 1;
  const approvalDate = document?.regulatorApprovalDate;
  const approved = !!document?.regulatorApprovalDate;
  const firstAuthorisationDate = document?.authorisedDate;
  const regulator = document?.approvedByRegulator;
  const reasonsForSubmission = document?.reasonsForSubmission;
  const documentState = document?.state;
  const hasDigitalVersion = document?.hasDigitalVersion ?? null;
  const url = document?.link ?? null;
  const details = document?.details ?? '';

  const reasonForRetirement =
    document?.reasonForRetirement?.length! > 0
      ? { label: document?.reasonForRetirement as string, value: document?.reasonForRetirement as string }
      : undefined;

  const displayHtmlView = !!document && htmlFileIsAvailable(document, publicProductGroupState!.documents);
  const documentProcessingDetails = {
    documentProcessingFlow: DocumentProcessingFlow.standard,
    isCustomerApprovalRequired: null,
    customerUrl: undefined,
  };

  const attributes = getDocumentAttributes(contentType, isAdmin);

  const { data: publishedHtml, documentFetching } = useFetchDocumentHtmlContent(
    attributes.hasHtmlContent
      ? {
          submissionId: null,
          documentId: Number(documentId),
          version,
          type: contentType,
        }
      : undefined,
  );

  const getTabs = () => {
    const items = [
      {
        label: `${documentTypeToCustomLabelMap[contentType]} details`,
        content: (
          <ReadonlyDocumentContent
            documentTitle={documentTitle ?? ''}
            version={version ?? 1}
            fileName={fileName ?? ''}
            contentType={contentType}
            approvalDate={approvalDate ?? null}
            approved={approved}
            url={url}
            firstAuthorisationDate={firstAuthorisationDate ?? null}
            regulator={regulator ?? ''}
            reasonsForSubmission={reasonsForSubmission ?? []}
            reasonForRetirement={reasonForRetirement}
            documentState={documentState!}
            hasDigitalVersion={hasDigitalVersion}
            onFileClick={onFileDownload}
            contentDescription={document?.contentDescription ?? ''}
            isVisibleToHCP={document?.isVisibleToHCP ?? null}
            htmlContent={publishedHtml}
            onAlternativeTextFileNameClick={() => {}}
            isAdmin={isAdmin}
            secondaryFileName={undefined}
            documentProcessingDetails={documentProcessingDetails}
            details={details}
          />
        ),
      },
      {
        label: `Version history`,
        content: (
          <DocumentVersionHistory
            documentId={document?.documentId ?? 0}
            documentType={document?.type}
            showHistoryFiltering={showHistoryFiltering}
          />
        ),
      },
    ];

    if (displayHtmlView)
      items.push({
        label: `View html`,
        content: <HtmlDocument documentId={documentId} documentType={contentType} version={version.toString()} />,
      });

    return items;
  };

  const renderContent = () => {
    switch (contentType) {
      case DocumentType.Smpc:
      case DocumentType.Pil:
      case DocumentType.Rmm:
      case DocumentType.Dhcp:
      case DocumentType.UserManual:
      case DocumentType.SafetyAlert:
      case DocumentType.ProductInformation:
      case DocumentType.Audio:
      case DocumentType.Video:
        return <Tabs items={getTabs()} />;
      default:
        return <>Work in progress</>;
    }
  };

  if (isFetchingPublicProductGroupState || !isPublicProductGroupStateFetched) {
    return <DocumentDetailsSkeletonLoader />;
  }

  return (
    <>
      <Styled.ContentDetailsWrap>
        <SubmissionFlowHeader title={productGroup.name} onBackClick={onCancel} onCloseClick={onCancel} />
        {!documentFetching && renderContent()}
      </Styled.ContentDetailsWrap>
      <SubmissionFlowFooter onCancel={onCancel} cancelText="Back" />
    </>
  );
};

export default PublishedDocumentDetailsContent;
