import useWaitingMutation from '@hooks/useWaitingMutation';
import { resubmitSubmissionTask } from '@common/services/submissionTaskService';

interface IMutationData {
  note?: string;
}

export const useResubmitTask = (taskId: string) => {
  const prepareData = (note?: string) => {
    return {
      additionalInformation: note ?? '',
    };
  };

  const { mutate, data, isLoading } = useWaitingMutation((data: IMutationData) =>
    resubmitSubmissionTask(taskId, prepareData(data.note)),
  );

  return { mutate, data, isResubmitting: isLoading };
};
