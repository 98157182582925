import useWaitingMutation from '@hooks/useWaitingMutation';
import { prepareDocumentForRetire } from '@common/services/submissionService';
import { ISelectOption } from '@common/types';

export const queryKey = 'retireDocument';

interface IRetireDocumentData {
  documentId: string;
  submissionId: number;
  reason?: ISelectOption;
}

export const usePrepareDocumentForRetire = () => {
  const { mutate } = useWaitingMutation(({ documentId, submissionId, reason }: IRetireDocumentData) => {
    return prepareDocumentForRetire(submissionId, documentId, {
      reasonId: reason?.id,
      reason: reason?.value,
    });
  });

  return { prepareDocumentForRetire: mutate };
};
